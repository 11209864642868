import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { AddSaleProps, SaleInput, SelectedProduct, SelectedService } from './types';
import axios from 'axios';
import type { DatePickerProps } from 'antd';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
// import { useUserCache } from '../hooks/useUserCache';
import {
  AppointmentPreview,
  AppointmentPreviewDocument,
  Client,
  CreateSaleDocument,
  PosDevice,
  Product,
  Sale,
  SalonStaff,
  Service,
  TransferAccount,
  UpdateSaleDocument
} from '../graphql/generated'
import { SALES_PAYMENT_METHODS } from './constants'
import {
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  convertOptionsToCommaSeparatedString,
  convertProductsToCommaSeparatedString,
  convertSaleProductsToCommaSeparatedString,
  convertServicesToCommaSeparatedStringWithQuantity,
  formatInToPrice,
  formatNumber,
  getHoursAndMinutesString,
  pickDefaultTime,
  sanitizePhoneNumber,
  validatePhoneNumber
} from '../utils/misc'
import { MultiSelectOption } from '../ui/molecules/multiselect/types'
import {
  convertServicesToAppointmentStaffService,
  formatBankAccountsToSelectField,
  formatClientToSelectFieldAppointmentCreation,
  formatPosTerminalsToSelectField,
  formatProductsToSelectField,
  formatServicesToSelectField,
  formatStaffToSelectField
} from '../utils/utils'
import Heading from '../ui/atoms/heading/Heading'
import Paragraph from '../ui/atoms/paragraph/Paragraph'
import { COLORS } from '../constants/colors'
import { FormatNumber } from '../ui/atoms/formatNumber/FormatNumber'
import { DEFAULT_CURRENCY } from '../constants/currency'
import SvgTrash from '../ui/icons/Trash'
import { Modal } from '../ui/templates/modal/Modal'
import Button from '../ui/atoms/button/Button'
import SvgArrowBack from '../ui/icons/ArrowBack'
import SvgChevronLeft from '../ui/icons/ChevronLeft'
import { FormLabel } from '../ui/atoms/formLabel'
import { FormHelperText } from '../ui/atoms/helperText/FormHelperText'
import { API_ERRORS, ERRORS } from '../constants/errors'
import CollapseRow from '../ui/organism/collapseRow/CollapseRow'
import { REGEX_PATTERNS } from '../constants/pattern'
import FormPhone from '../ui/molecules/input/FormPhone'
import Input from '../ui/molecules/input/Input'
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors
} from '../utils/form'
import MultiSelect from '../ui/molecules/multiselect/multiselect'
import SvgPlus from '../ui/icons/Plus'
import SearchTerm from '../ui/organism/debounceQuery/SearchTerm'
import FormTextarea from '../ui/molecules/input/FormTextarea'
import SelectInput from '../ui/molecules/input/SelectInput'
import SingleSelect from '../ui/molecules/singleSelect/SingleSelect'
import {
  fetchClientsData,
  fetchPosDevicesData,
  fetchProductsData,
  fetchServicesData,
  fetchStaffListData,
  fetchTransferAccountsData
} from './utils'
import { useSalonCache } from '../hooks/useSalonCache'
import { print } from 'graphql'
import { logger } from '../core/logger'
import {
  SvgCheckMarkGreen,
  SvgEdit,
  SvgGreyMinus,
  SvgGreyPlus,
} from '../ui/icons'
import { fetchSaleById } from '../uicomponents/appointmentComponents/api';
import ViewAppointmentShimmer from '../uicomponents/appointmentComponents/modals/ViewAppointmentShimmer';
import { formatSaleStatusPill } from '../uicomponents/salesComponents/StatusUtils';
import { Checkbox, ContactLink } from '../ui';
import ProceedSalePaymentInfo from '../uicomponents/salesComponents/ProceedSalePaymentInfo';
import { useModal } from '../hooks';

const AddSaleModal = (props: AddSaleProps) => {
  // const { getBusinessData } = useUserCache();
  // const business = getBusinessData();
  // const businessId = business?.businessId as string;
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [newClient, setNewClient] = useState(false)
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>(
    []
  )
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  )
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [countryCode, setCountryCode] = useState('')
  const [callingCode, setCallingCode] = useState('')
  const [enablePromo, setEnablePromo] = useState(false)
  const [enableAddOns, setEnableAddons] = useState(false)
  const [enableDiscount, setEnableDiscount] = useState(false)
  const [discountType, setDiscountType] = useState('')
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [promoCode, setPromoCode] = useState<{
    promoType: string
    promoValue: number
  } | null>(null)
  const [appointmentSummary, setAppointmentSummary] =
    useState<AppointmentPreview | null>(null)
  const [createSaleIsLoading, setCreateSaleIsLoading] = useState(false)
  const [saleIsLoading, setSaleIsLoading] = useState(false)
  const [
    previewAppointmentPriceIsLoading,
    setPreviewAppointmentPriceIsLoading
  ] = useState(false)
  const [clientsOptions, setClientsOptions] = useState<MultiSelectOption[]>([])
  const [servicesOptions, setServicesOptions] = useState<MultiSelectOption[]>(
    []
  )
  const [productsOptions, setProductsOptions] = useState<MultiSelectOption[]>(
    []
  )
  const [transfersOptions, setTransfersOptions] = useState<MultiSelectOption[]>(
    []
  )
  const [posTerminalsOptions, setPosTerminalsOptions] = useState<
    MultiSelectOption[]
  >([])
  let searchTimeout: ReturnType<typeof setTimeout>;
  const dateFormat = 'YYYY/MM/DD'
  const [clients, setClients] = useState<Client[]>([])
  const [services, setServices] = useState<Service[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [staff, setStaff] = useState<SalonStaff[]>([])
  const [bankTransfers, setBankTransfers] = useState<TransferAccount[]>([])
  const [posTerminals, setPosTerminals] = useState<PosDevice[]>([])
  const [staffMembersOptions, setStaffMembersOptions] = useState<
    MultiSelectOption[]
    >([])
  const [sale, setSale] = useState<Sale | null>(null)
  const [appointmentHasNoClient, setAppointmentHasNoClient] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    formState: { errors }
  } = useForm<SaleInput>({
    defaultValues: {
      newClient: false
    }
  })

  const {
    isVisible: isDialogModalVisible,
    openModal: openDialogModal,
    closeModal: closeDialogModal,
  } = useModal();

  const finalizeDialogModalAction = (action: string) => {
    closeDialogModal()
    if (action === 'proceed') {
      addSale(watch())
    }
  }

  const getClients = async (q: string) => {
    if (q) {
      const clientsData = await fetchClientsData({ salonId, q })
      setClients(clientsData)
    }
  }

  useEffect(() => {
    if (props.isVisible) {
      const fetchData = async () => {
        try {
          const productsData = await fetchProductsData({
            salonId,
            isPurchasable: true
          })
          setProducts(productsData)

          const servicesData = await fetchServicesData({ salonId })
          setServices(servicesData)

          const bankTransfersData = await fetchTransferAccountsData({ salonId })
          setBankTransfers(bankTransfersData)

          const posTerminalsData = await fetchPosDevicesData({ salonId })
          setPosTerminals(posTerminalsData)

          const staffData = await fetchStaffListData({ salonId });
          setStaff(staffData?.staffs);

          const clientsData = await fetchClientsData({ salonId }, 10)
          setClients(clientsData)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      if (salonId) {
        fetchData()
      }
    }
  }, [salonId, props.isVisible])

  useEffect(() => {
    if (props?.saleId && props?.isVisible) {
      setSaleIsLoading(true)
      const fetchSaleAsync = async () => {
        const saleDetailsData = await fetchSaleById({
          id: props?.saleId as string
        })

        setSale(saleDetailsData)
        setSaleIsLoading(false)
      }

      fetchSaleAsync()
    }

  }, [salonId, props?.isVisible, props?.saleId])

  useEffect(() => {
    const updateOptions = () => {
      if (Array?.isArray(clients) && clients?.length) {
        setClientsOptions(formatClientToSelectFieldAppointmentCreation(clients))
      }
      if (Array?.isArray(services) && services?.length) {
        setServicesOptions(formatServicesToSelectField(services))
      }
      if (Array?.isArray(products) && products?.length) {
        setProductsOptions(formatProductsToSelectField(products))
      }
      if (Array?.isArray(bankTransfers) && bankTransfers?.length) {
        setTransfersOptions(formatBankAccountsToSelectField(bankTransfers))
      }
      if (Array?.isArray(posTerminals) && posTerminals?.length) {
        setPosTerminalsOptions(formatPosTerminalsToSelectField(posTerminals))
      }
      if (Array?.isArray(staff) && staff?.length) {
        setStaffMembersOptions(formatStaffToSelectField(staff))
      }
    }

    updateOptions()
  }, [clients, products, services, staff, posTerminals, bankTransfers])

  useEffect(() => {
    if (watch('clientId')) {
      const clientIdField = watch('clientId') as unknown as {
        label: string;
        value: string;
      };
      if (clientIdField?.value === 'new-client') {
        setNewClient(true);
        setValue('clientName', '');
        setValue('clientEmail', '');
        setValue('clientPhone', '');
        setValue('newClient', true);
      } else {
        setNewClient(false);
        setValue('newClient', false);
        const client = clients.find((client) => client?.id === clientIdField?.value) as Client;
        if (client) {
          setValue('clientName', `${client?.firstName} ${client?.lastName}`);
          setValue('clientEmail', client?.email);
          setValue('clientPhone', client?.phone);
          setCallingCode(client?.callingCode);
          setCountryCode(client?.countryCode);
        }
      }
    }
  }, [watch('clientId')])

  useEffect(() => {
    if (watch('services')) {
      const serviceSelectedFromOptions =
        Array?.isArray(services) &&
        services
          ?.filter((service) => {
            const serviceId = service?.id
            const existsInServicesField = watch('services')?.some(
              (serviceSelected: MultiSelectOption) =>
                serviceSelected?.value === serviceId
            )
            const existsInSelectedServices = selectedServices.some(
              (selectedService) => selectedService.id === serviceId
            )

            if (!existsInServicesField && existsInSelectedServices) {
              // Return null to indicate that this service should be removed
              setSelectedServices((prevSelectedServices) =>
                prevSelectedServices.filter((s) => s.id !== serviceId)
              )
              return false
            }
            return existsInServicesField && !existsInSelectedServices
          })
          .filter((service) => service !== null)
      if (
        Array?.isArray(serviceSelectedFromOptions) &&
        serviceSelectedFromOptions?.length
      ) {
        setSelectedServices((prevSelectedServices) => [
          ...prevSelectedServices,
          ...serviceSelectedFromOptions
        ])
      }
    }
  }, [watch('services')])

  useEffect(() => {
    if (watch('products')) {
      const productSelectedFromOptions = products
        .filter((product) => {
          const productId = product?.id
          const existsInProductsField = watch('products')?.some(
            (selectedProduct: MultiSelectOption) =>
              selectedProduct?.value === productId
          )
          const existsInSelectedProducts = selectedProducts.some(
            (selectedProduct) => selectedProduct.id === productId
          )

          if (!existsInProductsField && existsInSelectedProducts) {
            // Return null to indicate that this service should be removed
            setSelectedProducts((prevSelectedProducts) =>
              prevSelectedProducts.filter((p) => p.id !== productId)
            )
            return false
          }
          return existsInProductsField && !existsInSelectedProducts
        })
        .filter((product) => product !== null)
      if (
        Array?.isArray(productSelectedFromOptions) &&
        productSelectedFromOptions?.length
      ) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...productSelectedFromOptions
        ])
      }
    }
  }, [watch('products')])

  // validate promo
  useEffect(() => {
    const clientId = watch('clientId') as unknown as {
      label: string
      value: string
    }
    const clientPhone = watch('clientPhone')

    if (
      clientId?.value &&
      clientId?.value !== undefined &&
      debouncedSearchQuery &&
      ((clientId?.value === 'new_client' && clientPhone !== '') ||
        clientId?.value !== 'new_client')
    ) {
      previewPrice()
    }
  }, [debouncedSearchQuery, watch('clientId'), watch('clientPhone'), watch('saleDate')])

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode)
    setValue('clientPhone', _phoneNumber)
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('saleDate', dateString)
  }

  const getClientDetails = (input: SaleInput) => {
    if (sale) {
      return {
        firstName: sale?.client?.firstName,
        lastName: sale?.client?.lastName || "",
        email: sale?.client?.email,
        phone: sale?.client?.phone,
        callingCode: sale?.client?.callingCode,
        countryCode: sale?.client?.countryCode,
      }
    }
    const clientId = input?.clientId as unknown as {
      label: string
      value: string
    }

    if (clientId?.value === 'new-client') {
      return {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1],
        email: input?.clientEmail,
        phone: input?.clientPhone,
        callingCode,
        countryCode
      }
    }
    // check client details from the list of clients
    const client = clients.find(
      (client) => client?.id === clientId?.value
    ) as Client
    return {
      firstName: client?.firstName,
      lastName: client?.lastName,
      email: client?.email,
      phone: client?.phone,
      callingCode: client?.callingCode,
      countryCode: client?.countryCode,
    }
  }

  const validateForm = (input: SaleInput) => {
    if (!input?.saleDate) {
      props?.addToast && props?.addToast({ message: 'Date is required', variant: 'error' })
      return false;
    }
    if (input?.clientId === undefined || input?.clientId === null) {
      props?.addToast && props?.addToast({ message: 'Client is required', variant: 'error' })
      return false;
    }
    if (!input?.services && !input?.products) {
      props?.addToast && props?.addToast({ message: 'Kindly select a service or a product', variant: 'error' })
      return false;
    }
    if (!input?.hasMadePayment) {
      props?.addToast && props?.addToast({ message: 'Please select payment status', variant: 'error' })
      return false;
    }
    if (input?.hasMadePayment === 'yes' && !input?.paymentMethod) {
      props?.addToast && props?.addToast({ message: 'Please select payment method', variant: 'error' })
      return false;
    }

    if (input?.hasMadePayment && input?.paymentMethod === 'pos' && posTerminals?.length && !input?.paymentMethodId) {
      props?.addToast && props?.addToast({ message: 'Please select a pos device', variant: 'error' })
      return false;
    }

    if (input?.hasMadePayment && input?.paymentMethod === 'bank_transfer' && bankTransfers?.length && !input?.paymentMethodId) {
      props?.addToast && props?.addToast({ message: 'Please select a bank account', variant: 'error' })
      return false;
    }

    return true
  }

  const addSale = async (input: SaleInput) => {
    // validate form
    const checkIfFormIsReadyToGo = validateForm(input);
    if (!checkIfFormIsReadyToGo) {
      return;
    }
    const pn = validatePhoneNumber(input?.clientPhone, countryCode);

    if (!pn.valid && !appointmentHasNoClient && !sale) {
      // If invalid, trigger an error
      props?.addToast && props?.addToast({
        message: 'Invalid phone number',
        variant: 'error',
      })
      return;
    }
    // setCreateSaleIsLoading(true)
    const saleDate = input?.saleDate.replace(/\//g, '-')
    const saleDateTime = `${saleDate}T${pickDefaultTime()}}`
    // const client = getClientDetails(input)

    let saleStatus = 'unpaid';

    if (sale && !sale?.amountClientPaid && input?.hasMadePayment !== 'yes') {
      saleStatus = 'unpaid';
    } else if (input?.hasMadePayment === 'yes' || sale) {
      const totalPaid = appointmentSummary?.totalPaid || 0;
      const inputAmount = Number(input?.amountClientPaid?.toString()?.replaceAll(",", "")) || 0;
      const saleAmount = sale?.amountClientPaid || 0;

      if (sale) {
        if (saleAmount === 0 && inputAmount > totalPaid) {
          props?.addToast?.({
            message: `Amount paid cannot be greater than total amount (${DEFAULT_CURRENCY}${totalPaid})`,
            variant: 'error',
          });
          return;
        }

        if (saleAmount > 0 && (inputAmount + saleAmount) > totalPaid) {
          props?.addToast?.({
            message: `Amount paid cannot be greater than balance amount (${DEFAULT_CURRENCY}${totalPaid - saleAmount})`,
            variant: 'error',
          });
          return;
        }

        if (saleAmount === 0 && inputAmount > 0 && inputAmount < totalPaid) {
          saleStatus = 'partially_paid';
        } else if (saleAmount === 0 && inputAmount === totalPaid) {
          saleStatus = 'paid';
        } else if (saleAmount > 0 && (inputAmount + saleAmount) === totalPaid) {
          saleStatus = 'paid';
        } else if (saleAmount > 0 && (inputAmount + saleAmount) < totalPaid) {
          saleStatus = 'partially_paid';
        }
      } else {
        if (inputAmount > totalPaid) {
          props?.addToast?.({
            message: `Amount paid cannot be greater than total amount (${DEFAULT_CURRENCY}${totalPaid})`,
            variant: 'error',
          });
          return;
        }
        if (inputAmount === totalPaid) {
          saleStatus = 'paid';
        } else if (inputAmount > 0 && inputAmount < totalPaid) {
          saleStatus = 'partially_paid';
        }
      }
    }
    const inputData = {
      client: !appointmentHasNoClient ? {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1] || ' ',
        email: input?.clientEmail,
        phone: input?.clientPhone,
        callingCode,
        countryCode,
      } : null,
      startAt: saleDateTime,
      services: selectedServices.length
        ? convertServicesToCommaSeparatedStringWithQuantity(selectedServices)
        : [],
      products: selectedProducts.length
        ? convertSaleProductsToCommaSeparatedString(selectedProducts)
        : [],
      staff: input?.staff
        ? convertOptionsToCommaSeparatedString(input?.staff)
        : [],
      note: input?.note,
      promoCode: debouncedSearchQuery,
      saleStatus,
      ...(input?.hasMadePayment === 'yes' ? {
        amountClientPaid: Number(input?.amountClientPaid?.toString()?.replaceAll(",", "")),
        paymentMethod: input?.paymentMethod,
        paymentMethodId: input?.paymentMethodId,
      } : {
        amountClientPaid: 0,
      }),
      ...(input?.addOnAmount
        ? {
            addOnAmount: parseFloat(
              input?.addOnAmount.toString().replace(',', '')
            )
          }
        : {}),
      addOnReason: input?.addOnReason,
      discountType,
      discountValue: input.discountValue ? Number(input.discountValue) : null
    }

    if (sale) {
      try {
        // logger.info(`Adding a sale: ${JSON.stringify(inputData)}`);
        await axios
          .post(
            '/graphql',
            {
              query: print(UpdateSaleDocument),
              variables: {
                input: { ...inputData, salonId: props?.salonId, id: sale?.id }
              }
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }
          )
          .then(({ data }) => {
            setCreateSaleIsLoading(false)
            if (
              data?.data?.updateSale?.status === 201
            ) {
              props?.addToast &&
                props.addToast({
                  message: 'Sale updated successfully',
                  variant: 'success'
                })
              resetFormInput()
              props.closeModal('updatedSale', data?.data?.updateSale?.sale)
              setSale(null);
            }

            if (data?.data?.updateSale?.errors?.length) {
              const message =
                data?.data?.updateSale?.errors[0]?.message ||
                API_ERRORS.SALE_UPDATE_FAILED
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message
                })
            }

            if (data?.errors?.length) {
              const message =
                data?.errors[0]?.message || API_ERRORS.SALE_UPDATE_FAILED
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message
                })
            }
          })
      } catch (addAppointmentError) {
        if (axios.isAxiosError(addAppointmentError)) {
          const message =
            addAppointmentError?.response?.data?.message ||
            API_ERRORS.SALE_UPDATE_FAILED
          props?.addToast &&
            props.addToast({
              message,
              variant: 'error'
            })
          logger.error(`Failed to add a sale: ${message}`)
          logger.exception(addAppointmentError as Error)
        }
      }
    } else {
      try {
        // logger.info(`Adding a sale: ${JSON.stringify(inputData)}`);
        await axios
          .post(
            '/graphql',
            {
              query: print(CreateSaleDocument),
              variables: {
                input: { ...inputData, salonId: props?.salonId }
              }
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }
          )
          .then(({ data }) => {
            setCreateSaleIsLoading(false)
            if (
              data?.data?.createSale?.status === 201 &&
              data?.data?.createSale?.sale
            ) {
              props?.addToast &&
                props.addToast({
                  message: 'Sale created successfully',
                  variant: 'success'
                })
              resetFormInput()
              props.closeModal('addedSale', data?.data?.createSale?.sale)
            }

            if (data?.data?.createSale?.errors?.length) {
              const message =
                data?.data?.createSale?.errors[0]?.message ||
                API_ERRORS.SALE_CREATION_FAILED
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message
                })
            }

            if (data?.errors?.length) {
              const message =
                data?.errors[0]?.message || API_ERRORS.SALE_CREATION_FAILED
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message
                })
            }
          })
      } catch (addAppointmentError) {
        if (axios.isAxiosError(addAppointmentError)) {
          const message =
            addAppointmentError?.response?.data?.message ||
            API_ERRORS.SALE_CREATION_FAILED
          props?.addToast &&
            props.addToast({
              message,
              variant: 'error'
            })
          logger.error(`Failed to add a sale: ${message}`)
          logger.exception(addAppointmentError as Error)
        }
      }
    }
  }

  const previewPrice = async () => {
    const client = getClientDetails({
      clientId: watch('clientId'),
      clientName: watch('clientName'),
      clientEmail: watch('clientEmail'),
      clientPhone: watch('clientPhone')
    } as SaleInput)

    const startDate = new Date().toISOString()
    let startAt = `${startDate?.split('T')[0]}T${pickDefaultTime()}`

    if (watch('saleDate')) {
      startAt = `${watch('saleDate').replace(/\//g, '-')}T${pickDefaultTime()}`
    }

    const appointmentData = {
      client: !appointmentHasNoClient ? {
        phone: sale ? sale?.client?.phone : client?.phone,
        countryCode: sale ? sale?.client?.countryCode : client?.countryCode
      } : null,
      services: convertServicesToAppointmentStaffService(
        selectedServices,
        startAt
      ),
      ...(selectedProducts.length && {
        products: convertProductsToCommaSeparatedString(selectedProducts)
      }),
      ...(debouncedSearchQuery ? { promoCode: debouncedSearchQuery } : {}),
      paymentMethod: "unpaid",
      discountType,
      discountValue: Number(watch('discountValue'))
    }

    await axios
      .post(
        '/graphql',
        {
          query: print(AppointmentPreviewDocument),
          variables: { input: { ...appointmentData, salonId: props?.salonId } }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then(({ data }) => {
        setPreviewAppointmentPriceIsLoading(false)
        const appointmentSummary = data?.data?.appointmentPreview?.data
        if (appointmentSummary) {
          setAppointmentSummary(appointmentSummary)
          if (!sale) {
            setValue('amountClientPaid', appointmentSummary?.totalPaid)
          }
          if (sale && sale?.saleStatus === 'unpaid') {
            setValue('amountClientPaid', appointmentSummary?.totalPaid)
          }

          if (appointmentSummary?.appointmentPromo) {
            setPromoCode({
              promoType: 'amount',
              promoValue: appointmentSummary?.appointmentPromo?.amount
            })
          }

          if (debouncedSearchQuery && !appointmentSummary?.appointmentPromo) {
            props?.addToast({
              variant: 'error',
              message: 'Promo code could not be applied'
            })
          }
        }
      })
      .catch((error) => {
        setPreviewAppointmentPriceIsLoading(false)
        if (axios.isAxiosError(error)) {
          const message =
            error?.response?.data?.message ||
            API_ERRORS.APPOINTMENT_PRICE_SUMMARY_FAILED
          props?.addToast &&
            props?.addToast({
              variant: 'error',
              message
            })
        }
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchClient = (e: any) => {
    // Clear any previously set timeout to avoid multiple calls
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to delay the execution of getClients
    searchTimeout = setTimeout(() => {
      getClients(e);
    }, 500);
  };

  useEffect(() => {
    previewPrice()
  }, [
    selectedServices,
    selectedProducts,
    debouncedSearchQuery,
    watch('paymentMethod'),
    watch('addOnAmount'),
    watch('clientId'), watch('clientPhone')
  ])

  const closeAddSaleModal = () => {
    props.closeModal('addedSale')
    resetFormInput()
  }

  const getStaffDetails = (id: string) => {
    return staff.find((staff) => staff?.id === id)
  }

  useEffect(() => {
    if (sale && staff && staff?.length) {
      const saleStaff = sale?.saleStaff?.map((staffMember) => {
        const staffDetails = getStaffDetails(staffMember?.salonStaffId);
        return {
          label: staffDetails?.user?.fullName,
          value: staffDetails?.id
        }
      })
      const services = sale?.saleServices?.map((service) => {
        return {
          label: service?.name,
          value: service?.serviceId
        }
      })
      const products = sale?.saleProducts?.map((product) => {
        return {
          label: product?.name,
          value: product?.productId
        }
      })

      setValue('saleDate', convertFullDateStringToDate(sale?.startAt))
      setValue('saleTime', convertFullDateStringToTime(sale?.startAt as string))
      setValue('clientId', {
        label: `${sale?.client?.firstName} ${sale?.client?.lastName}`,
        value: sale?.client?.id
      } as unknown as [])
      setValue('clientPhone', sale?.client?.phone)
      setValue('clientEmail', sale?.client?.email)
      setValue('clientName', sale?.client?.firstName + " " + sale?.client?.lastName)
      setValue('note', sale?.note)
      setValue('services', services as unknown as [])
      setValue('staff', saleStaff as unknown as [])
      setValue('products', products as unknown as [])
      setValue('addOnReason', sale?.addOnReason)
      setValue('addOnAmount', sale?.addOnAmount)
      setValue('hasMadePayment', sale?.paymentMethod ? 'yes' : 'no')
      setIsDiscountApplied(sale?.saleDiscount > 0 ? true : false)
      if (sale?.saleDiscount > 0) {
        setValue('discountType', 'fixed_value')
        setDiscountType('fixed_value')
        setValue('discountValue', sale?.saleDiscount)
      }
      setEnableAddons(sale?.addOnAmount > 0 ? true : false)
      setEnablePromo(sale?.salePromo && sale?.salePromo?.amount > 0 ? true : false)
      setEnableDiscount(sale?.discountAmount > 0 ? true : false)
      if (sale?.amountClientPaid && sale?.amountClientPaid > 0) {
        setValue("amountClientPaid", sale?.totalPaid - sale?.amountClientPaid)
      } else {
        setValue("hasMadePayment", "no")
      }
      previewPrice()
    }
  }, [sale, staff])

  const resetFormInput = () => {
    setValue('saleDate', '')
    setValue('clientId', [])
    setValue('clientPhone', '')
    setValue('clientEmail', '')
    setValue('clientName', '')
    setValue('note', '')
    setValue('services', [])
    setValue('staff', [])
    setValue('products', [])
    setValue('addOnReason', '')
    setValue('addOnAmount', 0)
    setValue('paymentMethod', '')
    setValue('discountType', null)
    setValue('discountValue', null)
    setSelectedServices([])
    setSelectedProducts([])
    setEnableAddons(false)
    setNewClient(false)
    setEnablePromo(false)
    setAppointmentSummary(null)
    setIsDiscountApplied(false)
    setDiscountType(null)
    setEnableDiscount(false)
    setValue("amountClientPaid", null)
    setValue("paymentMethodId", "")
    setValue("hasMadePayment", "")
    setAppointmentHasNoClient(false)
  }

  const removeService = (index?: number) => {
    const selectedServicesWatched = watch('services')
    if (!Array.isArray(selectedServicesWatched) || typeof index !== 'number')
      return
    if (selectedServicesWatched?.length && selectedServicesWatched?.[index]) {
      selectedServicesWatched.splice(index, 1)
    }
    setValue('services', selectedServicesWatched)

    if (selectedServices?.length && selectedServices?.[index]) {
      selectedServices.splice(index, 1)
    }
    setSelectedServices(selectedServices)
    previewPrice()
  }

  const removeProduct = (index?: number) => {
    const selectedProductsWatched = watch('products')
    if (!Array.isArray(selectedProductsWatched) || typeof index !== 'number')
      return
    if (selectedProductsWatched?.length && selectedProductsWatched?.[index]) {
      selectedProductsWatched.splice(index, 1)
    }
    setValue('products', selectedProductsWatched)

    if (selectedProducts?.length && selectedProducts?.[index]) {
      selectedProducts.splice(index, 1)
    }
    setSelectedProducts(selectedProducts)
    previewPrice()
  }

  const getServiceSummary = () => {
    return getServiceSummaryUtils(
      selectedServices,
      services,
      selectedProducts,
      products,
      removeService,
      removeProduct
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTime = (value: any, dateString: string) => {
    setValue('saleTime', dateString)
  }

  const modifyCart = (
    id: string,
    type: 'add' | 'remove',
    inventory: 'product' | 'service'
  ) => {
    // modify cart for services
    if (inventory === 'service') {
      let remove = false
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1
          if (type === 'add') {
            return {
              ...service,
              quantity: quantity + 1
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return service
      })
      if (remove) {
        // get serive index from watch("services") via id
        const serviceIndex = watch('services')?.findIndex(
          (serviceSelected: MultiSelectOption) => serviceSelected?.value === id
        )
        removeService(serviceIndex)
        return
      }
      setSelectedServices(serviceSelectedFromOptions)
    }

    // modify cart for products
    if (inventory === 'product') {
      let remove = false
      const productSelectedFromOptions = selectedProducts.map((product) => {
        if (product?.id === id) {
          const quantity = product?.quantity || 1
          if (type === 'add') {
            const newQuantity = quantity + 1
            if (newQuantity > product?.stockCount) {
              // flag error stockCount
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message: `You only have ${product?.stockCount} in stock`,
                })
            } else {
              return {
                ...product,
                quantity: newQuantity
              }
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...product,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return product
      })

      if (remove) {
        // get serive index from watch("services") via id
        const productIndex = watch('products')?.findIndex(
          (productSelected: MultiSelectOption) => productSelected?.value === id
        )
        removeProduct(productIndex)
        return
      }
      setSelectedProducts(productSelectedFromOptions)
    }
  }

  const applyDiscount = async () => {
    await previewPrice()
    setIsDiscountApplied(true)
  }

  const getServiceSummaryUtils = (
    selectedServices: SelectedService[],
    services: Service[],
    selectedProducts: SelectedProduct[],
    products: Product[],
    servicesCanBeRemoved?: (index?: number) => void,
    productsCanBeRemoved?: (index?: number) => void
  ) => {
    if (
      (Array.isArray(selectedProducts) && selectedProducts.length > 0) ||
      (Array.isArray(selectedServices) && selectedServices.length > 0)
    ) {
      return (
        <div className='flex flex-col p-3 space-y-2 border border-grey-20 rounded-md'>
          {Array.isArray(selectedServices) && selectedServices.length > 0 ? (
            <div className='flex flex-col space-y-4'>
              <div className='flex space-x-2'>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[50%]'
                >
                  SELECTED SERVICES
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[20%]'
                >
                  SELECT QUANTITY
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[20%] flex justify-end'
                >
                  AMOUNT
                </Heading>
                <div className='w-[10%] flex justify-end'></div>
              </div>
              <>
                {selectedServices?.map((service, index) => {
                  const quantity = service?.quantity || 1
                  return (
                    <div
                      className='flex items-start space-x-2'
                      key={service?.id}
                    >
                      <div className='flex flex-col w-[50%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className=''
                        >
                          {service?.name}
                        </Paragraph>{' '}
                        <span className='text-grey-300 text-b6'>
                          {getHoursAndMinutesString(service?.duration)}
                        </span>
                      </div>
                      <div className='w-[20%] flex space-x-2'>
                        <div className='w-full flex pt-2'>
                          <Button
                            variant='icon'
                            size='none'
                            type='button'
                            className='border-0'
                            disabled={!!sale && sale?.amountClientPaid === 0}
                            onClick={() =>
                              modifyCart(service?.id, 'add', 'service')
                            }
                          >
                            <SvgGreyPlus width='22px' height='22px' />
                          </Button>
                          <div className='flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center'>
                            <Paragraph
                              size='b6'
                              weight='normal'
                              color={COLORS.BLACK}
                            >
                              {service?.quantity || 1}
                            </Paragraph>
                          </div>
                          <Button
                            variant='icon'
                            size='none'
                            type='button'
                            className='border-0'
                            disabled={!!sale && sale?.amountClientPaid === 0}
                            onClick={() =>
                              modifyCart(service?.id, 'remove', 'service')
                            }
                          >
                            <SvgGreyMinus width='22px' height='22px' />
                          </Button>
                        </div>
                      </div>
                      <div className='flex justify-end w-[20%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className=''
                        >
                          {DEFAULT_CURRENCY}
                          <FormatNumber value={service?.price * quantity} />
                          {service?.pricingType === 'from' ? (
                            <span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full ml-2'>
                              from
                            </span>
                          ) : null}
                        </Paragraph>
                      </div>
                      <div className='w-[10%] flex justify-end items-center'>
                        {!sale || (sale && sale?.amountClientPaid === 0) ?
                          <span
                            className='text-red-600 text-b5 cursor-pointer'
                            onClick={() => {
                              servicesCanBeRemoved && servicesCanBeRemoved(index)
                            }}
                          >
                            <SvgTrash width='14px' height='14px' />
                          </span> : null}
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          ) : null}
          {Array.isArray(selectedProducts) && selectedProducts.length > 0 ? (
            <div className='flex flex-col space-y-4'>
              <div className='flex space-x-2 mt-4'>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[50%]'
                >
                  SELECTED PRODUCTS
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[20%]'
                >
                  SELECT QUANTITY
                </Heading>
                <Heading
                  variant='h3'
                  size='b6'
                  color={COLORS.GREY[300]}
                  weight='bold'
                  className='w-[20%] flex justify-end'
                >
                  AMOUNT
                </Heading>
                <div className='w-[10%] flex justify-end'></div>
              </div>
              <>
                {selectedProducts?.map((product, index) => {
                  const quantity = product?.quantity || 1
                  return (
                    <div
                      className='flex items-start space-x-2'
                      key={product?.id}
                    >
                      <div className='flex w-[50%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className='flex-1'
                        >
                          {product?.name}
                        </Paragraph>
                      </div>
                      <div className='w-[20%] flex space-x-2'>
                        <div className='w-full flex'>
                          <Button
                            variant='icon'
                            size='none'
                            type='button'
                            className='border-0'
                            disabled={!!sale && sale?.amountClientPaid === 0}
                            onClick={() =>
                              modifyCart(product?.id, 'add', 'product')
                            }
                          >
                            <SvgGreyPlus width='22px' height='22px' />
                          </Button>
                          <div className='flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center'>
                            <Paragraph
                              size='b6'
                              weight='normal'
                              color={COLORS.BLACK}
                            >
                              {product?.quantity || 1}
                            </Paragraph>
                          </div>
                          <Button
                            variant='icon'
                            size='none'
                            type='button'
                            className='border-0'
                            disabled={!!sale && sale?.amountClientPaid === 0}
                            onClick={() =>
                              modifyCart(product?.id, 'remove', 'product')
                            }
                          >
                            <SvgGreyMinus width='22px' height='22px' />
                          </Button>
                        </div>
                      </div>
                      <div className='flex justify-end w-[20%]'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          className=''
                        >
                          {DEFAULT_CURRENCY}
                          <FormatNumber
                            value={product?.retailPrice * quantity}
                          />
                        </Paragraph>
                      </div>
                      <div className='w-[10%] flex justify-end items-center'>
                        {!sale || (sale && sale?.amountClientPaid === 0) ?
                          <span
                            className='text-red-600 text-b5 cursor-pointer'
                            onClick={() => {
                              productsCanBeRemoved && productsCanBeRemoved(index)
                            }}
                          >
                            <SvgTrash width='14px' height='14px' />
                          </span> : null}
                      </div>
                    </div>
                  )
                })}
              </>
            </div>
          ) : null}
          {appointmentSummary ? (
            <div className='w-full flex flex-col space-y-2 border-t border-grey-100 py-3'>
              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  Subtotal
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  {formatInToPrice(
                    ((appointmentSummary?.totalServicesAmount as number) +
                      appointmentSummary?.totalProductsAmount) as number
                  )}
                </Paragraph>
              </div>

              {isDiscountApplied && (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Discount
                  </Paragraph>
                  <div className='flex items-center space-x-4'>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>-{formatInToPrice(appointmentSummary.discountAmount)}</Paragraph>
                    {!sale || (sale && !sale?.amountClientPaid || sale?.saleDiscount === 0 ) ?
                      <>
                        <span
                          className='mr-3'
                          style={{ marginRight: '10px', cursor: 'pointer' }}
                          onClick={() => {
                            setIsDiscountApplied(false)
                            setEnableDiscount(true)
                          }}
                        >
                          <SvgEdit width='15px' height='15px' />
                        </span>
                        <span
                          className='text-red-600 cursor-pointer'
                          onClick={() => {
                            setIsDiscountApplied(false)
                            setEnableDiscount(false)
                            setValue('discountValue', null)
                            setDiscountType(null)
                          }}
                        >
                          <SvgTrash width='15px' height='15px' />
                        </span>
                      </> : null}
                  </div>
                </div>
              )}

              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]}>
                  Tax
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]}>
                  {DEFAULT_CURRENCY}
                  <FormatNumber value={appointmentSummary?.taxAmount} />
                </Paragraph>
              </div>

              {appointmentSummary?.appointmentPromo ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Promo code
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    -{DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.taxAmount} />
                  </Paragraph>
                </div>
              ) : null}

              {!sale ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    Total
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.totalPaid} />
                  </Paragraph>
                </div>
              ) : sale && sale?.amountClientPaid && sale?.amountClientPaid < appointmentSummary?.totalPaid ? (
                <>
                  <div className='flex justify-between items-center'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      Amount paid
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      {DEFAULT_CURRENCY}
                      <FormatNumber value={sale?.amountClientPaid} />
                    </Paragraph>
                  </div>

                  <div className='flex justify-between items-center border-t border-grey-100 py-2'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                      Balance
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      {DEFAULT_CURRENCY}
                      <FormatNumber value={appointmentSummary?.totalPaid - sale?.amountClientPaid} />
                    </Paragraph>
                  </div>
                </>
              ) : (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    Total
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.totalPaid} />
                  </Paragraph>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    if (watch('hasMadePayment') && watch('hasMadePayment') === 'yes') {
      if (!sale) {
        setValue('amountClientPaid', appointmentSummary?.totalPaid)
      } else {
        if (appointmentSummary) {
          setValue('amountClientPaid', appointmentSummary?.totalPaid - sale?.amountClientPaid)
        } else {
          setValue('amountClientPaid', sale?.totalPaid - sale?.amountClientPaid)
        }
      }
    } else {
      if (!sale) {
        setValue('amountClientPaid', null)
        setValue('paymentMethod', null)
      }
    }
  }, [watch('hasMadePayment')])

  useEffect(() => {
    if (appointmentHasNoClient) {
      setValue('clientEmail', '')
      setValue('clientPhone', '')
      setValue('clientName', '')
      setValue('clientId', []);
      previewPrice()
    }
  }, [appointmentHasNoClient])

  return (
    <>
      <Modal
        show={props.isVisible}
        closeModal={closeAddSaleModal}
        variant='right'
      >
        {props?.saleId && saleIsLoading ?
          <ViewAppointmentShimmer />
          :
          <form
            onSubmit={handleSubmit(addSale)}
            className='w-full relative space-y-6 my-[80px]'
            autoComplete='off'
          >
            <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
              <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
                <Button
                  variant='text'
                  size='none'
                  type='button'
                  className='w-fit'
                  fontSize='b4'
                  onClick={closeAddSaleModal}
                >
                  <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
                </Button>
              </div>
              <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
                <Button
                  variant='icon'
                  size='square'
                  type='button'
                  rounded='md'
                  className='w-fit'
                  fontSize='b4'
                  onClick={closeAddSaleModal}
                >
                  <SvgChevronLeft width='24px' height='24px' />
                </Button>
                <Paragraph size='b3' weight='bold'>
                  {sale ? 'Edit Sale' : 'Add Sale'}
                </Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col px-6 py-4 space-y-6'>
              <Heading variant='h1' size='h9' weight='semiBold' className='flex gap-3 items-center'>
                {sale ? 'Edit Sale' : 'Add Sale'} {sale ? formatSaleStatusPill(sale?.saleStatus) : null}
              </Heading>
              <Paragraph size='b4'>{sale ? 'Modify an existing sale' : 'Create a new sale for a client'}</Paragraph>
            </div>
            {sale && sale?.amountClientPaid > 0 ?
              <div className='w-full px-6'>
                <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                  <SvgCheckMarkGreen width='24px' height='24px' />
                  <div className='w-full flex flex-col'>
                    <Paragraph
                      size='b4'
                      color={COLORS.GREEN[850]}
                      weight='semiBold'
                    >
                      {sale?.client?.firstName +
                        ' ' +
                        sale?.client?.lastName}
                    </Paragraph>
                    {sale?.client?.firstName?.toLowerCase() !== 'walkin' ?
                      <ContactLink
                        variant='tel'
                        value={`${sale?.client?.callingCode}${sale?.client?.phone}`}
                        label={`${sale?.client?.callingCode}${sale?.client?.phone}`}
                      /> : null}
                    <Paragraph size="b4" color={COLORS.GREEN[850]}>
                      Amount paid: {formatInToPrice(sale?.amountClientPaid)}
                    </Paragraph>
                    <Paragraph size="b4" color={COLORS.GREEN[850]}>
                      Balance: {formatInToPrice(appointmentSummary?.totalPaid - sale?.amountClientPaid)}
                    </Paragraph>
                  </div>
                </div>
            </div> : null}
            <div className='w-full px-6 pt-2 pb-4 space-y-6'>
              <div className='w-full flex flex-col xl:flex-row gap-4'>
                <div className='w-full xl:w-1/2'>
                  <Controller
                    control={control}
                    name='saleDate'
                    render={({ field: { value }, formState: { errors } }) => {
                      // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                      const errorMessage: string = errors?.appointmentDate?.message
                      const dateValue = value || null
                      return (
                        <div className='flex flex-col space-y-2'>
                          <FormLabel htmlFor='appointment-date'>Date</FormLabel>
                          <DatePicker
                            className='splice-time-input'
                            value={
                              dateValue ? dayjs(dateValue, dateFormat) : sale ? dayjs(convertFullDateStringToDate(sale?.startAt), dateFormat) : undefined
                            }
                            onChange={onChangeDate}
                            format={dateFormat}
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      )
                    }}
                  />
                </div>
                <div className='w-full xl:w-1/2'>
                  <Controller
                    control={control}
                    name='saleTime'
                    render={({
                      // field: { value },
                      formState: { errors }
                    }) => {
                      // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                      const errorMessage: string = errors?.appointmentTime?.message
                      // const timeValue = value || null;
                      return (
                        <div className='flex flex-col space-y-2'>
                          <FormLabel htmlFor='appointment-time'>Time</FormLabel>
                          <TimePicker
                            disabled={true}
                            className='splice-time-input'
                            use12Hours
                            format='h:mm a'
                            value={dayjs(sale ? convertFullDateStringToTime(
                              sale?.startAt as string
                            ) : pickDefaultTime(), 'HH:mm')}
                            onChange={onChangeTime}
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
              <CollapseRow title='Clients' initialState={sale ? false : true}>
                <div className='w-full flex flex-col space-y-2'>
                  <Controller
                    control={control}
                    name='clientId'
                    render={({
                      field: { onChange, value },
                      formState: { errors }
                    }) => {
                      const errorMessage: string = errors?.clientId?.message
                      return (
                        <>
                          <FormLabel htmlFor='service'>Select a client</FormLabel>
                          <SingleSelect
                            selected={value || []}
                            options={clientsOptions}
                            setSelected={onChange}
                            onInputChange={searchClient}
                            placeholder='Type client name'
                            isDisabled={sale || appointmentHasNoClient ? true : false}
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </>
                      )
                    }}
                  />
                  {!sale ?
                    <div className='w-full flex space-x-2 cursor-pointer' onClick={() => {
                      setAppointmentHasNoClient(!appointmentHasNoClient)
                    }}>
                      <Checkbox
                        isChecked={appointmentHasNoClient}
                      />
                      <span className='text-b5 text-grey-900'>Leave blank for walk-ins</span>
                    </div> : null}
                </div>
                {newClient && !appointmentHasNoClient ? (
                  <>
                    <Input
                      name='clientName'
                      label='Client'
                      id='clientName'
                      type='text'
                      placeholder='Enter client name'
                      control={control}
                      rules={{
                        required: newClient && !appointmentHasNoClient ? ERRORS.NAME_REQUIRED : false,
                        pattern: REGEX_PATTERNS.ADDRESS
                      }}
                      error={errors.clientName}
                    />
                    <div className='w-full flex gap-x-4'>
                      <div className='w-full xl:w-1/2'>
                        <FormPhone
                          country={{
                            disabled: false,
                            onSelect: (code, country: string) => {
                              setCallingCode(code)
                              setCountryCode(country)
                            },
                            value: countryCode
                          }}
                          phone={{
                            name: 'clientPhone',
                            type: 'text',
                            placeholder: '9151930463',
                            rules: {
                              required: !appointmentHasNoClient ? ERRORS.PHONE_REQUIRED : false,
                              pattern: REGEX_PATTERNS.NUMBER,
                              onChange: (e) => {
                                _sanitizePhoneNumber(e.target.value)
                              },
                              disabled: false
                            },
                            register,
                            id: 'phone-number',
                            label: 'Phone Number'
                          }}
                          helperText={getHelperTextForReactHookFormErrors(
                            errors?.clientPhone?.message as string
                          )}
                        />
                      </div>
                      <div className='w-full xl:w-1/2'>
                        <Input
                          name='clientEmail'
                          label='Client email'
                          id='clientEmail'
                          type='email'
                          placeholder='email@acme.com'
                          control={control}
                          rules={{
                            pattern: REGEX_PATTERNS.EMAIL
                          }}
                          error={errors.clientEmail}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </CollapseRow>
              <CollapseRow title='Service/Product' initialState={sale ? false : true}>
                <div className='w-full flex space-x-4'>
                  <Controller
                    control={control}
                    name='services'
                    render={({
                      field: { onChange, value },
                      formState: { errors }
                    }) => {
                      const errorMessage: string = errors?.services?.message
                      return (
                        <div className='w-1/2 flex flex-col space-y-2'>
                          <FormLabel htmlFor='service'>Add Service(s)</FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={servicesOptions}
                            disabled={sale && sale?.amountClientPaid > 0}
                            setSelected={onChange}
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name='products'
                    render={({
                      field: { onChange, value },
                      formState: { errors }
                    }) => {
                      const errorMessage: string = errors?.products?.message
                      return (
                        <div className='w-1/2 flex flex-col space-y-2'>
                          <FormLabel htmlFor='service'>Add Product(s)</FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={productsOptions}
                            disabled={sale && sale?.amountClientPaid > 0}
                            setSelected={onChange}
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      )
                    }}
                  />
                </div>
              </CollapseRow>
              {watch('services') || watch('products') ? getServiceSummary() : null}
              <CollapseRow title='Staff' initialState={sale ? false : true}>
                <div className='w-full flex flex-col space-y-2'>
                  <Controller
                    control={control}
                    name='staff'
                    render={({
                      field: { onChange, value },
                      formState: { errors }
                    }) => {
                      const errorMessage: string = errors?.staff?.message
                      return (
                        <>
                          <FormLabel htmlFor='staff'>Add Staff</FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={staffMembersOptions}
                            setSelected={onChange}
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </>
                      )
                    }}
                  />
                </div>
              </CollapseRow>
              <div className='flex space-x-4'>
                {!enableAddOns ? (
                  <Button
                    variant='light'
                    size='xs'
                    rounded='lg'
                    type='button'
                    className='border border-grey-100'
                    onClick={() => {
                      setEnableAddons(true)
                    }}
                  >
                    Add Extra Charge
                    <SvgPlus width='14px' height='14px' />
                  </Button>
                ) : null}
                {!enablePromo ? (
                  <Button
                    variant='light'
                    size='xs'
                    rounded='lg'
                    type='button'
                    className='border border-grey-100'
                    onClick={() => {
                      setEnablePromo(true)
                    }}
                  >
                    Add promo code
                    <SvgPlus width='14px' height='14px' />
                  </Button>
                ) : null}
                {!enableDiscount ? (
                  <Button
                    variant='light'
                    size='xs'
                    rounded='lg'
                    type='button'
                    className='border border-grey-100'
                    onClick={() => {
                      setEnableDiscount(true)
                    }}
                  >
                    Add discount
                    <SvgPlus width='14px' height='14px' />
                  </Button>
                ) : null}
              </div>
              {enableAddOns ? (
                <div className='w-full flex space-x-4'>
                  <div className='w-1/2'>
                    <Input
                      name='addOnReason'
                      label='Extra charge'
                      id='addons'
                      type='text'
                      placeholder='Extra charge'
                      control={control}
                      error={errors.addOnReason}
                    />
                  </div>
                  <Controller
                    control={control}
                    name='addOnAmount'
                    rules={{
                      required: watch('addOnReason')
                        ? ERRORS.ADDON_AMOUNT_REQUIRED
                        : false
                    }}
                    render={({ field }) => (
                      <div className='w-1/2 flex flex-col space-y-2'>
                        <FormLabel htmlFor='adds-on-amount'>
                          Amount
                        </FormLabel>
                        <input
                          className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                          type='text'
                          {...field}
                          value={formatNumber(field.value)}
                          onChange={(e) =>
                            field.onChange(formatNumber(e.target.value))
                          }
                          placeholder='Amount'
                        />
                        {errors?.addOnAmount?.message && (
                          <FormHelperText variant='error'>
                            {errors.addOnAmount.message}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  />
                </div>
              ) : null}
              {enablePromo ? (
                <div className='w-1/2 pr-2'>
                  <SearchTerm
                    placeholder='Enter Promo code'
                    label='Promo code'
                    showIcon={false}
                    setDebouncedSearchQuery={setDebouncedSearchQuery}
                  />
                </div>
              ) : null}
              {enableDiscount && !isDiscountApplied ? (
                <div className='flex justify-between items-center'>
                  <div>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Discount
                    </Paragraph>
                  </div>
                  <div className='flex w-1/2 border rounded-lg'>
                    <div className='pl-2 py-2 rounded-l '>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='light'
                        className={
                          discountType === 'percentage'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('percentage')}
                      >
                        %
                      </Button>
                    </div>
                    <div className=' py-2'>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='light'
                        className={
                          discountType === 'fixed_value'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('fixed_value')}
                      >
                        {DEFAULT_CURRENCY}
                      </Button>
                    </div>
                    <div className='py-2 flex-1'>
                      <Input
                        control={control}
                        type='number'
                        id='discount'
                        name='discountValue'
                        className='h-5 py-4 border-0'
                        rules={{
                          pattern: REGEX_PATTERNS.NUMBER
                        }}
                      />
                    </div>
                    <div className='py-2 rounded-r'>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='transparent'
                        onClick={() => applyDiscount()}
                        disabled={!discountType}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              <Controller
                control={control}
                name='note'
                render={({
                  field: { onChange, onBlur, name, ref, value },
                  formState: { errors }
                }) => {
                  const maxLength = 400
                  const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                    errors?.note?.message as string
                  )
                  const helperTexts = []

                  helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
                  if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

                  return (
                    <FormTextarea
                      type='text'
                      id='about'
                      label='Note'
                      placeholder='Add a note'
                      {...{
                        ref,
                        name,
                        value,
                        onChange,
                        onBlur,
                        maxLength
                      }}
                      helperText={helperTexts}
                    />
                  )
                }}
              />

              <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-3 py-5'>
                {!sale || sale && (sale?.paymentMethod === 'unpaid' || !sale?.paymentMethod) ?
                  <>
                    <div className='w-full'>
                      <SelectInput
                        name='hasMadePayment'
                        id='made-payment'
                        label='Has client made payment?'
                        control={control}
                        options={[{
                          label: 'Yes',
                          value: 'yes'
                        }, {
                          label: 'No',
                          value: 'no'
                        }]}
                        placeholder='Choose one'
                      />
                    </div>
                  </>
                  : sale && sale?.amountClientPaid < appointmentSummary?.totalPaid ?
                    <div className='w-full'>
                      <Controller
                        control={control}
                        name='amountClientPaid'
                        rules={{ required: watch('hasMadePayment') ? 'Please enter a price' : false }}
                        render={({ field, formState: { errors } }) => (
                          <div className='w-full flex flex-col space-y-2'>
                            <FormLabel htmlFor='price'>How much of the balance is the client paying?</FormLabel>
                            <input
                              className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) => field.onChange(formatNumber(e.target.value))}
                              placeholder='Enter price'
                              max={appointmentSummary?.totalPaid}
                            />
                            {errors?.amountClientPaid?.message && (
                              <FormHelperText variant='error'>
                                {errors.amountClientPaid.message}
                              </FormHelperText>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  : null}
                  {watch('hasMadePayment') && watch('hasMadePayment') === 'yes' ? (
                    <div className='w-full'>
                      <SelectInput
                        name='paymentMethod'
                        id='payment-method'
                        label='Select Payment Method?'
                        control={control}
                        error={errors.paymentMethod}
                        options={SALES_PAYMENT_METHODS}
                        placeholder='Choose one'
                        rules={{
                          required: 'Please select payment method'
                        }}
                      />
                    </div>
                  ) : null}
                  {watch('paymentMethod') && watch('hasMadePayment') ? (
                    <>
                    {!sale || sale && sale?.amountClientPaid === 0 ?
                      <div className='w-full'>
                        <Controller
                          control={control}
                          name='amountClientPaid'
                          rules={{ required: watch('hasMadePayment') ? 'Please enter a price' : false }}
                          render={({ field, formState: { errors } }) => (
                            <div className='w-full flex flex-col space-y-2'>
                              <FormLabel htmlFor='price'>How much did the client pay?</FormLabel>
                              <input
                                className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                                type='text'
                                {...field}
                                value={formatNumber(field.value)}
                                onChange={(e) => field.onChange(formatNumber(e.target.value))}
                                placeholder='Enter price'
                                max={appointmentSummary?.totalPaid}
                              />
                              {errors?.amountClientPaid?.message && (
                                <FormHelperText variant='error'>
                                  {errors.amountClientPaid.message}
                                </FormHelperText>
                              )}
                            </div>
                          )}
                        />
                      </div> : null}
                      <div className='w-full'>
                        {watch('paymentMethod') === 'pos' &&
                          posTerminals &&
                          posTerminals.length ? (
                          <SelectInput
                            name='paymentMethodId'
                            id='transfers'
                            label='Point-of-Sale Terminal (POS)'
                            control={control}
                            error={errors.paymentMethodId}
                            options={posTerminalsOptions}
                            placeholder='Select Point-of-Sale Terminal'
                          />
                        ) : null}
                        {watch('paymentMethod') === 'bank_transfer' &&
                          bankTransfers &&
                          bankTransfers.length ? (
                          <SelectInput
                            name='paymentMethodId'
                            id='pos'
                            label='Bank'
                            control={control}
                            error={errors.paymentMethodId}
                            options={transfersOptions}
                            placeholder='Select Bank'
                          />
                        ) : null}
                      </div>
                    </>
                  ) : null}
              </div>
            </div>
            <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
              <div className='w-full flex justify-between py-4 px-8'>
                <div className='bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    TOTAL: {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.totalPaid} />
                  </Paragraph>
                </div>
                <div className='flex space-x-2'>
                  <Button
                    variant='text'
                    className='text-red-600 mr-6'
                    size='none'
                    fontWeight='semiBold'
                    type='button'
                    onClick={() => {
                      props.closeModal('close')
                      reset()
                    }}
                  >
                    Cancel
                  </Button>
                  {(sale && sale?.saleStatus === 'partially_paid') || (!sale && watch('hasMadePayment') === 'yes' && watch('amountClientPaid') && Number(watch('amountClientPaid')?.toString()?.replaceAll(",", "")) < appointmentSummary?.totalPaid) ?
                    <Button
                      variant='primary'
                      className=''
                      disabled={
                        createSaleIsLoading || previewAppointmentPriceIsLoading
                      }
                      loading={
                        createSaleIsLoading || previewAppointmentPriceIsLoading
                      }
                      size='md'
                      rounded='lg'
                      type='button'
                      onClick={openDialogModal}
                    >
                      {!sale && watch('hasMadePayment') === 'yes' ? "Add sale" : sale ? "Save Sale" : "Save as Unpaid"}
                    </Button>
                    :
                    <Button
                      variant='primary'
                      className=''
                      disabled={
                        createSaleIsLoading || previewAppointmentPriceIsLoading
                      }
                      loading={
                        createSaleIsLoading || previewAppointmentPriceIsLoading
                      }
                      size='md'
                      rounded='lg'
                    >
                      {!sale && watch('hasMadePayment') === 'yes' ? "Add sale" : sale ? "Save Sale" : "Save as Unpaid"}
                    </Button>}
                </div>
              </div>
            </div>
          </form>
        }
      </Modal>
      <ProceedSalePaymentInfo
        isVisible={isDialogModalVisible}
        closeModal={finalizeDialogModalAction}
        amountClientPaid={sale ? watch('amountClientPaid') : null}
      />
    </>
  )
}

export default AddSaleModal
