import React, { useEffect, useState } from 'react'
import {
  Appointment,
  CancelledAppointmentSummaryDocument,
  PaystackPaymentAuthorization
} from '../graphql/generated'
import FullStory from 'react-fullstory'
import { Button, Heading, Paragraph } from '../ui'
import { SvgDate, SvgLogo } from '../ui/icons'
import { COLORS } from '../constants/colors'
import BookingPageShimmer from '../uicomponents/bookingSales/BookingPageShimmer'
import {
  formatInToPrice,
  getNumberMonthAndYearFromDate,
  getTimeFromDate
} from '../utils/misc'
import PaymentModal from '../modals/PaymentModal'
import { useModal } from '../hooks'
import PaymentConfirmed from '../uicomponents/PaymentConfirm'
import axios from 'axios'
import { print } from 'graphql'

const PayCancellationFee = () => {
  const [appointment, setAppointment] = useState<Appointment>(null)
  const [isPaymentPageLoading, setIsPaymentPageLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [cancellationFee, setCancellationFee] = useState(null)
  const [paymentAuthorization, setPaymentAuthorization] =
    useState<PaystackPaymentAuthorization>(null)
  const [paymentMadeSuccessfully, setPaymentMadeSuccessfully] = useState(false)
  const parts = window.location.href.split('/')
  const appointmentId = parts[parts.length - 1]
  // @ts-expect-error Env variables has been set in the application.html.erb
  const ORG_ID = envVariables.fullstoryOrgId

  const getCancelledAppointmentSummary = async () => {
    setIsPaymentPageLoading(true)
    const { data } = await axios.post(
      '/graphql',
      {
        query: print(CancelledAppointmentSummaryDocument),
        variables: { appointmentId }
      },
    )
    const response = data.data.cancelledAppointmentSummary
    setAppointment(response.appointment)
    setPaymentAuthorization(response.paymentAuthorization)
    setMessage(response.message)
    setCancellationFee(response.cancellationFee)
    setIsPaymentPageLoading(false)
  }

  const {
    isVisible: paymentModalIsvisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal
  } = useModal()

  const confirmPayment = () => {
    closePaymentModal()
    setPaymentMadeSuccessfully(true)
  }

  useEffect(() => {
    appointmentId && getCancelledAppointmentSummary()
  }, [])

  return (
    <>
      <FullStory org={ORG_ID} />
      <main className='w-full h-screen flex flex-col space-y-12 p-4 xl:p-12'>
        <div className='flex justify-center items-end space-x-2 mt-[20px]'>
          <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>
            Powered by
          </Paragraph>{' '}
          <SvgLogo width='90px' height='100%' />
        </div>

        {isPaymentPageLoading && (
          <div className='w-full flex flex-col xl:flex-row border border-grey-20 rounded-lg p-4 xl:p-10 max-w-[1000px] mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]'>
            <BookingPageShimmer />
          </div>
        )}

        {appointment && !isPaymentPageLoading ? (
          <div className='w-full flex flex-col xl:flex-row border border-grey-20 rounded-lg p-4 xl:p-10 max-w-[1000px] mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]'>
            <div className='w-full flex flex-col space-y-6 max-w-[360px]'>
              <div className='flex flex-col space-y-4'>
                <Heading
                  variant='h1'
                  size='h8'
                  weight='bold'
                  color={COLORS.GREY['900']}
                >
                  Pay your cancellation fee
                </Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>
                  {message}
                </Paragraph>
              </div>
              <div className='flex space-x-4'>
                <SvgDate width='24px' height='24px' />
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>
                  {getNumberMonthAndYearFromDate(appointment?.startAt, appointment?.salon?.business?.timezone)} ・{' '}
                  {getTimeFromDate(appointment?.startAt)}
                </Paragraph>
              </div>
              <div className='flex flex-col p-4 space-y-2 bg-grey-25 rounded-md'>
                <div>
                  <div className='flex justify-between items-center'>
                    <Paragraph
                      size='b4'
                      color={COLORS.GREY[300]}
                      weight='semiBold'
                    >
                      Cancellation amount
                    </Paragraph>
                    <Paragraph
                      size='b4'
                      color={COLORS.GREY[300]}
                      weight='semiBold'
                    >
                      {formatInToPrice(cancellationFee)}
                    </Paragraph>
                  </div>
                </div>
              </div>
              {paymentAuthorization ? (
                <Button
                  onClick={openPaymentModal}
                  variant='secondary'
                  rounded='none'
                  size='sm'
                  fontSize='b5'
                  fontWeight='semiBold'
                >
                  Pay
                </Button>
              ) : null}
            </div>

            <div className='w-full max-w-[490px] bg-white py-[28px] px-[32px] border border-grey-20 h-full flex flex-col items-start space-y-5 overflow-y-auto'>
              <Heading variant='h2' size='h10' weight='bold'>
                Order summary
              </Heading>
              <div className='w-full flex justify-between'>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>
                    Booking ID: #{appointment?.bookingId}
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>
                    Date:{' '}
                    {getNumberMonthAndYearFromDate(appointment?.startAt, appointment?.salon?.business?.country?.timezone) || '-'}
                  </Paragraph>
                </div>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>
                    Billed to: {appointment?.client?.firstName}{' '}
                    {appointment?.client?.lastName}
                  </Paragraph>
                  <Paragraph
                    size='b5'
                    color={COLORS.GREY[400]}
                    className='capitalize'
                  >
                    Status: {appointment?.appointmentStatus}
                  </Paragraph>
                </div>
              </div>
              <div className='w-full flex flex-col space-y-2'>
                <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                  <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>
                    Item
                  </Heading>
                  <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>
                    Amount
                  </Heading>
                </div>

                {appointment?.appointmentServices?.map((service) => (
                  <div
                    className='flex justify-between items-center px-4 py-2 line-through'
                    key={service?.serviceId}
                  >
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      {service?.name}
                    </Paragraph>
                    <Paragraph size='b5'>
                      {formatInToPrice(service?.price)}
                    </Paragraph>
                  </div>
                ))}

                {appointment?.appointmentProducts?.map((product) => (
                  <div
                    className='flex justify-between items-center px-4 py-2 line-through'
                    key={product?.productId}
                  >
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      {product?.name}
                    </Paragraph>
                    <Paragraph size='b5'>
                      {formatInToPrice(product?.price)}
                    </Paragraph>
                  </div>
                ))}

                <div className='border-t border-grey-50'></div>
                <div className='flex justify-between items-center px-4 py-2 line-through'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    Subtotal
                  </Paragraph>
                  <Paragraph size='b5'>
                    {formatInToPrice(
                      ((appointment?.totalServicesAmount as number) +
                        appointment?.totalProductsAmount) as number
                    )}
                  </Paragraph>
                </div>
                <div className='flex justify-between items-center px-4 py-2 line-through'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    Tax
                  </Paragraph>
                  <Paragraph size='b5'>
                    {formatInToPrice(appointment?.taxAmount)}
                  </Paragraph>
                </div>
                <div className='flex justify-between items-center px-4 py-2 line-through'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    Discount
                  </Paragraph>
                  <Paragraph size='b5'>
                    -
                    {formatInToPrice(appointment?.appointmentPromo?.amount) ||
                      0}
                  </Paragraph>
                </div>
                <div className='border-t border-grey-50'></div>
                <div className='flex justify-between items-center px-4 py-2 line-through'>
                  <Paragraph
                    size='b5'
                    weight='semiBold'
                    className='inline'
                    color={COLORS.GREY[300]}
                  >
                    Total
                  </Paragraph>
                  <Paragraph size='b5' weight='semiBold' className='inline'>
                    {formatInToPrice(appointment?.totalPaid)}
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <PaymentModal
          isVisible={paymentModalIsvisible}
          closeModal={closePaymentModal}
          confirmPayment={confirmPayment}
          paymentAuthorization={paymentAuthorization || null}
          salonId={appointment?.salon?.id}
        ></PaymentModal>
        {paymentMadeSuccessfully && <PaymentConfirmed />}
      </main>
    </>
  )
}

export default PayCancellationFee
