import { SalonHour } from "../graphql/generated";
import { HelperTextProps } from "../ui";
import { checkStaffWorkingSchedule } from "../uicomponents/utils";
import { getStaffRoleName } from "./misc";

export const getHelperTextForReactHookFormErrors = (
  message: string
): HelperTextProps | undefined => {
  return typeof message === "string"
    ? {
        variant: "error",
        message,
      }
    : undefined;
};

export const getHelpTextForCharacterLeft = (
  maxLength: number,
  value = ""
): HelperTextProps => {
  const characterLength = `${value || ""}`.length;
  return {
    message: `${maxLength - characterLength} of ${maxLength} characters left`,
    variant: "info",
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatStaffUpdateData = (data: any, salonHours: SalonHour[]) => {
  const getWorkingSchedule = () => {
    if (data && data?.workingSchedule && !data?.workingSchedule?.workScheduleType) {
      const workingType = checkStaffWorkingSchedule(salonHours, data?.workingSchedule?.workSchedule)
      return {
        workScheduleType: workingType,
        workSchedule: data?.workingSchedule?.workSchedule?.filter(schedule => schedule?.openTime && schedule?.closeTime)
      }
    }

    return {
      workScheduleType: data?.workingSchedule?.workScheduleType,
      workSchedule: data?.workingSchedule?.workSchedule?.filter(schedule => schedule?.openTime && schedule?.closeTime)
    }
  }

  const inputData = {
    salaryInformation: data?.salaryInformation,
    reminder: data?.reminder,
    workingSchedule: getWorkingSchedule(),
    employmentDetails: {
      canLogin: data?.employmentDetails?.canLogin || false,
      canServeCustomers: data?.employmentDetails?.canServeCustomers || false,
      endDate: data?.employmentDetails?.endDate || null,
      level: data?.employmentDetails?.level?.toString(),
      present: data?.employmentDetails?.present,
      salonId: data?.salonId,
      specialty: data?.employmentDetails?.specialty,
      startDate: data?.employmentDetails?.startDate || null,
      role: data?.employmentDetails?.level && getStaffRoleName(data?.employmentDetails?.level?.toString()),
      services: Array?.isArray(data?.employmentDetails?.services) && data?.employmentDetails?.services?.length ? data?.employmentDetails?.services : []
    },
    salonId: data?.salonId,
    active: data?.active,
    id: data?.id,
    leave: { daysEntitled: data?.leave?.daysEntitled || "0" },
    user: { firstName: data?.user?.firstName, lastName: data?.user?.lastName, email: data?.user?.email || "", countryCode: data?.user?.countryCode, phone: data?.user?.phone, dateOfBirth: data?.user?.dateOfBirth, profileUrl: data?.user?.profileUrl, callingCode: data?.user?.callingCode }
  }

  return inputData;
};