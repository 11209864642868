import React, { createContext, useContext, useState } from 'react'
import {
  CreateServiceDocument,
  CreateServiceInput,
  DeleteServiceDocument,
  Service,
  ServicesDocument,
  UploadServiceDocument,
  UploadServiceInput
} from '../graphql/generated'
import axios from 'axios'
import { useToast } from '../hooks'
import { print } from 'graphql'
import { useSalonCache } from '../hooks/useSalonCache'

const ServiceContext = createContext(undefined)

const ServiceProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [services, setServices] = useState<Service[]>([])
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache()

  const sortServices = (services: Service[]) => {
    const data = [...services].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return data
  }

  const getServices = async (q?: string) => {
    axios
      .post(
        '/graphql',
        {
          query: print(ServicesDocument),
          variables: { salonId: getSalonFieldValue('id'), q }
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { services }
          }
        } = res
        setServices(sortServices(services))
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const deleteService = async (id: string) => {
    axios
      .post(
        '/graphql',
        { query: print(DeleteServiceDocument), variables: { input: { id } } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { deleteService: deleteService_ }
          }
        } = res

        if (deleteService_.status === 200) {
          addToast({ message: 'Service deleted', variant: 'success' })
          getServices()
        } else {
          addToast({
            message: deleteService_.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const createService = async (input: CreateServiceInput, closeModal: () => void, reset: () => void) => {
    axios
      .post(
        '/graphql',
        {
          query: print(CreateServiceDocument),
          variables: { input: { ...input, salonId: getSalonFieldValue('id') } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const createService_ = res.data.data.createService
        if (createService_.status === 200) {
          getServices()
          addToast({
            message: 'Service saved successfully',
            variant: 'success'
          })
          closeModal()
          reset()
        } else {
          addToast({
            message: createService_.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const uploadService = async (input: UploadServiceInput) => {
    try {
      const {
        data: {
          data: { uploadService: us }
        }
      } = await axios.post(
        '/graphql',
        { query: print(UploadServiceDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (us.status === 200) {
        addToast({message: 'Services uploaded successfully. This may take a few minutes to process', variant: 'success'})
        getServices()
      } else {
        addToast({message: us?.errors[0]?.message, variant: 'error'})
      }
    } catch (error) {
      addToast({ message: error?.message, variant: 'error' })
    }
  }

  return (
    <ServiceContext.Provider
      value={{
        services,
        toast,
        getServices,
        deleteService,
        createService,
        uploadService,
        addToast,
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

// Service Context custom hook
export const useServiceContext = () => {
  const context = useContext(ServiceContext)
  if (!context) {
    throw new Error('useServiceContext must be used with a ServiceProvider')
  }

  return context
}

export default ServiceProvider
