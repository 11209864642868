import React from 'react'
import { Button, Modal, Paragraph, Pill } from "../../../ui";
import { SvgArrowBack, SvgChevronLeft } from '../../../ui/icons';
import { COLORS } from '../../../constants/colors';
import { ViewCustomerVoucherModalProps } from '../types';
import { formatInToPrice, formatSnakeCaseText, getNumberMonthAndYearFromDate } from '../../../utils/misc';
import { VoucherService } from '../../../graphql/generated';

const ViewCustomerVoucherModal = ({
  isVisible,
  closeModal,
  voucher,
}: ViewCustomerVoucherModalProps) => {

  const nameMap = {
    "packageName": "Voucher Name",
    "servicesIncluded": "Services",
    "voucherCode": "Voucher Code",
    "price": "Total Price",
    "totalPrice": "Total Price",
    "totalServices": "Total Services",
    "voucherBalance": "Voucher Balance",
    "servicesRemaining": "Services Remaining",
    "location": "Location",
    "dateOfPurchase": "Date of Purchase",
    "status": "Status"
  };

  const getLocation = () => {
    const salonId = voucher?.salonId;
    const salons = voucher?.business?.salons;

    const salon = Array?.isArray(salons) && salons.find(salon => salon?.id === salonId);

    if (salon) {
      return salon?.branchName;
    }

    return "-"
  }

  const getVoucherPillsForServices = (voucherServices: VoucherService[]) => {
    if (Array.isArray(voucherServices) && voucherServices.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {voucherServices.map(voucherService => {
          return <Pill variant='light' rounded='lg' key={voucherService?.id}>{voucherService?.name}
            <span className="bg-grey-300 text-white p-1 ml-2 rounded-full text-xs">
              {voucherService?.quantity}
            </span></Pill>
        })}
      </div>)
    }

    return "-"
  }

  const getVoucherPillsForRemainingServices = (voucherServices: VoucherService[]) => {
    if (Array.isArray(voucherServices) && voucherServices.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {voucherServices?.map(voucherService => {
          return <Pill variant='light' rounded='lg' key={voucherService?.id}>{voucherService?.name} {voucherService?.quantity - voucherService?.redeemedCount || 0}/{voucherService?.quantity}</Pill>
        })}
      </div>)
    }

    return "-"
  }

  const voucherMap = {
    "packageName": voucher?.package?.name,
    "servicesIncluded": getVoucherPillsForServices(voucher?.voucherServices),
    "voucherCode": voucher?.code,
    "price": formatInToPrice(voucher?.price),
    "totalServices": voucher?.voucherServices?.length,
    "voucherBalance": formatInToPrice(voucher?.price - voucher?.amountRedeemed),
    "servicesRemaining": getVoucherPillsForRemainingServices(voucher?.voucherServices),
    "location": getLocation(),
    "dateOfPurchase": getNumberMonthAndYearFromDate(voucher?.createdAt, voucher?.business?.country?.timezone) || "-",
    "status": <Pill variant={ voucher?.status === 'active' ? 'success' : voucher?.status === 'redeemed' ? 'primary' : voucher?.status === 'expired' ? 'light' : 'light' }>{formatSnakeCaseText(voucher?.status || "")}</Pill>
  };
  return (
    <>
      <Modal show={isVisible} closeModal={closeModal} variant='right'>
        <div className='relative'>
          <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
            </div>
          </div>
          {voucher && voucherMap ? (
            <div className='w-full max-w-5xl flex flex-col space-y-6 h-fit p-6 mt-[70px]'>
              <div className='hidden xl:flex flex-col xl:justify-between xl:items-left space-x-4'>
                <div className='flex flex-col space-y-4'>
                  <div className='w-full flex space-x-4 items-left'>
                    <Paragraph size='b2' weight='semiBold'>
                      Voucher details
                    </Paragraph>
                  </div>
                  <div className='w-full flex space-x-4 items-left'>
                    <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                      Find information about voucher here
                    </Paragraph>
                  </div>
                  <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                    {Object.entries(voucherMap).map(([key, value]) => (
                      
                      nameMap[key] && value !== null && (
                        <div key={key} className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                          <div className="flex flex-col space-y-1 p-2">
                            <Paragraph size="b4" color="grey-300" className="text-left">
                              {nameMap[key]}
                            </Paragraph>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                              {value}
                            </Paragraph>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  )
}

export default ViewCustomerVoucherModal