import React, { useEffect, useState } from 'react';
import { useSalonCache } from '../hooks/useSalonCache';
import { formatDateStringToOriginalDate, getDayMonthAndNumberFromDateString } from '../utils/misc';
import { CreateAppointmentDetailsProps, ICalendarEventProps } from '../ui/organism/calendar/types';
import { OrganizedAppointment } from '../uicomponents/appointmentComponents/types';
import { useToast } from '../hooks/useToast';
import { Appointment, ClosedPeriod, DeleteAppointmentDocument, RevertAppointmentCancellationDocument, SalonStaff, StaffBlockedTime, UpdateAppointmentStatusDocument, User } from '../graphql/generated';
import { useModal } from '../hooks/useModal';
import { PAGE_ROUTES } from '../constants/routes';
import ToastWrapper from '../ui/molecules/toastWrapper/PlainToast';
import { ToastProps } from '../ui/atoms/toast/types';
import Layout from '../ui/layout/Layout';
import { COLORS } from '../constants/colors';
import Calendar from '../ui/organism/calendar/Calendar';
import Heading from '../ui/atoms/heading/Heading';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import Button from '../ui/atoms/button/Button';
import UnassignedAppointmentList from '../uicomponents/appointmentComponents/UnassignedAppointmentList';
import DeleteAppointmentModal from '../uicomponents/appointmentComponents/modals/DeleteAppointmentModal';
import SaveAppointmentModal from '../uicomponents/appointmentComponents/modals/SaveAppointmentModal';
import CancelAppointmentModal from '../uicomponents/appointmentComponents/modals/CancelAppointmentModal';
import SuccessModal from '../modals/SuccessModal';
import BlockTimeModal from '../uicomponents/appointmentComponents/modals/BlockTimeModal';
import BusinessClosePeriodModal from '../uicomponents/appointmentComponents/modals/BusinessClosePeriodModal';
import ViewAppointmentModal from '../modals/ViewAppointmentModal';
import AddAppointmentModal from '../modals/AddAppointmentModal';
import { formatAppointmentsToCalendarEvents, formatBlockedTimeDataToCalendarEvents, formatClosedPeriodDataToCalendarEvents, formatTimeOffDataToCalendarEvents, groupToDates, pickClosedPeriodForSpecificDate } from '../uicomponents/appointmentComponents/utils';
import { fetchStaffListData, fetchUnassignedAppointments, organizeUnassignedAppointmentsByDate } from '../modals/utils';
import { fetchBlockTimeData, fetchCalendarAppointments, fetchClosedPeriodsData, fetchTimeOffData } from '../uicomponents/appointmentComponents/api';
import { print } from 'graphql'
import axios from 'axios';
import { API_ERRORS } from '../constants/errors';
import ActionCable from "actioncable";
import NoShowAppointmentModal from '../uicomponents/appointmentComponents/modals/NoShowAppointmentModal';
import { SvgClose } from '../ui/icons';
import { canPerformAction } from '../utils/permission';
import { PERMISSION_CONSTANTS } from '../constants/permission';

const AppointmentPage = () => {
  const { getSalonFieldValue, getSalonData } = useSalonCache();
  // const businessCountry = JSON.parse(localStorage.getItem('country'))
  // const currentDateTimeZone = moment().tz(businessCountry?.timezone || 'Africa/Lagos');

  // // Get the date components in the local time zone
  // const currentYear = currentDateTimeZone.year();
  // const currentMonth = currentDateTimeZone.month(); // 0-based index for month
  // const currentDay = currentDateTimeZone.date();
  // // Create a new Date object using the local time components
  // const currentBusinessDate = new Date(Date.UTC(currentYear, currentMonth, currentDay));

  // @ts-expect-error Env variables has been set in the application.html.erb
	const webSocketUrl = envVariables.webSocketUrl;
  const salon = getSalonData();
  const salonId = getSalonFieldValue('id');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState<string | null>(null);
  const [createAppointmentDetails, setCreateAppointmentDetails] = useState<CreateAppointmentDetailsProps | null>(null);
  const [events, setEvents] = useState<ICalendarEventProps[]>([]);
  const [organizedUnassignedAppointments, setOrganizedUnassignedAppointments] = useState<OrganizedAppointment[]>([]);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [blockedTime, setBlockedTime] = useState<StaffBlockedTime | null>(null);
  const [showClosedPeriodOverlay, setShowClosedPeriodOverlay] = useState(true);
  const [closedPeriodId, setClosedPeriodId] = useState<string | null>(null);
  const [closedPeriod, setClosedPeriod] = useState<ClosedPeriod | null>(null);
  const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[] | null>(null);
  const [editClosedPeriod, setEditClosedPeriod] = useState<ClosedPeriod | null>(null);
  const [staffBlockedTimeData, setBlockedTimeData] = useState<StaffBlockedTime[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appointmentServiceId, setAppointmentServiceId] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cancelType, setCancelType] = useState<string>('service');
  const { toast, addToast } = useToast();
  const [isUnassignedAppointmentsLoading, setIsUnassignedAppointmentsLoading] = useState(false);
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/');
  const appointmentIdInRoute = parts[parts.length - 1];

  const [staff, setStaff] = useState<SalonStaff[]>([])
  const user: User = JSON.parse(localStorage.getItem('userData'))
  const country = JSON.parse(localStorage.getItem('country') as string);
  const timezone = country?.timezone || 'Africa/Lagos'
  const changeCalendarView = (view: string) => {
    setCurrentView(view);
  };

  const actions = {
    addAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.addAppointment),
    deleteAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.deleteAppointment),
    rescheduleAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.rescheduleAppointment),
    cancelAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.cancelAppointment),
    markNoShow: canPerformAction(PERMISSION_CONSTANTS?.calendar?.markNoShow),
    addBlockTime: canPerformAction(PERMISSION_CONSTANTS?.calendar?.addBlockTime),
    editClosedPeriod: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editClosedPeriod),
    applyDiscounts: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyDiscounts),
    applyPromo: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyPromo),
    viewAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.viewAppointment),
    editBlockedTime: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editBlockTime),
    canViewAllCalendar: canPerformAction(PERMISSION_CONSTANTS?.calendar?.canViewAllCalendar),
    canCheckInOrCheckOutAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.checkInOrOut),
    editPrices: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editPrices),
  }

  const changeDates = ({ start, end, view }: {
    start: string,
    end: string,
    view: string,
  }) => {
    setStartDate(formatDateStringToOriginalDate(start, 'start'));
    setEndDate(formatDateStringToOriginalDate(view !== 'resourceTimeGridDay' ? end : start, 'end'));
  }

  const getStaffData = async() => {
    const staffData = await fetchStaffListData({ salonId: salon?.id, startDate: formatDateStringToOriginalDate(startDate, 'start'), endDate: formatDateStringToOriginalDate(endDate, 'end') });
    setStaff(staffData?.staffs);
  }

  useEffect(() => {
    if (startDate && endDate) {
      refetchCalendarData();
      setShowClosedPeriodOverlay(true)
      getStaffData()
    }
  }, [currentView, startDate, endDate]);

  useEffect(() => {
    if (appointmentIdInRoute && appointmentIdInRoute !== PAGE_ROUTES.CALENDAR.replace('/', '')) {
      setAppointmentId(appointmentIdInRoute);
      openViewAppointmentModal();
    }
  }, [appointmentIdInRoute])

  const refetchCalendarData = async () => {
    setClosedPeriod(null)
    setEditClosedPeriod(null)
    setIsUnassignedAppointmentsLoading(true);
    const assignedData = await fetchUnassignedAppointments({ salonId: salon?.id, status: "pending" })

    const unAssignedAppointments = organizeUnassignedAppointmentsByDate(assignedData || []);
    setOrganizedUnassignedAppointments(actions?.viewAppointment ? unAssignedAppointments : []);
    setIsUnassignedAppointmentsLoading(false)

    const appointmentsOnlyData = await fetchCalendarAppointments({ salonId: salon?.id, startDate, endDate });
    const appointmentData = formatAppointmentsToCalendarEvents(appointmentsOnlyData || [], currentView, timezone);

    const staffOffPeriodData = await fetchTimeOffData({ salonId: salon?.id });
    const timeOffData = formatTimeOffDataToCalendarEvents(staffOffPeriodData || [], timezone);

    const blockTimeData = await fetchBlockTimeData({ salonId: salon?.id, startDate, endDate });
    setBlockedTimeData(blockTimeData)
    const blockedTimeData = formatBlockedTimeDataToCalendarEvents(blockTimeData || [], timezone);

    const businessClosedPeriodData = await fetchClosedPeriodsData({ salonId: salon?.id });
    const closedPeriodsDatesGroup = groupToDates(businessClosedPeriodData)
    setClosedPeriods(businessClosedPeriodData)
    const formatClosedPeriodData = () => formatClosedPeriodDataToCalendarEvents(closedPeriodsDatesGroup || []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const closedPeriodData = currentView !== 'resourceTimeGridDay' ? formatClosedPeriodData() : [];

    setEvents([...appointmentData, ...timeOffData, ...blockedTimeData, ...closedPeriodData]);

    if (currentView !== 'resourceTimeGridDay') {
      setClosedPeriod(null);
    } else {
      if (closedPeriodsDatesGroup && businessClosedPeriodData && Array.isArray(closedPeriodsDatesGroup) && closedPeriodsDatesGroup.length > 0) {
        const getClosedPeriodForDayView = pickClosedPeriodForSpecificDate(closedPeriodsDatesGroup, businessClosedPeriodData, startDate);
        if (getClosedPeriodForDayView) {
          setClosedPeriod(getClosedPeriodForDayView)
        }
      }
    }
  }

  React.useEffect(() => {
		const cable = ActionCable.createConsumer(webSocketUrl);
		cable.subscriptions.create(
			{ channel: "PendingAppointmentsChannel", salon_id: getSalonFieldValue("id") },
			{
				connected() {
					console.log("Connected to Action Cable Appointments");
				},
				received: (data) => {
					// handle notification here
          const appointments = data.appointments as Appointment[]
          if (Array?.isArray(appointments)) {
            const mergedAndOrganizedAppointments = organizeUnassignedAppointmentsByDate(appointments);
            setOrganizedUnassignedAppointments(mergedAndOrganizedAppointments);
          }
				},
			}
		);
	}, []);

  // const markAsNoShow = async () => {
  //   if (!appointmentId) return;
  //   try {
  //     const payload = {
  //       appointmentStatus: "no_show",
  //       id: appointmentId,
  //       salonId,
  //     }

  //     await axios.post(
  //       '/graphql',
  //       {
  //         query: print(UpdateAppointmentStatusDocument),
  //         variables: { input: { ...payload } },
  //       },
  //       { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  //     ).then(({ data }) => {
  //       if (data?.data?.updateAppointmentStatus?.status === 200) {
  //         closeViewAppointmentModal();
  //         refetchCalendarData();
  //         addToast({
  //           message: "Appointment updated successfully",
  //           variant: 'success',
  //         })
  //       }

  //       if (data?.errors?.length) {
  //         const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
  //         addToast && addToast({
  //           variant: 'error',
  //           message,
  //         })
  //       }


  //       if (data?.data?.updateAppointmentStatus?.errors?.length) {
  //         const message = data?.data?.updateAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
  //         addToast && addToast({
  //           variant: 'error',
  //           message,
  //         })
  //       }
  //     })
  //   } catch (checkoutAppointmentError) {
  //     if (axios.isAxiosError(checkoutAppointmentError)) {
  //       const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
  //       addToast({
  //         message,
  //         variant: 'error',
  //       })
  //     }
  //   }
  // }

  const revertAppointmentCancellation = async () => {
    if (!appointmentId) return;
    try {
      const payload = {
        appointmentId
      }
      const { data } = await axios.post('/graphql', {query: print(RevertAppointmentCancellationDocument), variables: {input: {...payload}}}, {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})

      if (data.data.revertAppointmentCancellation.status === 200) {
        closeViewAppointmentModal()
        refetchCalendarData()
        addToast({message: 'Appointment updated successfully', variant: 'success'})
      } else {
        const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
      }
    } catch (error) {
      const message = error?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
      addToast({
        message,
        variant: 'error'
      })
    }
  }

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, applyCancellationFee
  }: {
    appointmentId: string,
    status: string,
    applyCancellationFee: boolean
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId,
        applyCancellationFee
      }

      await axios.post(
        '/graphql',
        {
          query: print(UpdateAppointmentStatusDocument),
          variables: { input: { ...payload } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        if (data?.data?.updateAppointmentStatus?.status === 200) {
          refetchCalendarData();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.errors?.length) {
          const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }


        if (data?.data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.data?.updateAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;
    const userId = JSON.parse(localStorage.getItem('userData')).id
    try {
      await axios.post(
        '/graphql',
        {
          query: print(DeleteAppointmentDocument),
          variables: {
            input: {
              salonId,
              id,
              userId: userId
            }
          },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        const clientDetailsData = data?.data?.deleteAppointment;
        if (clientDetailsData) {
          refetchCalendarData();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          addToast({
            message,
            variant: 'error',
          })
        }
      })
      } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const {
    isVisible: isAddAppointmentModalVisible,
    openModal: openAddAppointmentModal,
    closeModal: closeAddAppointmentModal,
  } = useModal();
  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();
  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();
  const {
    isVisible: isBlockTimeModalVisible,
    openModal: openBlockTimeModal,
    closeModal: closeBlockTimeModal,
  } = useModal();
  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();
  const {
    isVisible: isNoShowAppointmentModalVisible,
    openModal: openNoShowAppointmentModal,
    closeModal: closeNoShowAppointmentModal
  } = useModal()
  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();
  const {
    isVisible: isSaveAppointmentModalVisible,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModal: openSaveAppointmentModal,
    closeModal: closeSaveAppointmentModal,
  } = useModal();
  const {
    isVisible: isBusinessTimeModalVisible,
    openModal: openBusinessTimeModal,
    closeModal: closeBusinessTimeModal,
  } = useModal();

  const initiateCloseAddAppointmentModal = (action: string) => {
    closeAddAppointmentModal();
    if (action === 'addedAppointment') {
      openSuccessModal();
    }
  }

  const initiateCloseViewAppointmentModal = (action: string) => {
    setAppointmentId(null);
    closeViewAppointmentModal();
    if (appointmentIdInRoute && appointmentIdInRoute !== PAGE_ROUTES.CALENDAR.replace('/', '')) {
      window.location.href = PAGE_ROUTES.CALENDAR
    }
    if (action === 'updatedAppointment') {
      refetchCalendarData();
    }
  }

  const openAppointmentPopoverDialog = (serviceId: string, appointmentId: string) => {
    setAppointmentId(appointmentId.includes('T') ? appointmentId.split("T")[0] : appointmentId);
    setAppointmentServiceId(serviceId);
    openViewAppointmentModal();
  }

  const initiateEditBlockedTimeProcess = (id: string) => {
    // get block time from blockTimes
    const blockTime = staffBlockedTimeData?.find((blockTime) => blockTime?.id === id);
    if (!blockTime) {
      addToast({
        message: "Block time doesn't exist",
        variant: 'error',
      })
    }
    setBlockedTime(blockTime);
    openBlockTimeModal();
  }

  const initiateEditClosedPeriodProcess = (id: string) => {
    // get closedPeriod by id from closedPeriods
    const closedPeriod = closedPeriods?.find((closedPeriod) => closedPeriod?.id === id)
    if (!closedPeriod) {
      addToast({
        message: "Closed period doesn't exist",
        variant: 'error',
      })
    }
    setEditClosedPeriod(closedPeriod);
    openBusinessTimeModal();
  }

  const initiateCancelAppointment = () => {
    setCancelType('appointment')
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const initiateNoShowAppointment = () => {
    setCancelType('appointment')
    closeViewAppointmentModal();
    openNoShowAppointmentModal();
  }

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }

  const finalizeAppointmentCancellation = (action: string, applyCancellationFee = false) => {
    if (action === 'cancel') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointmentId as string, status: 'cancelled', applyCancellationFee
      })
      closeCancelAppointmentModal();
    } else if (action === 'no_show') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointmentId as string, status: 'no_show', applyCancellationFee
      })
      closeNoShowAppointmentModal()
    }
    else {
      closeCancelAppointmentModal();
      closeNoShowAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(appointmentId)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const closeSaveAppointment = (action: string) => {
    if (action === 'save') {
      closeSaveAppointmentModal();
    } else {
      closeSaveAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const closeSuccessModalAndReloadCalendar = () => {
    closeSuccessModal();
    refetchCalendarData();
  }

  const closeBlockTimeViewModal = () => {
    setBlockedTime(null);
    closeBlockTimeModal();
  }

  const closeModalsAndReFreshPage = () => {
    closeBusinessTimeModal();
    setClosedPeriodId(null)
    setClosedPeriod(null)
    setEditClosedPeriod(null)
    refetchCalendarData();
  }

  const formatStaffBasedOffPermission = () => {
    if (actions?.canViewAllCalendar) {
      return staff;
    }
    return staff?.filter((staffMember) => staffMember?.id === user?.salonStaff?.id);
  }

  return (
    <Layout
      pageTitle="Calendar"
    >
      <ToastWrapper toast={toast as ToastProps} />
      <div className='flex flex-col xl:flex-row w-full h-full'>
        <div className="w-full h-full max-h-[300px] xl:max-h-[100%] flex flex-col xl:max-w-[200px]">
          <UnassignedAppointmentList openAppointment={openAppointmentPopoverDialog} unassignedAppointments={organizedUnassignedAppointments} isLoading={isUnassignedAppointmentsLoading} />
        </div>
        <div className='relative w-full h-full overflow-x-scroll'>
          <Calendar
            openAddAppointmentModal={openAddAppointmentModal}
            openBlockTimeModal={openBlockTimeModal}
            openAppointment={openAppointmentPopoverDialog}
            events={events}
            currentView={currentView}
            changeCalendarView={changeCalendarView}
            changeDates={changeDates}
            staff={formatStaffBasedOffPermission()}
            initiateEditBlockedTimeProcess={initiateEditBlockedTimeProcess}
            setCreateAppointmentDetails={setCreateAppointmentDetails}
            initiateEditClosedPeriodProcess={initiateEditClosedPeriodProcess}
            actions={actions}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          {currentView === 'resourceTimeGridDay' && closedPeriod && showClosedPeriodOverlay ? (
            <div className='absolute inset-0 top-[55px] flex justify-center items-center bg-black/3 backdrop-blur-[2px] z-[40]'>
              <div className="w-full xl:border-none shadow-medium py-12 px-9 max-w-[400px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h9' weight='bold' color={COLORS.BLACK}>
                    Business is closed
                  </Heading>
                  <Paragraph size="b6" weight='medium' className='w-full text-center' color={COLORS.GREY[400]}>
                    {getDayMonthAndNumberFromDateString(closedPeriod?.startAt)} - {getDayMonthAndNumberFromDateString(closedPeriod?.endAt)}
                  </Paragraph>
                  {actions?.editClosedPeriod ?
                    <Button
                      variant="primary"
                      size='md'
                      rounded='md'
                      fontSize='b5'
                      onClick={openBusinessTimeModal}
                    >
                      Edit closed business
                    </Button> : null}
                  <Button
                    variant='text'
                    size='none'
                    type='button'
                    className='w-fit'
                    fontSize='b4'
                    onClick={() => {
                      setShowClosedPeriodOverlay(false);
                    }}
                  >
                    <SvgClose width='24px' height='24px' />
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <AddAppointmentModal
        isVisible={isAddAppointmentModalVisible}
        closeModal={initiateCloseAddAppointmentModal}
        addToast={addToast}
        salonId={salonId}
        createAppointmentDetails={createAppointmentDetails}
      />
      <SuccessModal
        isVisible={successModalIsVisible}
        closeModal={closeSuccessModalAndReloadCalendar}
      />
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={salonId}
        addToast={addToast}
        appointmentId={appointmentId}
        // markAsNoShow={markAsNoShow}
        markAsNoShow={initiateNoShowAppointment}
        refetchCalendarData={refetchCalendarData}
        revertCancellation={revertAppointmentCancellation}
      />
      <CancelAppointmentModal
        isVisible={isCancelAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
      />
      <NoShowAppointmentModal
        isVisible={isNoShowAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
      <SaveAppointmentModal
        isVisible={isSaveAppointmentModalVisible}
        closeModal={closeSaveAppointment}
      />
      <BlockTimeModal
        isVisible={isBlockTimeModalVisible}
        closeModal={closeBlockTimeViewModal}
        addToast={addToast}
        refetchCalendarData={refetchCalendarData}
        salonId={salonId}
        blockedTime={blockedTime}
        user={user}
      />
      <BusinessClosePeriodModal
        isVisible={isBusinessTimeModalVisible}
        closeModal={closeModalsAndReFreshPage}
        closedPeriod={editClosedPeriod || closedPeriod}
        closedPeriodId={closedPeriodId}
      />
    </Layout>
  )
}

export default AppointmentPage
