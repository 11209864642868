import React from 'react'
import { useSalonCache } from '../hooks/useSalonCache';
import { SaleReceiptPrintViewProps } from './types';
import Heading from '../ui/atoms/heading/Heading';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../constants/colors';
import { SvgLogo } from '../ui/icons';
import { formatInToPrice } from '../utils/misc';

const SaleReceiptPrintView = ({
  sale,
  business,
}: SaleReceiptPrintViewProps) => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  return (
    <>
      {sale ? (
        <div className='w-full py-[40px] bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
          <Heading variant='h1' size='h8' weight='bold'>{business?.name}</Heading>
          <div className='space-y-2 flex flex-col items-center px-12'>
            <Paragraph size='b5' weight='normal'>{salon?.address}, {salon?.city}, {salon?.country}</Paragraph>
          </div>
          <div className='w-full border-t border-grey-100'></div>
          <div className='space-y-3 flex flex-col items-center px-12'>
            <Heading variant='h2' size='h9' weight='bold'>Thank you for your order.</Heading>
            <Paragraph size='b5' weight='normal' className='text-center'>Thank you for choosing us! We appreciate your visit and can't wait to welcome you back for another wonderful experience!</Paragraph>
          </div>
          <div className='w-full border-t border-grey-100'></div>
          <div className='space-y-3 flex flex-col items-center px-12'>
            <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>Billed to: {sale?.client?.firstName} {sale?.client?.lastName}</Paragraph>
          </div>
          <div className='w-full flex flex-col space-y-2 px-[40px]'>
            <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
              <Heading variant='h1' size='h11'>Item</Heading>
              <Heading variant='h1' size='h11'>Amount</Heading>
            </div>

            {sale?.saleServices?.map((service) => (
              <div className='flex justify-between items-center px-4 py-2' key={service?.id}>
                <Paragraph size='b5'>{service?.name}</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(service?.price)}</Paragraph>
              </div>
            ))}

            {sale?.saleProducts?.map((product) => (
              <div className='flex justify-between items-center px-4 py-2' key={product?.productId}>
                <Paragraph size='b5'>{product?.name} x{product?.quantity}</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(product?.price * product?.quantity)}</Paragraph>
              </div>
            ))}

            <div className='border-t border-grey-50'></div>
            <div className='flex justify-between items-center px-4 py-2'>
              <Paragraph size='b5'>Subtotal</Paragraph>
              <Paragraph size='b5'>{formatInToPrice(sale?.totalPrice)}</Paragraph>
            </div>
            {sale?.addOnAmount ? (
              <div className='flex justify-between px-4 py-2 items-center'>
                <Paragraph size='b4' color={COLORS.GREY[400]} weight="bold">Extra charge: <span className='text-b5 font-semibold'>{sale?.addOnReason}</span></Paragraph>
                <Paragraph size='b4' weight="bold">{formatInToPrice(sale?.addOnAmount)}</Paragraph>
              </div>
            ) : null}
            {salon?.isTaxVisible ? (
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5'>Tax</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(sale?.taxAmount)}</Paragraph>
              </div>
            ) : null}
            {salon?.addOnAmount ? (
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Extra charge</Paragraph>
                <Paragraph size='b5'>+{formatInToPrice(sale?.addOnAmount) || 0}</Paragraph>
              </div>
            ) : null}
            <div className='flex justify-between items-center px-4 py-2'>
              <Paragraph size='b5'>Discount</Paragraph>
              <Paragraph size='b5'>-{sale?.salePromo && sale?.discountAmount > 0 ? formatInToPrice(sale?.salePromo?.amount + sale?.saleDiscount) : !sale?.salePromo && sale?.saleDiscount > 0 ? formatInToPrice(sale?.salePromo?.amount) : formatInToPrice(sale?.discountAmount) || 0}</Paragraph>
            </div>
            <div className='border-t border-grey-50'></div>
            <div className='flex justify-between items-center px-4 py-2'>
              <Paragraph size='b5' weight='semiBold' className='inline'>Total</Paragraph>
              <Paragraph size='b5' weight='semiBold' className='inline'>{formatInToPrice(sale?.totalPaid - sale?.saleDiscount)}</Paragraph>
            </div>
          </div>

          <div className='flex justify-center items-end space-x-2 mt-[20px]'>
            <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <SvgLogo width="50px" height="100%" />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SaleReceiptPrintView