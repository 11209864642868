import * as React from "react";
import { LabelProps } from "./types";

const Base = (props: LabelProps) => {
  return (
    <label
      className={`
        w-full block text-b6 xl:text-b5 font-semiBold text-black dark:text-grey-700 subpixel-antialiased
        ${props.className || ""}
      `}
      htmlFor={props.htmlFor}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </label>
  )
}

export default Base