import React, { useEffect, useState } from 'react'
import { Sale, SaleProduct, SaleService } from '../graphql/generated';
import Heading from '../ui/atoms/heading/Heading';
import { COLORS } from '../constants/colors';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { formatInToPrice } from '../utils/misc';
import SvgLogo from '../ui/icons/SvgLogo';
import { fetchSaleSummaryBySaleId } from '../uicomponents/appointmentComponents/api';

const Receipt = () => {
  const [sale, setSale] = useState<Sale | null>(null)
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/');
  const saleId = parts[parts.length - 1];

  const getSaleSummaryAsync = async () => {
    const res = await fetchSaleSummaryBySaleId({
      id: saleId
    })
    if (res) {
      setSale(res)
    }
  }

  useEffect(() => {
    if (saleId) {
      // Fetch the appointment details from the server using the booking ID
      // and update the appointment state accordingly
      getSaleSummaryAsync()
    }
  }, [saleId])
  return (
    <>
      <main className='w-full flex bg-grey-100 h-screen p-0 xl:py-[49px] xl:px-0 justify-center'>
        {sale ? (
          <div className='w-full max-w-[490px] py-[40px] bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
            <Heading variant='h1' size='h8' weight='bold'>{sale?.salon?.business?.name}</Heading>
            <div className='space-y-2 flex flex-col items-center px-12'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{sale?.salon?.address}</Paragraph>
            </div>
            <div className='w-full border-t border-grey-100'></div>
            <div className='space-y-3 flex flex-col items-center px-12'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>Billed to: {sale?.client?.firstName} {sale?.client?.lastName}</Paragraph>
            </div>
            <div className='space-y-3 flex flex-col items-center px-12'>
              <Heading variant='h2' size='h9' weight='bold'>Thank you for your order.</Heading>
            </div>
            <div className='w-full flex flex-col space-y-2 px-[40px]'>
              <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
              </div>

              {sale?.saleServices?.map((service: SaleService) => (
                <div className='flex justify-between items-center px-4 py-2' key={service?.serviceId}>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(service?.price, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ))}

              {sale?.saleProducts?.map((product: SaleProduct) => (
                <div className='flex justify-between items-center px-4 py-2' key={product?.productId}>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>{product?.name} x{product?.quantity}</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(product?.price * (product?.quantity || 1), sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ))}

              <div className='border-t border-grey-50'></div>
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b4' color={COLORS.GREY[400]}>Subtotal</Paragraph>
                <Paragraph size='b4'>{formatInToPrice(sale?.totalPrice, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
              </div>
              {sale?.addOnAmount ? (
                <div className='flex justify-between px-4 py-2 items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]} weight="bold">Extra charge: <span className='text-b5 font-semibold'>{sale?.addOnReason}</span></Paragraph>
                  <Paragraph size='b4' weight="bold">{formatInToPrice(sale?.addOnAmount)}</Paragraph>
                </div>
              ) : null}
              {sale?.taxAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Tax</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(sale?.taxAmount, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              ) : null}
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b4' color={COLORS.GREY[400]}>Discount</Paragraph>
                <Paragraph size='b4'>-{formatInToPrice(sale?.saleDiscount || 0, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
              </div>
              <div className='border-t border-grey-50'></div>
              {sale?.amountClientPaid > 0 && sale?.amountClientPaid < (sale?.totalPaid - sale?.saleDiscount) ? (
                <>
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b4' color={COLORS.GREY[400]}>Amount Paid</Paragraph>
                    <Paragraph size='b4'>{formatInToPrice(sale?.amountClientPaid, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                  <div className='flex justify-between items-center px-4 py-2 border-t border-grey-50'>
                    <Paragraph size='b4' weight='bold' className='inline' color={COLORS.GREY[300]}>Balance</Paragraph>
                    <Paragraph size='b4' weight='bold' className='inline'>{formatInToPrice(sale?.totalPaid - sale?.amountClientPaid - sale?.saleDiscount, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                </>
              ) : (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' weight='bold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                  <Paragraph size='b4' weight='bold' className='inline'>{formatInToPrice(sale?.totalPaid - sale?.saleDiscount, sale?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
              )}
            </div>

            <div className='flex justify-center items-end space-x-2 mt-[20px]'>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <SvgLogo width="90px" height="100%" />
            </div>
          </div>
        ) : null}
      </main>
    </>
  )
}

export default Receipt