import React, { useEffect, useState } from 'react'
import Heading from '../ui/atoms/heading/Heading'
import { COLORS } from '../constants/colors'
import Paragraph from '../ui/atoms/paragraph/Paragraph'
import Button from '../ui/atoms/button/Button'
import { API_ERRORS, ERRORS } from '../constants/errors'
import { useToast } from '../hooks/useToast'
import { useForm } from 'react-hook-form'
import { PAGE_ROUTES } from '../constants/routes'
import { GeneratePasswordInput } from '../core/types/auth'
import axios from 'axios'
import { ResetPasswordDocument } from '../graphql/generated'
import { print } from 'graphql'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { ToastProps } from '../ui/atoms/toast/types'
import PasswordInput from '../ui/molecules/input/PasswordInput'
import FullStory from 'react-fullstory'
import { REGEX_PATTERNS } from '../constants/pattern'

const GeneratePassword = () => {
  const [resetPasswordIsLoading, setResetPasswordIsLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem('forgotEmailData'))?.token;
  useEffect(() => {
    if (!token) {
      window.location.href = PAGE_ROUTES.LOGIN;
    }
  }, [token]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<GeneratePasswordInput>();
  const { addToast, toast } = useToast();

  const logout = () => {
    localStorage.clear()
    window.location.href = '/'
  }

  const onSubmit = async (input: GeneratePasswordInput) => {
    try {
      setResetPasswordIsLoading(true)
      await axios.post('/graphql', {
        query: print(ResetPasswordDocument),
        variables: {
          input: {
            password: input?.password,
          }
        }
        }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(({ data }) => {
        setResetPasswordIsLoading(false)
        if (data?.data?.resetPassword?.status === 200) {
          // go to login page
          addToast({
            variant: "success",
            message: "Password reset successfully",
          })
          logout();
        } else if (data?.data?.resetPassword?.errors?.length) {
          addToast({
            variant: "error",
            message: data?.data?.resetPassword?.errors[0]?.message || API_ERRORS.RESET_PASSWORD_FAILED,
          });
        } else {
          addToast({
            variant: "error",
            message: API_ERRORS.RESET_PASSWORD_FAILED,
          });
        }
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.RESET_PASSWORD_FAILED;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  };
	// @ts-expect-error Env variables has been set in the application.html.erb
	const ORG_ID = envVariables.fullstoryOrgId

  return (
    <>
      <FullStory org={ORG_ID} />
      <main className="flex items-center justify-center h-screen bg-white xl:bg-grey-100">
        <div className="table-row">
          <div className="table-cell align-middle">
            <ToastWrapper toast={toast as ToastProps} />
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[400px] xl:w-[520px] xl:max-w-[620px]">
              <div className="flex flex-col items-center space-y-9">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>
                  Generate password
                </Heading>
                <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                  Create a secure password for your Splice account
                </Paragraph>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8" autoComplete='off'>
                  <div className="w-full relative block xl:flex">
                    <PasswordInput
                      name='password'
                      label='Password'
                      id='password'
                      type='password'
                      placeholder='**********'
                      control={control}
                      rules={{
                        required: ERRORS.PASSWORD_REQUIRED,
                        pattern: REGEX_PATTERNS.PASSWORD_STRENGTH,
                      }}
                      error={errors.password}
                    />
                    <span className="bg-white xl:shadow-md relative xl:absolute xl:top-6 xl:-right-[35%] transition-all rounded p-0 xl:p-2 text-b6 text-grey-400 scale-100">
                      Password must: <br />
                      Be a minimum of 8 characters <br />
                      Include the following: <br />
                      <ul>
                        <li>A digit(s)</li>
                        <li>A lowercase letter(s)</li>
                        <li>An uppercase letter(s)</li>
                        <li>A symbol(s)</li>
                      </ul>
                    </span>
                  </div>
                  <PasswordInput
                    name='confirmPassword'
                    label='Confirm Password'
                    id='password'
                    type='password'
                    placeholder='**********'
                    control={control}
                    rules={{
                      required: ERRORS.PASSWORD_REQUIRED,
                      minLength: {
                        message: 'Password must be at least 6 characters',
                        value: 6
                      },validate: {
                        matchesPreviousPassword: (value) => {
                          const { password } = getValues();
                          return password === value || ERRORS.PASSWORD_MISMATCH;
                        },
                      },
                    }}
                    error={errors.confirmPassword}
                  />
                  <Button
                    variant='primary'
                    className='uppercase'
                    disabled={resetPasswordIsLoading}
                    loading={resetPasswordIsLoading}
                    size='lg'
                    rounded='lg'
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default GeneratePassword