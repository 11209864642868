import React, { useEffect, useState } from 'react'
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../constants/colors';
import { useModal } from '../hooks/useModal';
import Heading from '../ui/atoms/heading/Heading';
import SvgLogo from '../ui/icons/SvgLogo';
import { cleanUpText, formatInToPrice, getNumberMonthAndYearFromDate, getTimeFromDate } from '../utils/misc';
import { APPOINTMENT_STATUS } from '../uicomponents/bookingSales/constants';
import Button from '../ui/atoms/button/Button';
import PaymentModal from '../modals/PaymentModal';
import PaymentConfirmed from '../uicomponents/PaymentConfirm';
import SvgDate from '../ui/icons/Date';
import { fetchAppointmentSummaryByBookingId } from '../uicomponents/appointmentComponents/api';
import { Appointment, PaystackPaymentAuthorization } from '../graphql/generated';
import BookingPageShimmer from '../uicomponents/bookingSales/BookingPageShimmer';
import FullStory from 'react-fullstory';

const CompletePayment = () => {
  const [appointment, setAppointment] = useState<Appointment>(null)
  const [paymentAuthorization, setPaymentAuthorization] = useState<PaystackPaymentAuthorization>(null)
  const [paymentMadeSuccessfully, setPaymentMadeSuccessfully] = useState(false);
  const [isPaymentPageLoading, setIsPaymentPageLoading] = useState(false)
  const [seconds, setSeconds] = useState(0);

  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const bookingId = parts[parts.length - 1];

  const getAppointmentSummaryAsync = async () => {
    setIsPaymentPageLoading(true);
    const res = await fetchAppointmentSummaryByBookingId({
      bookingId
    })
    setIsPaymentPageLoading(false);
    if (res?.appointment) {
      setAppointment(res?.appointment)
    }
    if (res?.paymentAuthorization) {
      setPaymentAuthorization(res?.paymentAuthorization)
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [seconds]);

  useEffect(() => {
    if (bookingId) {
      // Fetch the appointment details from the server using the booking ID
      // and update the appointment state accordingly
      getAppointmentSummaryAsync()
    }
  }, [bookingId])

  const {
    isVisible: paymentModalIsvisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();

  const confirmPayment = () => {
    closePaymentModal();
    setPaymentMadeSuccessfully(true);
    setSeconds(10);
  }
  // @ts-expect-error Env variables has been set in the application.html.erb
  const ORG_ID = envVariables.fullstoryOrgId
  return (
    <>
      <FullStory org={ORG_ID} />
      <main className='w-full h-screen flex flex-col space-y-12 p-4 xl:p-12'>
        <div className='flex justify-center items-end space-x-2 mt-[20px]'>
          <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <SvgLogo width="90px" height="100%" />
        </div>
        {isPaymentPageLoading && (
          <div className="w-full flex flex-col xl:flex-row border border-grey-20 rounded-lg p-4 xl:p-10 max-w-[1000px] mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]">
            <BookingPageShimmer />
          </div>
        )}

        {appointment && !isPaymentPageLoading ? (
          <div className="w-full flex flex-col xl:flex-row border border-grey-20 rounded-lg p-4 xl:p-10 max-w-[1000px] mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]">
            <div className="w-full flex flex-col space-y-6 max-w-[360px]">
              <div className="flex flex-col space-y-4">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.GREY['900']}>Make a deposit</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Hello {appointment?.client?.firstName}, {appointment?.appointmentStatus === APPOINTMENT_STATUS.deposit_pending ? " please make deposit to guarantee your reservation." : " your reservation is confirmed. You can pay now or at the store. Enjoy your experience!"} </Paragraph>
              </div>
              <div className='flex space-x-4'>
                <SvgDate width="24px" height="24px" />
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{getNumberMonthAndYearFromDate(appointment?.startAt, appointment?.salon?.business?.country?.timezone)} ・ {getTimeFromDate(appointment?.startAt)}</Paragraph>
              </div>
              <div className='flex flex-col p-4 space-y-2 bg-grey-25 rounded-md'>
                {appointment?.appointmentStatus === APPOINTMENT_STATUS.deposit_pending ? (
                  <>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>Deposit required</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>{formatInToPrice(appointment?.depositAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Total amount</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{formatInToPrice(appointment?.totalPrice, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>Outstanding balance</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>{formatInToPrice(appointment?.totalPaid - appointment?.totalAmountPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Total amount</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{formatInToPrice(appointment?.totalPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Deposit Paid</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>-{formatInToPrice(appointment?.depositAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  </>
                )}
              </div>
              {!paymentMadeSuccessfully && appointment?.appointmentStatus === APPOINTMENT_STATUS.deposit_pending && paymentAuthorization ? (
                <Button
                  onClick={openPaymentModal}
                  variant='secondary' rounded='none' size='sm' fontSize='b5' fontWeight='semiBold'
                >Pay</Button>
              ) : null}
            </div>

            <div className='w-full max-w-[490px] bg-white py-[28px] px-[32px] border border-grey-20 h-full flex flex-col items-start space-y-5 overflow-y-auto'>
              <Heading variant='h2' size='h10' weight='bold'>Order summary</Heading>
              <div className='w-full flex justify-between'>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Booking ID: #{appointment?.bookingId}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Date: {getNumberMonthAndYearFromDate(appointment?.startAt, appointment?.salon?.business?.country?.timezone) || "-"}</Paragraph>
                </div>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Billed to: {appointment?.client?.firstName} {appointment?.client?.lastName}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]} className='capitalize'>Status: {cleanUpText(appointment?.appointmentStatus)}</Paragraph>
                </div>
              </div>
              <div className='w-full flex flex-col space-y-2'>
                <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                  <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                  <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
                </div>

                {appointment?.appointmentServices?.map((service) => (
                  <div className='flex justify-between items-center px-4 py-2' key={service?.serviceId}>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                    <Paragraph size='b5'>{formatInToPrice(service?.price, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ))}

                {appointment?.appointmentProducts?.map((product) => (
                  <div className='flex justify-between items-center px-4 py-2' key={product?.productId}>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>{product?.name}</Paragraph>
                    <Paragraph size='b5'>{formatInToPrice(product?.price, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ))}

                <div className='border-t border-grey-50'></div>
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Subtotal</Paragraph>
                  <Paragraph size='b5'>{formatInToPrice(appointment?.totalServicesAmount as number + appointment?.totalProductsAmount as number, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Tax</Paragraph>
                  <Paragraph size='b5'>{formatInToPrice(appointment?.taxAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Discount</Paragraph>
                  <Paragraph size='b5'>-{formatInToPrice(appointment?.appointmentPromo?.amount, appointment?.salon?.business?.country?.currency?.symbol) || 0}</Paragraph>
                </div>
                <div className='border-t border-grey-50'></div>
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' weight='semiBold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                  <Paragraph size='b5' weight='semiBold' className='inline'>{formatInToPrice(appointment?.totalPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
                {appointment?.appointmentStatus !== APPOINTMENT_STATUS.deposit_pending ? (
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' className='inline' color={COLORS.GREY[300]}>Deposit Paid</Paragraph>
                    <Paragraph size='b5' className='inline' color={COLORS.GREY[900]}>{formatInToPrice(appointment?.depositAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ) : null}
                {appointment?.appointmentStatus !== APPOINTMENT_STATUS.deposit_pending ? (
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' weight='semiBold' className='inline' color={COLORS.GREY[300]}>Outstanding Balance</Paragraph>
                    <Paragraph size='b5' weight='semiBold' className='inline'>{formatInToPrice(appointment?.totalPrice - appointment?.totalAmountPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <PaymentModal
          isVisible={paymentModalIsvisible}
          closeModal={closePaymentModal}
          confirmPayment={confirmPayment}
          paymentAuthorization={paymentAuthorization || null}
          salonId={appointment?.salon?.id || null}
          propertyId={appointment?.id}
          propertyType='appointment'
        ></PaymentModal>
        {paymentMadeSuccessfully && seconds > 0 ? (
          <PaymentConfirmed seconds={seconds} />
        ) : null}
      </main>
    </>
  )
}

export default CompletePayment