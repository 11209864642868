import React, { createContext, useContext, useState } from 'react'
import {
  Client,
  ClientCommunicationLogsDocument,
  CommunicationLogConnection,
  CreateClientDocument,
  CreateClientInput,
  CreateClientNoteAttachmentDocument,
  CreateClientNoteAttachmentInput,
  CreateClientNoteDocument,
  CreateClientNoteInput,
  DeleteClientDocument,
  DeleteClientInput,
  DeleteClientNoteAttachmentDocument,
  DeleteClientNoteDocument,
  PageInfo,
  RecordRefundDocument,
  RecordRefundInput,
  RecordSaleRefundDocument,
  RecordSaleRefundInput,
  SalonClientDocument,
  SalonClientsDocument,
  UploadClientDocument,
  UploadClientInput
} from '../graphql/generated'
import { useToast } from '../hooks'
import axios from 'axios'
import { print } from 'graphql'
import { useSalonCache } from '../hooks/useSalonCache'
import { formatDateToOriginalDate, sortClientData } from '../utils/misc'

const ClientContext = createContext(undefined)
type SalonClientConnection = {
  nodes: Client[]
  pageInfo: PageInfo
}
const ClientProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const { toast, addToast } = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const [clients, setClients] = useState<SalonClientConnection>({
    nodes: [],
    pageInfo: null
  })
  const [communicationLogs, setCommunicationLogs] =
    useState<CommunicationLogConnection>()
  const [client, setClient] = useState<Client>()
  const { getSalonFieldValue } = useSalonCache()

  const getSalonClients = (q?: string, after?: string, fetchNew?: boolean) => {
    setIsLoading(true)
    axios
      .post(
        '/graphql',
        {
          query: print(SalonClientsDocument),
          variables: { salonId: getSalonFieldValue('id'), q, first: 50, after }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { salonClients }
          }
        } = res
        setIsLoading(false)
        // update clients, merge with new salonClients?.nodes with existing data
        if ((q || fetchNew) && !after) {
          setClients({
            nodes: sortClientData(salonClients?.nodes),
            pageInfo: salonClients?.pageInfo
          })
        } else {
          if (
            Array?.isArray(salonClients?.nodes) &&
            salonClients?.nodes.length > 0
          ) {
            const existingNodes = clients?.nodes ?? [];
            const newNodes = salonClients?.nodes ?? [];
            const combinedNodes = [...existingNodes, ...newNodes] as unknown as Client[];
            setClients({
              nodes: sortClientData(combinedNodes),
              pageInfo: salonClients?.pageInfo
            })
          }
        }
      })
      .catch((err) => {
        setIsLoading(false)
        addToast({ variant: 'error', message: err.message })
      })
  }

  const getSalonClient = (clientId: string) => {
    axios
      .post(
        '/graphql',
        { query: print(SalonClientDocument), variables: { clientId } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { salonClient }
          }
        } = res
        setClient(salonClient)
      })
      .catch((err) => addToast({ variant: 'error', message: err.message }))
  }

  const createClient = async (
    input: CreateClientInput,
    closeModal: () => void,
    reset: () => void
  ) => {
    try {
      const {
        data: {
          data: { createClient: createClient_ }
        }
      } = await axios.post(
        '/graphql',
        {
          query: print(CreateClientDocument),
          variables: { input: { ...input, salonId: getSalonFieldValue('id') } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (createClient_.status === 201) {
        closeModal()
        addToast({ variant: 'success', message: 'Client saved successfully' })
        await getSalonClients('', '', true)
        await getSalonClient(createClient_.client.id)
        reset()
      } else {
        addToast({
          message: createClient_.errors[0].message,
          variant: 'error'
        })
      }
    } catch (error) {
      addToast({ variant: 'error', message: error.message }) // Close modal on error
    }
  }

  const deleteClient = (input: DeleteClientInput) => {
    axios
      .post(
        '/graphql',
        {
          query: print(DeleteClientDocument),
          variables: { input }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { deleteClient: deleteClient_ }
          }
        } = res

        if (deleteClient_.status === 200) {
          getSalonClients('', '', true)
          addToast({
            message: 'Client deleted successfully',
            variant: 'success'
          })
        }
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const createClientNote = (input: CreateClientNoteInput) => {
    axios
      .post(
        '/graphql',
        { query: print(CreateClientNoteDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { createClientNote: createClientNote_ }
          }
        } = res

        if (createClientNote_.status === 200) {
          getSalonClient(input.clientId)
          addToast({
            message: 'Client note saved successfully',
            variant: 'success'
          })
        } else {
          addToast({
            message: createClientNote_.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const uploadClient = async (input: UploadClientInput) => {
    try {
      const {
        data: {
          data: { uploadClient: uc }
        }
      } = await axios.post(
        '/graphql',
        { query: print(UploadClientDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (uc.status === 200) {
        await getSalonClients()
        addToast({ message: 'Clients saved successfully', variant: 'success' })
      } else {
        addToast({ message: uc?.errors[0]?.message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error?.message, variant: 'error' })
    }
  }

  const deleteClientNote = async (id: string) => {
    try {
      const {
        data: {
          data: { deleteClientNote: dcn }
        }
      } = await axios.post(
        '/graphql',
        {
          query: print(DeleteClientNoteDocument),
          variables: { input: { id } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dcn.status === 200) {
        getSalonClient(client.id)
        addToast({
          message: 'Client note deleted successfully',
          variant: 'success'
        })
      } else {
        addToast({ message: dcn.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const deleteClientNoteAttachment = async (imageUrl: string) => {
    try {
      const {
        data: {
          data: { deleteClientNoteAttachment: dcn }
        }
      } = await axios.post(
        '/graphql',
        {
          query: print(DeleteClientNoteAttachmentDocument),
          variables: { input: { imageUrl } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dcn.status === 200) {
        getSalonClient(client.id)
        addToast({
          message: 'Client note attachment deleted successfully',
          variant: 'success'
        })
      } else {
        addToast({ message: dcn.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const recordRefund = async (
    input: RecordRefundInput,
    handleClose: () => void
  ) => {
    try {
      const {
        data: {
          data: { recordRefund: rf }
        }
      } = await axios.post(
        '/graphql',
        { query: print(RecordRefundDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (rf.status === 200) {
        await getSalonClients()
        await getSalonClient(client.id)
        addToast({
          message: 'Refund recorded successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: rf.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const recordSaleRefund = async (
    input: RecordSaleRefundInput,
    handleClose: () => void
  ) => {
    try {
      const {
        data: {
          data: { recordSaleRefund: rfr }
        }
      } = await axios.post(
        '/graphql',
        { query: print(RecordSaleRefundDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (rfr.status === 200) {
        await getSalonClients()
        await getSalonClient(client.id)
        addToast({
          message: 'Sale refund recorded successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: rfr.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const createClientNoteAttachment = (
    input: CreateClientNoteAttachmentInput
  ) => {
    axios
      .post(
        '/graphql',
        {
          query: print(CreateClientNoteAttachmentDocument),
          variables: { input }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { createClientNoteAttachment: ccna }
          }
        } = res

        if (ccna.status === 200) {
          getSalonClient(client.id)
          addToast({
            message: 'Client note saved successfully',
            variant: 'success'
          })
        } else {
          addToast({
            message: ccna.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const getCommunicationLogHistory = async (
    startDate,
    endDate,
    before,
    after,
    q
  ) => {
    try {
      const { data } = await axios.post(
        '/graphql',
        {
          query: print(ClientCommunicationLogsDocument),
          variables: { salonId: getSalonFieldValue('id'), clientId: client.id, startDate: formatDateToOriginalDate(startDate, 'start'), endDate: formatDateToOriginalDate(endDate, 'end'), q, ...(before && before !== '' ? { before } : {}), ...(after && after !== '' ? { after } : {}) }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setCommunicationLogs(data.data.clientCommunicationLogs)
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <ClientContext.Provider
      value={{
        clients,
        setClients,
        client,
        toast,
        addToast,
        getSalonClients,
        createClient,
        deleteClient,
        createClientNote,
        getSalonClient,
        uploadClient,
        deleteClientNote,
        recordRefund,
        recordSaleRefund,
        createClientNoteAttachment,
        deleteClientNoteAttachment,
        getCommunicationLogHistory,
        communicationLogs,
        isLoading
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

// Client Context custom hook
export const useClientContext = () => {
  const context = useContext(ClientContext)
  if (!context) {
    throw new Error('useClientContext must be used with a ClientProvider')
  }
  return context
}

export default ClientProvider
