import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useToast } from '../../../hooks/useToast';
import { LocationInput, LocationSetupEnum, PageProps } from '../types';
import { API_ERRORS, ERRORS } from '../../../constants/errors';
import { formatNumber, sanitizePhoneNumber } from '../../../utils/misc';
import { DEFAULT_CURRENCY } from '../../../constants/currency';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from '../../../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../../../ui/atoms/toast/types';
import {
  IMAGE_UPLOAD_PATTERN,
  REGEX_PATTERNS,
} from '../../../constants/pattern';
import { getImageUploadContent } from '../../../utils/upload';
import { FormHelperText } from '../../../ui/atoms/helperText/FormHelperText';
import Paragraph from '../../../ui/atoms/paragraph/Paragraph';
import Input from '../../../ui/molecules/input/Input';
import SelectInput from '../../../ui/molecules/input/SelectInput';
import { FormLabel } from '../../../ui/atoms/formLabel';
import Checkbox from '../../../ui/atoms/checkbox/Checkbox';
import { PROCESSING_FEE } from '../../../constants/information';
import { DEPOSIT_DURATION_INTERVAL } from '../../../constants/form';
import FormPhone from '../../../ui/molecules/input/FormPhone';
import { getHelperTextForReactHookFormErrors } from '../../../utils/form';
import Button from '../../../ui/atoms/button/Button';
import { PRICE_TYPE_REQUIREMENTS, YES_OR_NO_SETTINGS } from '../constants';
import { useSalonCache } from '../../../hooks/useSalonCache';
import { COLORS } from '../../../constants/colors';
import { useImageUploader } from '../../../hooks/useUploader';
import {
  Business,
  CreateSalonDocument,
  GooglePlacesSearchDocument,
  UpdateSalonDocument,
  UpdateSalonInput,
  UserDocument,
} from '../../../graphql/generated';
import { print } from 'graphql';
import { useUserCache } from '../../../hooks/useUserCache';
import { MultiSelectOption } from '../../../ui';
import SingleSelect from '../../../ui/molecules/singleSelect/SingleSelect';

const SalonLocation = ({ onNextPage }: PageProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    register,
    watch,
    formState: { errors },
  } = useForm<LocationInput>({
    defaultValues: {
      locationSetupSalon: true,
      taxInclusivePricing: true,
      isTaxVisible: true,
      bearsProcessingFee: false,
      isDepositRequired: false,
    },
  });
  const { addToast, toast } = useToast();
  const { setSalonData, getSalonData } = useSalonCache();
  const { getBusinessData, setBusinessData } = useUserCache();
  const salon = getSalonData();
  const { imageUrl, errorMessage, handleImageUpload, uploadIsLoading } =
    useImageUploader({
      width: 3000,
      height: 3000,
    });
  const businessData: Business = getBusinessData();
  const [isLoading, setIsLoading] = React.useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [callingCode, setCallingCode] = useState(null);
  const [typeSign, setTypeSign] = useState<string | null>('-');
  const [locationOptions, setLocationOptions] = useState<MultiSelectOption[]>()
  const [loadingLocationsShimmer, setLoadingLocationShimmer] = useState(false)
  const [locationSearchTerm, setLocationSearchTerm] = useState('')


  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    setCountryCode(userData?.business?.countryCode);
    setCallingCode(userData?.business?.callingCode);
  }, []);


  useEffect(() => {
    if (errorMessage) {
      addToast({
        message: errorMessage,
        variant: 'error',
      });
    }
  }, [errorMessage, uploadIsLoading]);

  useEffect(() => {
    if (imageUrl) {
      setValue('logoUrl', imageUrl);
      clearErrors('logoUrl');
    }
  }, [clearErrors, imageUrl, setValue]);

  const getUser = async () => {
    axios
      .post(
        '/graphql',
        { query: print(UserDocument) },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then((res) => {
        const user = res?.data?.data?.user;
        localStorage.setItem('userData', JSON.stringify(user));
        localStorage.setItem(
          'role',
          JSON.stringify(user?.salonStaff?.staffRole)
        );
        setBusinessData(user?.business);
        setIsLoading(false);
        onNextPage();
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    if (salon) {
      // setCountryCode(salon?.countryCode);
      // setCallingCode(salon?.callingCode);
      setValue('branchName', salon.branchName);
      // @ts-expect-error error expected
      setValue('address', { label: salon?.address, value: salon.address })
      setValue('logoUrl', businessData?.logoUrl);
      setValue('email', salon.email);
      setValue('phone', salon.phone);
      if (salon.locationType === 'salon') {
        setValue('locationSetupSalon', true);
      }
      if (salon.locationType === 'home') {
        setValue('locationSetupHome', true);
      }

      if (salon.locationType === 'flexible') {
        setValue('locationSetupSalon', true);
        setValue('locationSetupHome', true);
      }
      setValue('depositValue', salon?.depositValue || '');
      setValue('depositType', salon?.depositType || '');
      setValue('depositLinkValidityHours', salon?.depositLinkValidityHours);
      setValue('isDepositRequired', salon?.isDepositRequired || false);
      setValue('isTaxVisible', salon?.isTaxVisible);
    }
  }, []);

  useEffect(() => {
    if (businessData) {
      setValue('logoUrl', businessData?.logoUrl);
    }
  }, [businessData?.logoUrl]);

  const onSubmitData = async (input: LocationInput) => {
    setIsLoading(true);
    const locationTypes: LocationSetupEnum[] = [];
    if (input?.locationSetupSalon) {
      locationTypes.push(LocationSetupEnum.SALON);
    }
    if (input?.locationSetupHome) {
      locationTypes.push(LocationSetupEnum.HOME);
    }
    // if there is no address kindly flag an error
    // @ts-expect-error error expected
    if (!input?.address?.value) {
      addToast({
        message: API_ERRORS.ADDRESS_REQUIRED,
        variant: 'error',
      });
      setIsLoading(false);
      return;
    }
    try {
      const salonData = {
        branchName: input?.branchName,
        locationType:
          locationTypes?.length === 2 ? 'flexible' : locationTypes?.join(','),
        // @ts-expect-error error expected
        address: input?.address?.value,
        city: '',
        state: '',
        taxInclusivePricing: input?.taxInclusivePricing,
        isTaxVisible: input?.isTaxVisible || false,
        bearsProcessingFee: input?.bearsProcessingFee,
        callingCode,
        phone: input?.phone,
        email: input?.email,
        depositType: input?.depositType ? input.depositType : null,
        depositValue: input?.depositValue
          ? Number(input?.depositValue?.toString()?.replace(/,/g, ''))
          : 0,
        isDepositRequired: input?.isDepositRequired,
        logoUrl: input?.logoUrl,
        status: 'open',
        intervalMinutes: salon?.intervalMinutes || 30,
      } as unknown as UpdateSalonInput;

      if (input?.depositLinkValidityHours !== undefined) {
        salonData.depositLinkValidityHours = Number(
          input?.depositLinkValidityHours
        );
      }

      if (!salon) {
        await axios
          .post(
            '/graphql',
            {
              query: print(CreateSalonDocument),
              variables: { input: { ...salonData } },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          )
          .then(({ data }) => {
            if (data?.data?.createSalon?.status === 201) {
              setSalonData(data?.data?.createSalon?.salon);
              getUser();
            } else {
              const message =
                data?.data?.createSalon?.errors[0]?.message ||
                API_ERRORS.CREATE_SALON_FAILED;
              addToast({
                message,
                variant: 'error',
              });
              setIsLoading(false);
            }
          });
      } else {
        await axios
          .post(
            '/graphql',
            {
              query: print(UpdateSalonDocument),
              variables: { input: { ...salonData, id: salon?.id } },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          )
          .then(({ data }) => {
            if (data?.data?.updateSalon?.status === 200) {
              setIsLoading(false);
              setSalonData(data?.data?.updateSalon?.salon);
              getUser();
            } else {
              setIsLoading(false);
              const message =
                data?.data?.updateSalon?.errors[0]?.message ||
                API_ERRORS.CREATE_SALON_FAILED;
              addToast({
                message,
                variant: 'error',
              });
            }
          });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      const message =
        error?.response?.data?.message || API_ERRORS.CREATE_SALON_FAILED;
      addToast({
        message,
        variant: 'error',
      });
    }
  };

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber);
  };

  useEffect(() => {
    if (watch('depositType')) {
      if (watch('depositType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY);
      } else if (watch('depositType') === 'percentage') {
        setTypeSign('%');
      }
    } else {
      setTypeSign('-');
    }
  }, [watch('depositType')]);


  const searchLocation = (e) => {
    setLoadingLocationShimmer(true)
    setLocationSearchTerm(e)
  }

  const getLocationAysnc = (searchTerm) => {
    axios
      .post(
        '/graphql',
        {
          query: print(GooglePlacesSearchDocument),
          variables: { q: searchTerm }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => {
        const predictions = res.data.data.googlePlacesSearch
        setLocationOptions(predictions)
        // console.log(predictions)
        // setLocationOptions(
        //   predictions.map((x) => ({
        //     label: x.description,
        //     value: x.description,
        //     disabled: false
        //   }))
        // )
        setLoadingLocationShimmer(false)
      })
      .catch((err) => {
        setLoadingLocationShimmer(false)
        console.error(err)
      })
  }

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (locationSearchTerm) {
        getLocationAysnc(locationSearchTerm)
      }
    }, 3000)

    return () => clearTimeout(delaySearch)
  }, [locationSearchTerm])
  return (
    <AccountSetupTemplate
      title={`Welcome, ${businessData?.name || ''}`}
      subtitle="Enter your address and set where you want to serve your clients"
    >
      <ToastWrapper toast={toast as ToastProps} />
      <form
        onSubmit={handleSubmit(onSubmitData)}
        className="w-full space-y-6 pt-6"
      >
        <div className="w-full flex border rounded-sm border-grey-20 border-dashed h-[130px] justify-center items-center">
          <Controller
            control={control}
            name="logoUrl"
            render={({ field: { value }, formState: { errors } }) => {
              const logoImageUploadUrl = imageUrl || value;
              return (
                <div className="flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="relative cursor-pointer">
                      {getImageUploadContent({
                        imageUrl: logoImageUploadUrl,
                        uploadIsLoading,
                        placeHolder: 'Upload Business Logo',
                      })}
                      <input
                        className="cursor-pointer absolute block opacity-0 top-0 w-full h-[96px]"
                        type="file"
                        accept={IMAGE_UPLOAD_PATTERN}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  {errors?.logoUrl?.message && (
                    <FormHelperText variant="error">
                      {errors.logoUrl.message}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
          />
        </div>
        <Paragraph size="b7" color={COLORS.GREY[300]} className="text-center">
          Kindly upload a logo with dimensions not exceeding 250px by 150px
        </Paragraph>
        <Input
          name="branchName"
          id="branch-name"
          label="Branch name"
          type="text"
          placeholder="Enter branch name here"
          control={control}
          rules={{
            pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
            required: ERRORS.BUSINESS_BRANCH_NAME_REQUIRED,
          }}
          error={errors.branchName}
        />
        {/* <Input
          name="address"
          id="business-address"
          label="Business Address"
          type="text"
          placeholder="Enter business address here"
          control={control}
          rules={{
            required: watch('locationSetupSalon')
              ? ERRORS.ADDRESS_REQUIRED
              : false,
          }}
          error={errors.address}
        /> */}

        <div className='w-full flex flex-col space-y-2'>
          <Controller
            control={control}
            name='address'
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const errorMessage = errors?.address?.message
              return (
                <>
                  <FormLabel htmlFor='client'>Business Address</FormLabel>
                  <SingleSelect
                    // @ts-expect-error exprect error
                    selected={value || []}
                    options={locationOptions}
                    setSelected={onChange}
                    isLoading={loadingLocationsShimmer}
                    onInputChange={searchLocation}
                    placeholder='Enter address'
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              )
            }}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <FormLabel htmlFor="locationSetupSalon">
            Where do you serve clients?
          </FormLabel>
          <div className="flex space-x-4">
            <Controller
              control={control}
              name="locationSetupSalon"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex" onClick={() => onChange(!value)}>
                    <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                      <Checkbox isChecked={value} />
                      <span className="text-grey-300 whitespace-nowrap">
                        Business Address
                      </span>
                    </div>
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="locationSetupHome"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex" onClick={() => onChange(!value)}>
                    <div className="flex items-center cursor-pointer text-b5 text-grey-300 space-x-2">
                      <Checkbox isChecked={value} />
                      <span className="text-grey-300 whitespace-nowrap">
                        Home Service
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="taxInclusivePricing"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="taxInclusivePricing">
                  Are your display prices inclusive of VAT?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (taxSetting: { label: string; value: boolean }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(taxSetting?.value)}
                            key={taxSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === taxSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {taxSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="isTaxVisible"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="isTaxVisible">
                  Do you want to display tax in receipt?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (taxSetting: { label: string; value: boolean }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(taxSetting?.value)}
                            key={taxSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === taxSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {taxSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="bearsProcessingFee"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="locationSetupSalon">
                  Do you want to bear the {PROCESSING_FEE}% online payment
                  processing fee?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (feeSetting: { label: string; value: boolean }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(feeSetting?.value)}
                            key={feeSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === feeSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {feeSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="isDepositRequired"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor="isDepositRequired">
                  Is payment required for booking?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (isDepositRequired: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(isDepositRequired?.value)}
                            key={isDepositRequired?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === isDepositRequired?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {isDepositRequired?.label}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            );
          }}
        />
        {watch('isDepositRequired') && (
          <>
            <div className="w-full flex items-center gap-x-4">
              <SelectInput
                name="depositType"
                id="depositValue-type"
                label="How much deposit is required?"
                control={control}
                rules={{
                  required: ERRORS.DEPOSIT_TYPE_REQUIRED,
                }}
                value={watch('depositType')}
                error={errors.depositType}
                options={PRICE_TYPE_REQUIREMENTS}
                placeholder="Select Requirement"
              />
              <Controller
                control={control}
                name="depositValue"
                rules={{ required: 'How much deposit is required?' }}
                render={({ field, formState: { errors } }) => (
                  <div className="w-full flex flex-col mt-7">
                    <div className="flex w-full">
                      <input
                        className="w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder="Enter percentage"
                      />
                      <div className="px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900">
                        {typeSign}
                      </div>
                    </div>
                    {errors?.depositValue?.message && (
                      <FormHelperText variant="error">
                        {errors.depositValue.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>

            <SelectInput
              name="depositLinkValidityHours"
              id="depositLinkValidityHours"
              label="Select Payment Link Duration"
              control={control}
              rules={{
                required: ERRORS.DEPOSIT_INTERVAL_REQUIRED,
              }}
              value={watch('depositLinkValidityHours')}
              error={errors.depositLinkValidityHours}
              options={DEPOSIT_DURATION_INTERVAL}
              placeholder="Select Duration"
            />
          </>
        )}
        <div>
          <Paragraph
            size="b4"
            color={COLORS.GREY[300]}
            weight="bold"
            className="pb-3"
          >
            Contact information
          </Paragraph>
          <Input
            name="email"
            label="Contact Email"
            id="email"
            type="email"
            placeholder="email@acme.com"
            control={control}
            rules={{
              pattern: REGEX_PATTERNS.EMAIL,
              required: ERRORS.EMAIL_REQUIRED,
            }}
            error={errors.email}
          />
        </div>
        <FormPhone
          country={{
            disabled: true,
            onSelect: (code, country: string) => {
              setCallingCode(code);
              setCountryCode(country);
            },
            value: countryCode,
          }}
          phone={{
            name: 'phone',
            type: 'text',
            placeholder: '9151930463',
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: 'phone-number',
            label: 'Contact Phone Number',
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />
        <Button
          variant="primary"
          className=""
          size="lg"
          rounded="lg"
          disabled={isLoading}
          loading={isLoading}
        >
          Proceed
        </Button>
      </form>
    </AccountSetupTemplate>
  );
};

export default SalonLocation;
