/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../constants/colors';
import Heading from '../ui/atoms/heading/Heading';
import SvgUsers from '../ui/icons/Users';
import SvgCalendar from '../ui/icons/Calendar';
import { formatInToPrice, getNumberMonthAndYearFromDate, getTimeFromDate } from '../utils/misc';
import SvgCreditCard from '../ui/icons/CreditCard';
import { APPOINTMENT_STATUS } from '../uicomponents/bookingSales/constants';
import Disclaimer from '../uicomponents/bookingSales/Disclaimer';
import SvgLocationPin from '../ui/icons/LocationPin';
import SvgLogo from '../ui/icons/SvgLogo';
import { Appointment } from '../graphql/generated';
import { fetchAppointmentSummaryByBookingId } from '../uicomponents/appointmentComponents/api';
import FullStory from 'react-fullstory';
import { Anchor } from '../ui';

const AppointmentSummary = () => {
  const [appointment, setAppointment] = useState<Appointment>(null)
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL using '/' as the delimiter and get the last part
  const parts = currentUrl.split('/');
  const bookingId = parts[parts.length - 1];

  const getAppointmentSummaryAsync = async () => {
    const res = await fetchAppointmentSummaryByBookingId({
      bookingId
    })
    if (res?.appointment) {
      setAppointment(res?.appointment)
    }
  }

  useEffect(() => {
    if (bookingId) {
      // Fetch the appointment details from the server using the booking ID
      // and update the appointment state accordingly
      getAppointmentSummaryAsync()
    }
  }, [bookingId])

  const contactEmailAndPhoneText = () => {
    return (
      <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{`If you have any questions, kindly reach out via: Email: ${appointment?.salon?.email}, Phone: ${appointment?.salon?.callingCode + '' + appointment?.salon?.phone}`}</Paragraph>
    )
  }
  // @ts-expect-error Env variables has been set in the application.html.erb
  const ORG_ID = envVariables.fullstoryOrgId

  return (
    <>
      <FullStory org={ORG_ID} />
      <main className='w-full h-screen flex flex-col space-y-6 xl:space-y-12'>
        <div>
          <div className='flex items-end border-b border-grey-20 space-x-2 py-6 px-[20px] xl:px-[80px]'>
            <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <SvgLogo width="60px" height="100%" />
          </div>
          <div className='flex items-center border-b border-grey-20 space-x-2 py-6 px-[20px] xl:px-[80px]'>
            <Heading variant='h1' size='h8' weight='bold' color={COLORS.GREY['900']}>Appointment summary</Heading>
          </div>
        </div>

        <div className='w-full px-[20px] xl:px-[80px]'>
          {appointment ? (
            <div className="w-full flex flex-col xl:flex-row xl:justify-between border border-grey-20 rounded-lg p-4 xl:p-10 mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]">
              <div className="w-full flex flex-col space-y-6">
                <div className="flex flex-row justify-between space-y-4">
                  <div className="flex flex-col">
                    {appointment?.salon?.business?.logoUrl ? (
                      <img
                        src={appointment?.salon?.business?.logoUrl}
                        alt="business logo"
                        className="w-full max-w-[70px]"
                        loading="lazy"
                      />
                    ) : null}
                    <Heading variant='h1' size='h9' weight='semiBold' color={COLORS.GREY['900']}>{appointment?.salon?.business?.name}</Heading>
                  </div>
                  {appointment?.salon?.business?.headerImageUrl ? (
                    <div className='w-full max-w-[80px]'>
                      <img
                        src={appointment?.salon?.business?.headerImageUrl}
                        alt="business header image"
                        className="w-full object-cover rounded"
                        loading="lazy"
                      />
                    </div>
                  ) : null}
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 border-t border-grey-50 py-4'>
                  <div className='flex space-x-2'>
                    <div className="w-[28px] h-[28px] p-[6px] flex justify-center items-center text-grey-900 bg-grey-50 rounded">
                      <SvgUsers width="16px" height="16px" />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['200']}>Who</Paragraph>
                      <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{appointment?.client?.firstName} {appointment?.client?.lastName}</Paragraph>
                    </div>
                  </div>
                  <div className='flex space-x-2'>
                    <div className="w-[28px] h-[28px] p-[6px] flex justify-center items-center text-grey-900 bg-grey-50 rounded">
                      <SvgCalendar width="16px" height="16px" />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['200']}>When</Paragraph>
                      <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{getNumberMonthAndYearFromDate(appointment?.startAt, appointment?.salon?.business?.country?.timezone)} ・ {getTimeFromDate(appointment?.startAt)}</Paragraph>
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 border-b border-grey-50 py-4'>
                  <div className='flex space-x-2'>
                    <div className="w-[28px] h-[28px] p-[6px] flex justify-center items-center text-grey-900 bg-grey-50 rounded">
                      <SvgLocationPin width="16px" height="16px" />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['200']}>Where</Paragraph>
                      <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>
                        {appointment?.locationType === 'home' ? (
                          <Anchor to={`https://maps.google.com/?q=${appointment?.address}`} target='_blank'>
                            {`${appointment?.address}`}
                          </Anchor>
                        ) : (
                          <Anchor to={`https://maps.google.com/?q=${appointment?.salon?.address}, ${appointment?.salon?.city}, ${appointment?.salon?.state}`} target='_blank'>
                            {`${appointment?.salon?.address}, ${appointment?.salon?.city}, ${appointment?.salon?.state}`}
                          </Anchor>
                        )}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex space-x-2'>
                    <div className="w-[28px] h-[28px] p-[6px] flex justify-center items-center text-grey-900 bg-grey-50 rounded">
                      <SvgCreditCard width="16px" height="16px" />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['200']}>Amount</Paragraph>
                      <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{formatInToPrice(appointment?.totalPaid)}</Paragraph>
                    </div>
                  </div>
                </div>

                <Disclaimer title="Cancellation Policy" type='orange' message={appointment?.salon?.business?.cancellationPolicy} />
                <div className='hidden xl:flex flex-col mt-[10px] space-y-[20px]'>
                  {contactEmailAndPhoneText()}
                </div>
              </div>

              <div className='w-full xl:max-w-[490px] bg-white xl:py-[28px] p-[10px] xl:px-[32px] border border-grey-20 h-full flex flex-col items-start space-y-5 overflow-y-auto'>
                <Heading variant='h2' size='h10' weight='bold'>Details</Heading>
                <div className='w-full flex flex-col space-y-2'>
                  <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                    <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                    <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
                  </div>

                  {appointment?.appointmentServices?.map((service) => (
                    <div className='flex justify-between items-center px-4 py-2' key={service?.id}>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                      <Paragraph size='b5'>{formatInToPrice(service?.price)}</Paragraph>
                    </div>
                  ))}

                  {appointment?.appointmentProducts?.map((product) => (
                    <div className='flex justify-between items-center px-4 py-2' key={product?.id}>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{product?.name}</Paragraph>
                      <Paragraph size='b5'>{formatInToPrice(product?.price)}</Paragraph>
                    </div>
                  ))}

                  <div className='border-t border-grey-50'></div>
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Subtotal</Paragraph>
                    <Paragraph size='b5'>{formatInToPrice(appointment?.totalPrice)}</Paragraph>
                  </div>
                  {appointment?.taxAmount ? (
                    <div className='flex justify-between items-center px-4 py-2'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Tax</Paragraph>
                      <Paragraph size='b5'>{formatInToPrice(appointment?.taxAmount)}</Paragraph>
                    </div>
                  ) : null}
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Discount</Paragraph>
                    <Paragraph size='b5'>-{formatInToPrice(appointment?.appointmentPromo?.amount) || 0}</Paragraph>
                  </div>
                  <div className='border-t border-grey-50'></div>
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' weight='semiBold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                    <Paragraph size='b5' weight='semiBold' className='inline'>{formatInToPrice(appointment?.totalPaid)}</Paragraph>
                  </div>
                  {appointment?.appointmentStatus !== APPOINTMENT_STATUS.deposit_pending ? (
                    <div className='flex justify-between items-center px-4 py-2'>
                      <Paragraph size='b5' className='inline' color={COLORS.GREY[300]}>Deposit Paid</Paragraph>
                      <Paragraph size='b5' className='inline' color={COLORS.GREY[900]}>{formatInToPrice(appointment?.depositAmount)}</Paragraph>
                    </div>
                  ) : null}
                  {appointment?.appointmentStatus !== APPOINTMENT_STATUS.deposit_pending ? (
                    <div className='flex justify-between items-center px-4 py-2'>
                      <Paragraph size='b5' weight='semiBold' className='inline' color={COLORS.GREY[300]}>Outstanding Balance</Paragraph>
                      <Paragraph size='b5' weight='semiBold' className='inline'>{formatInToPrice(appointment?.totalPrice - appointment?.depositAmount)}</Paragraph>
                    </div>
                  ) : null}
                </div>
              </div>


              <div className='flex xl:hidden flex-col mt-[10px] space-y-[20px]'>
                {contactEmailAndPhoneText()}
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </>
  )
}

export default AppointmentSummary
