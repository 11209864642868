import axios from "axios";
import {
  Appointment,
  AppointmentsDocument,
  PackagesDocument,
  PosDevicesDocument,
  Product,
  ProductsDocument,
  SalesDocument,
  SalonClientsDocument,
  SalonProjectedSalesDocument,
  StaffManagementDocument,
  Service,
  ServicesDocument,
  TopSellersDocument,
  TransferAccountsDocument
} from '../graphql/generated'
import { print } from "graphql";
import { MultiSelectOption } from "../ui/molecules/multiselect/types";
import { AppointmentServiceInputProps, BookingPackages, UpdateAppointmentServiceInputProps } from "./types";
import { AppointmentPackageServiceInputProps, OrganizedAppointment } from "../uicomponents/appointmentComponents/types";
import { convert12HourTo24Hour } from "../utils/misc";

export const fetchClientsData = async (variables, limit?: number) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(SalonClientsDocument), variables: { ...variables, first: limit || 50 } },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { salonClients }
      }
    } = response;

    return salonClients?.nodes;
  } catch (error) {
    console.log(error);
  }
};

export const fetchServicesData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(ServicesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { services }
      }
    } = response;

    return services;
  } catch (error) {
    console.log(error);
  }
};

export const fetchProductsData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(ProductsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { products }
      }
    } = response;

    return products;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTransferAccountsData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(TransferAccountsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { transferAccounts }
      }
    } = response;

    return transferAccounts;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPosDevicesData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(PosDevicesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { posDevices }
      }
    } = response;

    return posDevices;
  } catch (error) {
    console.log(error);
  }
};

export const fetchStaffListData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      {
        query: print(StaffManagementDocument),
        variables: { ...variables, active: true }
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    )

    const {
      data: {
        data: { staffManagement }
      }
    } = response

    return staffManagement
  } catch (error) {
    console.log(error);
  }
};

export const fetchPackagesData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(PackagesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { packages }
      }
    } = response;

    return packages;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUnassignedAppointments = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { appointments }
      }
    } = response;

    return appointments;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSales = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(SalesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { sales }
      }
    } = response;

    return sales;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTodayProjectedSale = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(SalonProjectedSalesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { salonProjectedSales }
      }
    } = response;

    return salonProjectedSales;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTopSellers = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(TopSellersDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { topSellers }
      }
    } = response;

    return topSellers;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAppointments = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { appointments }
      }
    } = response;

    return appointments;
  } catch (error) {
    console.log(error);
  }
};

export const removeFromOptions = (items: [], id?: string) => {
  if (!Array.isArray(items)) return;

  const index = items.findIndex((item: MultiSelectOption) => item.value === id);
  if (index !== -1) {
    items.splice(index, 1);
  }

  return items;
}

export const removeServiceFromSelectedServices = (services: UpdateAppointmentServiceInputProps[], serviceId?: string) => {
  if (!Array.isArray(services)) return;

  const index = services.findIndex((service: UpdateAppointmentServiceInputProps) => service.id === serviceId);
  if (index !== -1) {
    services.splice(index, 1);
  }

  return services;
}

export const removeProductFromSelectedServices = (products: Product[], productId?: string) => {
  if (!Array.isArray(products)) return;

  const index = products.findIndex((product: Product) => product.id === productId);
  if (index !== -1) {
    products.splice(index, 1);
  }

  return products;
}

export const convertPackagesToCommaSeparatedString = (packages: BookingPackages) => {
  // return an array of each package and their quantity e.g [{ packageId: string, quantity: number }]
  return packages?.map?.((packageItem) => {
    return { packageId: packageItem.id, quantity: packageItem.quantity }
  })
}

export const checkIfDepositIsRequiredInServices = (services: Service[]): boolean => {
  if (!Array.isArray(services)) return false;
  if (services?.length === 0) return false;
  return services?.some((service) => service?.isDepositRequired);
}

export const getAppointmentPackagesServiceInputData = (services: AppointmentPackageServiceInputProps[]): {
  serviceId: string;
  staffIds: string[] | null;
  replacementServiceId: string | null;
}[] => {
  return services?.map((service) => ({
    serviceId: service?.serviceId ?? '',
    staffIds: service?.staff?.map((staff) => staff?.staffId) ?? null,
    replacementServiceId: service?.replacementService?.id ?? null,
    quantity: service?.cartQuantity
  })) || [];
}

export const getAppointmentServiceInputData = (services: AppointmentServiceInputProps[]): {
  serviceId: string;
  staffIds: string[];
}[] => {
  return services?.map((service) => ({
    serviceId: service?.id ?? '',
    staffIds: service?.staff?.map((staff) => staff?.staffId) ?? null,
    quantity: service?.quantity || 1,
    price: service?.servicePrice
  })) || [];
}

export const convertServicesToAppointment = (services: Service[]): AppointmentServiceInputProps[] => {
  return services?.map((service) => ({
    ...service,
    staff: null,
    startAt: null,
    quantity: 1,
    servicePrice: service?.price,
  }));
}

export const organizeUnassignedAppointmentsByDate = (data: Appointment[]): OrganizedAppointment[] => {
  const organizedAppointments: OrganizedAppointment[] = [];

  data.forEach((appointment) => {
    const appointmentDate = appointment.startAt.split("T")[0];
    // Check if the date already exists in the organizedAppointments array
    const existingDateIndex = organizedAppointments.findIndex((item) => item.dateString === appointmentDate);

    if (existingDateIndex !== -1) {
      // Date already exists, push the appointment to the existing date
      organizedAppointments[existingDateIndex].appointments.push(appointment);
    } else {
      // Date doesn't exist, create a new entry
      organizedAppointments.push({
        dateString: appointmentDate,
        appointments: [appointment],
      });
    }
  });

  return organizedAppointments;
};

export const getUpdateAppointmentServiceInputData = (services: UpdateAppointmentServiceInputProps[], date: string, countryCode?: string): {
  serviceId: string;
  staffIds: string[] | null;
  startAt: string | null;
  quantity: number;
  price?: number
}[] => {
  return services?.map((service) => ({
    serviceId: service?.id ?? '',
    staffIds: service?.staff?.map((staff) => staff?.staffId) ?? null,
    startAt: service.startAt && getServiceStartAt(date, service.startAt, countryCode),
    quantity: service?.quantity || 1,
    price: service?.servicePrice
  })) || [];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getServiceStartAt = (date: string, time: string, countryCode?: string) => {
  const startAt = `${date}T${convert12HourTo24Hour(time)}`;

  return startAt;
}