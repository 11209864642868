import React from 'react'
import { Button, FormPhone, Heading, Input, Logo, Paragraph, ToastProps, ToastWrapper } from '../ui'
import { COLORS } from '../constants/colors'
import SelectWithChangeHandlerInput from '../ui/molecules/input/SelectWithChangeHandlerInput';
import { ERRORS } from '../constants/errors';
import { REGEX_PATTERNS } from '../constants/pattern';
import { AllCountriesDocument, JoinWaitlistDocument, JoinWaitlistInput } from '../graphql/generated';
import { useForm } from 'react-hook-form';
import { getHelperTextForReactHookFormErrors } from '../utils/form';
import { sanitizePhoneNumber } from '../utils/misc';
import axios from 'axios';
import { print } from 'graphql';
import { useModal, useToast } from '../hooks';
import { SvgCheckMarkGreen, SvgClose } from '../ui/icons';
import { Turnstile } from '@marsidev/react-turnstile';
import FullStory from 'react-fullstory';
import { TermAndConditionsModal } from './Register';
import { BOOKING_MANAGEMENT, BUSINESS_CATEGORIES } from '../constants/information';
// import FrontChat from '../ui/atoms/frontChat/FrontChat';

const ApplyDemo = () => {
  const [countries, setCountries] = React.useState([]);
  const [isHuman, setIsHuman] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false);
  const { addToast, toast } = useToast();
  const { openModal, closeModal, isVisible } = useModal();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<JoinWaitlistInput>({
    defaultValues: {
      countryCode: 'NG',
    },
    delayError: 100,
    mode: 'onChange',
  });
  const [countryCode, setCountryCode] = React.useState('NG');
  const [callingCode, setCallingCode] = React.useState('234');

  const submitWaitlistAsync = async (input: JoinWaitlistInput) => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { joinWaitlist },
        },
      } = await axios.post('/graphql', {
        query: print(JoinWaitlistDocument),
        variables: {
          input: {
            ...input,
              email: input?.email?.toLowerCase(),
          },
        },
      });

      if (joinWaitlist.status === 200) {
        setShowSuccessMessage(true)
      } else {
        addToast({ variant: 'error', message: joinWaitlist.errors[0].message });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      addToast({ variant: 'error', message: error.message });
    }
  }

  React.useEffect(() => {
    axios
      .post('/graphql', {
        query: print(AllCountriesDocument),
      })
      .then((response) => {
        const countries = response.data.data.allCountries;
        setCountries(countries);
      });
  }, []);

  const closePage = () => {
    window.location.href = 'https://withsplice.com';
  }

  const handleCountryChange = (selectedOption) => {
    const countryCode = selectedOption.target.value;

    const selectedCountry = countries.find(
      (country) => country.code === countryCode
    );
    setCountryCode(selectedCountry?.code);
    setValue('countryCode', selectedCountry?.code);
    setCallingCode(selectedCountry?.countryCode);
  };

  const handleBusinessCategoryChange = (selectedOption) => {
    const businessCategory = selectedOption.target.value;
    setValue('category', businessCategory);
  };

  const handleBookingManagementChange = (selectedOption) => {
    const bookingManagement = selectedOption.target.value;
    setValue('bookingManagement', bookingManagement);
  };

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber);
  }
  // @ts-expect-error Env variables has been set in the application.html.erb
  const ORG_ID = envVariables.fullstoryOrgId;
  // @ts-expect-error Env variables has been set in the application.html.erb
  const turnstileKey = envVariables.turnstileKey;
  return (
    <>
      <FullStory org={ORG_ID} />
      {/* <FrontChat /> */}
      <ToastWrapper toast={toast as ToastProps} />
      <div className="w-full h-screen flex justify-center items-center bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1725533012/temhcvgkohtfzdvk9m4h.png')] bg-cover bg-center bg-no-repeat">
        <div className="w-full h-screen flex items-center justify-center bg-black/30 backdrop-blur-[2px]">
          {!showSuccessMessage ?
            <section className="w-full h-[90%] relative overflow-y-auto bg-white max-w-[500px] flex flex-col items-center rounded-lg shadow-md px-4 py-8 space-y-6">
              <Logo />
              <div className='w-full max-w-[70%] flex flex-col justify-center'>
                <Heading variant='h1' size='h8' weight='semiBold' className='gradient-text text-center'>
                Get Started
                </Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className='text-center'>
                Enter your details to get started.
                </Paragraph>
              </div>


              <div className='absolute top-1 right-2'>
                <Button
                  variant="text"
                  size="none"
                  type="button"
                  className="flex justify-center items-center border border-grey-50 rounded-full p-1 w-fit text-grey-300"
                  fontSize="b4"
                  onClick={closePage}
                >
                  <SvgClose width="24px" height="24px" />
                </Button>
              </div>

              <form
                onSubmit={handleSubmit(submitWaitlistAsync)}
                className="w-full flex flex-col space-y-3"
                autoComplete="off"
              >
                <Input
                  name="firstName"
                  label="First name"
                  id="first-name"
                  type="text"
                  placeholder="Enter first name here"
                  control={control}
                  rules={{
                    required: ERRORS.FIRST_NAME_REQUIRED,
                    pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
                  }}
                  error={errors.firstName}
                />

                <Input
                  name="lastName"
                  label="Last name"
                  id="last-name"
                  type="text"
                  placeholder="Enter last name here"
                  control={control}
                  rules={{
                    required: ERRORS.LAST_NAME_REQUIRED,
                    pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
                  }}
                  error={errors.lastName}
                />
                <Input
                  name="email"
                  label="Email"
                  id="email"
                  type="email"
                  placeholder="email@acme.com"
                  control={control}
                  rules={{
                    required: ERRORS.EMAIL_REQUIRED,
                    pattern: REGEX_PATTERNS.EMAIL,
                  }}
                  error={errors?.email}
                />
                <Input
                  name="businessName"
                  id="business-name"
                  label="Business name"
                  type="text"
                  placeholder="Enter business name here"
                  control={control}
                  rules={{
                    required: ERRORS.BUSINESS_NAME_REQUIRED,
                  }}
                  error={errors.businessName}
                />
                <SelectWithChangeHandlerInput
                  name="category"
                  id="category"
                  label="What type of business do you run?"
                  control={control}
                  rules={{
                    required: ERRORS.CATEGORY_REQUIRED,
                  }}
                  error={errors.category}
                  options={BUSINESS_CATEGORIES}
                  placeholder="Select a category"
                  onChange={handleBusinessCategoryChange}
                />
                <SelectWithChangeHandlerInput
                  name="countryCode"
                  id="country"
                  label="Country"
                  control={control}
                  rules={{
                    required: ERRORS.COUNTRY_REQUIRED,
                  }}
                  error={errors?.countryCode}
                  options={countries.map((country) => ({
                    label: country.name,
                    value: country.code,
                  }))}
                  placeholder="Select country"
                  onChange={handleCountryChange}
                  value={countryCode}
                />
                <FormPhone
                  country={{
                    disabled: true,
                    onSelect: (code, country: string) => {
                      setCallingCode(code);
                      setCountryCode(country);
                    },
                    value: countryCode,
                  }}
                  phone={{
                    name: 'phone',
                    type: 'text',
                    placeholder: '9151930463',
                    rules: {
                      required: ERRORS.PHONE_REQUIRED,
                      pattern: REGEX_PATTERNS.NUMBER,
                      onChange: (e) => {
                        _sanitizePhoneNumber(e.target.value);
                      },
                      disabled: false,
                    },
                    register,
                    id: 'phone-number',
                    label: 'Phone Number',
                  }}
                  helperText={getHelperTextForReactHookFormErrors(
                    errors?.phone?.message as string
                  )}
                />
                <SelectWithChangeHandlerInput
                  name="bookingManagement"
                  id="manageBooking"
                  label="How do you manage your business"
                  control={control}
                  rules={{
                    required: ERRORS.BOOKING_MANAGEMENT_REQUIRED,
                  }}
                  error={errors?.bookingManagement}
                  options={BOOKING_MANAGEMENT}
                  placeholder="Select..."
                  onChange={handleBookingManagementChange}
                />
                <div className="w-full flex justify-center space-x-2">
                  <Turnstile
                    siteKey={turnstileKey}
                    onSuccess={() => setIsHuman(true)}
                    onError={() => setIsHuman(false)}
                  />
                </div>
                <Button
                  variant="primary"
                  className=""
                  disabled={isLoading || !isHuman}
                  loading={isLoading}
                  size="lg"
                  rounded="lg"
                >
                  Get started
                </Button>
              </form>

              <div className='w-full max-w-[70%] flex flex-col justify-center'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className='text-center'>
                  By submitting, you agree to the
                  <span className="font-bold text-green-300"
                    onClick={openModal}
                    role="button">
                    {" "}
                    Terms of Service{" "}
                    <span className="text-black font-normal"
                    onClick={openModal}
                    role="button"> and</span> Privacy
                    Policy{" "}
                  </span>
                  including receiving emails from Splice. You can opt-out at any
                  time.
                </Paragraph>
              </div>
            </section> :
            <div className='w-full max-w-[300px] bg-white flex flex-col justify-center items-center space-y-2 py-10'>
              <SvgCheckMarkGreen width='48px' height='48px' />
              <Heading variant='h1' size='h8' weight='semiBold' className='gradient-text text-center'>
                Success
              </Heading>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className='text-center'>
                We will get back to you shortly
              </Paragraph>
              <Button
                variant="primary"
                className=""
                size="lg"
                rounded="lg"
                onClick={closePage}
              >
                Go back to homepage
              </Button>
            </div>}
        </div>
      </div>

      <TermAndConditionsModal
        isVisible={isVisible}
        closeModal={closeModal}
      />
    </>
  )
}

export default ApplyDemo
