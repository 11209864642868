import React from 'react';

const ToggleOnly = ({ isChecked }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <div
          className={`block w-10 h-6 rounded-full ${isChecked ? 'bg-green-300' : 'bg-grey-100'
            }`}
        ></div>
        <div
          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${isChecked ? 'transform translate-x-4' : ''
            }`}
        ></div>
      </div>
    </label>
  );
};

export default ToggleOnly;