import React from 'react'
import Button from '../ui/atoms/button/Button';
import SvgArrowBack from '../ui/icons/ArrowBack';
import SvgChevronLeft from '../ui/icons/ChevronLeft';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import Heading from '../ui/atoms/heading/Heading';
import { formatInToPrice, getNumberMonthAndYearFromDate } from '../utils/misc';
import { Modal } from '../ui/templates/modal/Modal';
import { ClientRewardModalProps } from './types';

const MilestoneReportDetails = ({
  reward,
  isOpen,
  closeModal,
}: ClientRewardModalProps) => {
  const rewardMap = reward ? [{
    title: 'Name',
    value: reward?.name
  }, {
    title: 'Services/Products',
    value: reward?.services?.map(service => service?.name).join(', ') + reward?.products?.map(product => product?.name).join(', ')
  }, {
    title: 'Valid for',
    value: ''
  }, {
    title: 'Date reached',
    value: getNumberMonthAndYearFromDate(reward?.createdAt) || "-",
  }, {
    title: 'Date redeemed',
    value: '-'
  }, {
    title: 'Points earned',
    value: reward?.customerPointsAttained + 'pts'
  }, {
    title: 'Reward value',
    value: reward?.customerReceivesType === 'percentage' ? `${reward?.customerReceives}%` : formatInToPrice(reward?.customerReceives)
  }] : [];
  return (
    <Modal
      show={isOpen}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Reward Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Reward details</Heading>
              <Paragraph size="b4">Find information about reward here</Paragraph>
            </div>
          </div>
          {reward && rewardMap?.length ? (
            <div className='w-full flex flex-col space-y-2 border border-grey-50 p-4'>
              {Array?.isArray(rewardMap) && rewardMap?.length ? rewardMap?.map((dataReward, index) => {
                return (
                  <div className='w-full p-3 flex justify-between items-center space-x-4 bg-grey-10' key={index}>
                    <Paragraph size="b4" color="grey-300" className="text-left">
                      {dataReward?.title}
                    </Paragraph>

                    <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                      {dataReward?.value}
                    </Paragraph>
                  </div>
                )
              }) : null}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default MilestoneReportDetails