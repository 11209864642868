import React, { createContext, useContext, useState } from 'react'
import {
  CategoriesDocument,
  Category,
  CreateCategoryDocument,
  CreateCategoryInput,
  CreateProductDocument,
  CreateProductInput,
  DeleteProductDocument,
  Product,
  ProductConnection,
  ProductDocument,
  ProductsDocument,
  StockFlowReportConnection,
  StockFlowReportDocument,
  StockMovementLogReportConnection,
  StockMovementLogReportDocument,
  StockOnHandReportDocument,
  UpdateProductStockDocument,
  UpdateProductStockInput,
  UploadProductDocument,
  UploadProductInput
} from '../graphql/generated'
import axios from 'axios'
import { useToast } from '../hooks'
import { print } from 'graphql'
import { useSalonCache } from '../hooks/useSalonCache'
import { formatDateToOriginalDate } from '../utils/misc'

const ProductContext = createContext(undefined)

const ProductProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [products, setProducts] = useState<Product[]>([])
  const [product, setProduct] = useState<Product>()
  const [stockFlows, setStockFlows] = useState<StockFlowReportConnection>()
  const [stockMovementLogs, setStockMovementLogs] = useState<StockMovementLogReportConnection>()
  const [stockOnHand, setStockOnHand] = useState<ProductConnection>()
  const [category, setCategory] = useState<Category | null>(null)
  const [categories, setCategories] = useState<Category[] | null>(null)
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache()


  const sortProducts = (products: Product[]) => {
    const data = [...products].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return data
  }
  
  const getCategories = async () => {
    axios
      .post(
        '/graphql',
        {
          query: print(CategoriesDocument),
          variables: { salonId: getSalonFieldValue('id') }
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { categories: categories_ }
          }
        } = res
        setCategories(categories_)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const getProducts = async (q?: string) => {
    axios
      .post(
        '/graphql',
        {
          query: print(ProductsDocument),
          variables: { salonId: getSalonFieldValue('id'), q }
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { products }
          }
        } = res
        setProducts(sortProducts(products))
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const getStockFlows = async (startDate, endDate, q, before, after) => {
    try {
      const salonId = getSalonFieldValue('id')
      const { data } = await axios.post(
        '/graphql',
        {
          query: print(StockFlowReportDocument),
          variables: { salonId, startDate: formatDateToOriginalDate(startDate, 'start'), endDate: formatDateToOriginalDate(endDate, 'end'), q, ...(before && before !== '' ? { before } : {}), ...(after && after !== '' ? { after } : {}) }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setStockFlows(data.data.stockFlowReport)
    } catch (error) {
      addToast({ message: 'An error occured, Kindly try again.', variant: 'error' })
    }
  }

  const getStockMovementLog = async (startDate, endDate, q, before, after) => {
    const salonId = getSalonFieldValue('id')
    const { data } = await axios.post(
      '/graphql',
      {
        query: print(StockMovementLogReportDocument),
        variables: { salonId, startDate: formatDateToOriginalDate(startDate, 'start'), endDate: formatDateToOriginalDate(endDate, 'end'), q, ...(before && before !== '' ? { before } : {}), ...(after && after !== '' ? { after } : {}) }
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    setStockMovementLogs(data.data.stockMovementLogReport)
  }

  const getStockOnHand = async (q, before, after) => {
    const salonId = getSalonFieldValue('id')
    const { data } = await axios.post(
      '/graphql',
      {
        query: print(StockOnHandReportDocument),
        variables: { salonId, q, ...(before && before !== '' ? { before } : {}), ...(after && after !== '' ? { after } : {})  }
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )

    setStockOnHand(data.data.stockOnHandReport)
  }

  const getProduct = async (id) => {
    axios
      .post(
        '/graphql',
        {
          query: print(ProductDocument),
          variables: { id: id }
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { product: p }
          }
        } = res
        setProduct(p)
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const deleteProduct = async (id: string) => {
    axios
      .post(
        '/graphql',
        { query: print(DeleteProductDocument), variables: { input: { id } } },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { deleteProduct: deleteProduct_ }
          }
        } = res

        if (deleteProduct_.status === 200) {
          addToast({ message: 'Product deleted', variant: 'success' })
          getProducts()
        } else {
          addToast({
            message: deleteProduct_.errors[0].message,
            variant: 'error'
          })
        }
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  const createProduct = async (
    input: CreateProductInput,
    closeModal: () => void,
    reset: () => void
  ) => {
    try {
      const {
        data: {
          data: { createProduct: createProduct_ }
        }
      } = await axios.post(
        '/graphql',
        {
          query: print(CreateProductDocument),
          variables: { input: { ...input, salonId: getSalonFieldValue('id') } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (createProduct_.status === 200) {
        getProducts()
        addToast({
          message: 'Product saved successfully',
          variant: 'success'
        })
        closeModal()
        reset()
      } else {
        addToast({
          message: createProduct_.errors[0].message,
          variant: 'error'
        })
      }
    } catch (err) {
      addToast({ message: err.message, variant: 'error' })
    }
  }

  const createProductCategory = async (input: CreateCategoryInput) => {
    try {
      const {
        data: {
          data: { createCategory: createCategory_ }
        }
      } = await axios.post(
        '/graphql',
        {
          query: print(CreateCategoryDocument),
          variables: { input: { ...input } }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (createCategory_.status === 200) {
        setCategory(createCategory_?.category)
        return createCategory_?.category
      } else {
        addToast({
          message: createCategory_.errors[0].message,
          variant: 'error'
        })
      }
    } catch (err) {
      addToast({ message: err.message, variant: 'error' })
    }
  }

  const uploadProduct = async (input: UploadProductInput) => {
    try {
      const {
        data: {
          data: { uploadProduct: up }
        }
      } = await axios.post(
        '/graphql',
        { query: print(UploadProductDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (up.status === 200) {
        await getProducts()
        addToast({ message: 'Product upload successful', variant: 'success' })
      } else {
        addToast({ message: up.errors[0].message, variant: 'error' })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  const updateProductStock = async (
    input: UpdateProductStockInput,
    closeModal: () => void,
    reset: () => void
  ) => {
    try {
      const {
        data: {
          data: { updateProductStock: ups }
        }
      } = await axios.post(
        '/graphql',
        { query: print(UpdateProductStockDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (ups.status === 200) {
        addToast({
          variant: 'success',
          message: 'Stock count updated successfully'
        })
        closeModal()
        reset()
        await getProducts()
        await getProduct(product.id)
      } else {
        addToast({ variant: 'error', message: ups.errors[0].message })
      }
    } catch (error) {
      addToast({ message: error.message, variant: 'error' })
    }
  }

  return (
    <ProductContext.Provider
      value={{
        products,
        toast,
        product,
        getProducts,
        deleteProduct,
        createProduct,
        addToast,
        uploadProduct,
        updateProductStock,
        getProduct,
        createProductCategory,
        getCategories,
        getStockFlows,
        getStockOnHand,
        getStockMovementLog,
        category,
        categories,
        stockFlows,
        stockOnHand,
        stockMovementLogs
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

// Product Context custom hook
export const useProductContext = () => {
  const context = useContext(ProductContext)
  if (!context) {
    throw new Error('useProductContext must be used with a ProductProvider')
  }

  return context
}

export default ProductProvider
