import React, { useEffect, useState } from 'react'
import Button from '../ui/atoms/button/Button';
import SvgArrowBack from '../ui/icons/ArrowBack';
import SvgChevronLeft from '../ui/icons/ChevronLeft';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import Heading from '../ui/atoms/heading/Heading';
import { formatInToPrice, formatSnakeCaseText, getNumberMonthAndYearFromDate } from '../utils/misc';
import { Modal } from '../ui/templates/modal/Modal';
import { useSalonCache } from '../hooks/useSalonCache';
import { ClientPackageModalProps } from './types';
import { Pill, Table } from '../ui';
import axios from 'axios';
import { print } from 'graphql'
import { ClientVoucherRedeemedHistoryDocument, PackagesSalesHistory, VoucherService } from '../graphql/generated';

const ClientPackageDetails = ({ voucher, ...props }: ClientPackageModalProps) => {
  const [voucherHistory, setVoucherHistory] = useState<PackagesSalesHistory[]>([])
  const { getSalonData } = useSalonCache();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const salon = getSalonData();
  const closeModal = () => {
    props.closeModal()
  }

  const getLocation = () => {
    const salonId = voucher?.salonId;
    const salons = voucher?.business?.salons;

    const salon = Array?.isArray(salons) && salons.find(salon => salon?.id === salonId);

    if (salon) {
      return salon?.branchName;
    }

    return "-"
  }

  const getVoucherPillsForServices = (voucherServices: VoucherService[]) => {
    if (Array.isArray(voucherServices) && voucherServices.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {voucherServices.map(voucherService => {
          return <Pill variant='light' rounded='lg' className='py-2 px-2' key={voucherService?.id}>{voucherService?.name}
            {voucherService?.quantity}</Pill>
        })}
      </div>)
    }

    return "-"
  }

  const getVoucherPillsForRemainingServices = (voucherServices: VoucherService[]) => {
    if (Array.isArray(voucherServices) && voucherServices.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {voucherServices?.map(voucherService => {
          return <Pill variant='light' rounded='lg' className='py-2 px-2' key={voucherService?.id}>{voucherService?.name} {voucherService?.quantity - voucherService?.redeemedCount || 0}/{voucherService?.quantity}</Pill>
        })}
      </div>)
    }

    return "-"
  }

  const nameMap = {
    "packageName": "Voucher Name",
    "servicesIncluded": "Services",
    "voucherCode": "Voucher Code",
    "price": "Total Price",
    "totalPrice": "Total Price",
    "totalServices": "Total Services",
    "voucherBalance": "Voucher Balance",
    "servicesRemaining": "Services Remaining",
    "location": "Location",
    "dateOfPurchase": "Date of Purchase",
    "status": "Status"
  };

  const voucherMap = {
    "packageName": voucher?.package?.name,
    "servicesIncluded": getVoucherPillsForServices(voucher?.voucherServices),
    "servicesRemaining": getVoucherPillsForRemainingServices(voucher?.voucherServices),
    "voucherCode": voucher?.code,
    "price": formatInToPrice(voucher?.price),
    "totalServices": voucher?.voucherServices?.length,
    "voucherBalance": voucher?.redemptionMode !== 'services' ? formatInToPrice(voucher?.price - voucher?.amountRedeemed) : "-",
    "location": getLocation(),
    "dateOfPurchase": getNumberMonthAndYearFromDate(voucher?.createdAt) || "-",
    "status": <Pill variant={ voucher?.status === 'active' ? 'success' : voucher?.status === 'redeemed' ? 'primary' : voucher?.status === 'expired' ? 'light' : 'light' }>{formatSnakeCaseText(voucher?.status || "")}</Pill>
  };

  const fetchClientVoucherHistory = async () => {
    try {
      const res = await axios.post(
        '/graphql',
        {
          query: print(ClientVoucherRedeemedHistoryDocument),
          variables: {
            voucherId: voucher?.id
          }
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      )
      console.log({
        res
      })
      setVoucherHistory(res?.data?.data?.clientVoucherRedeemedHistory)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (props?.isOpen && voucher) {
      fetchClientVoucherHistory()
    }
  }, [props?.isOpen])

  const getVoucherRedeemedData = () => {
    return voucherHistory?.map((voucher) => {
      return {
        serviceName: voucher?.serviceName,
        staffsAssigned: voucher?.staffsAssigned,
        dateOfUse: getNumberMonthAndYearFromDate(voucher?.dateOfUse),
        location: voucher?.location
      }
    })
  }
  return (
    <Modal
      show={props.isOpen}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Voucher Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Voucher details</Heading>
              <Paragraph size="b4">Find information about package here</Paragraph>
            </div>
          </div>
          {voucher ? (
            <div className='w-full flex flex-col space-y-4'>
              {/* <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
                <div className="grid grid-col-1 md:grid-cols-4 gap-4">
                  <div className="flex justify-start items-start space-x-2">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Name</Paragraph>
                      <Paragraph size='b4'>{props?.voucher.package?.name}</Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Services</Paragraph>
                      <Paragraph size='b4'>{convertVoucherServicesNamesToString(props?.voucher?.voucherServices)}</Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Total price</Paragraph>
                      <Paragraph size='b4'>{formatInToPrice(props?.voucher.price)}</Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Status</Paragraph>
                      <Paragraph size='b4' className='capitalize'>{props?.voucher?.status}</Paragraph>
                    </div>
                  </div>
                </div>
                <div className="grid grid-col-1 md:grid-cols-4 gap-4 pt-4 border-t border-grey-50">
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Valid for</Paragraph>
                      <Paragraph size='b4'>{props?.voucher?.validityMonths} Month(s)</Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Purchase date</Paragraph>
                      <Paragraph size='b4'>{getDayMonthAndNumberAndTimeFromDate(convertDateTimeStringToTimeZone(props?.voucher?.createdAt, salon?.countryCode) || "-")}</Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Expiry date</Paragraph>
                      <Paragraph size='b4'>{getDayMonthAndNumberAndTimeFromDate(convertDateTimeStringToTimeZone(props?.voucher?.expiresAt, salon?.countryCode) || "-")}</Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>Value Redeemed</Paragraph>
                      <Paragraph size='b4'>{formatInToPrice(props?.voucher?.amountRedeemed)}</Paragraph>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
                {Object.entries(voucherMap).map(([key, value]) => (
                  
                  nameMap[key] && value !== null && (
                    <div key={key} className="px-2 flex justify-between items-center space-x-4 bg-grey-10">
                      <div className="flex flex-col space-y-1 p-2">
                        <Paragraph size="b4" color="grey-300" className="text-left">
                          {nameMap[key]}
                        </Paragraph>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                          {value}
                        </Paragraph>
                      </div>
                    </div>
                  )
                ))}
              </div>
              <Heading variant='h1' size='h11' weight='semiBold'>Redeemed history</Heading>
              <div>
                <Table
                  headers={[
                    { label: 'Service Name', value: 'serviceName' },
                    {
                      label: 'Staff Assigned',
                      value: 'staffsAssigned'
                    },
                    { label: 'Date of Use', value: 'dateOfUse' },
                    { label: 'Location', value: 'location' }
                  ]}
                  rows={getVoucherRedeemedData()}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default ClientPackageDetails