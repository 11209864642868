import React, { useEffect, useState } from 'react'
import Button from '../ui/atoms/button/Button';
import SvgArrowBack from '../ui/icons/ArrowBack';
import SvgChevronLeft from '../ui/icons/ChevronLeft';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import Heading from '../ui/atoms/heading/Heading';
import { formatInToPrice, getNumberMonthAndYearFromDate } from '../utils/misc';
import { Modal } from '../ui/templates/modal/Modal';
import { LoyaltyRedeemedRewardModalProps } from './types';
import axios from 'axios';
import { Loyalty, LoyaltyProgramDocument, Milestone, MilestoneDocument } from '../graphql/generated';
import { checkReportsTokenFromRoute } from '../utils/token';
import { print } from 'graphql'

const LoyaltyRedeemedDetails = ({
  reward,
  isOpen,
  closeModal,
}: LoyaltyRedeemedRewardModalProps) => {
  const [milestone, setMilestone] = useState<Milestone>(null);
  const [loyalty, setLoyalty] = useState<Loyalty>(null);
  const token = checkReportsTokenFromRoute()

  const getMilestoneApi = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(MilestoneDocument),
          variables: {
            id: reward?.milestoneId
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { milestone }
          }
        } = res
        setMilestone(milestone)
        getLoyaltyApi()
      })
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })

      })
  }

  const getLoyaltyApi = () => {
    axios
      .post(
        '/graphql',
        {
          query: print(LoyaltyProgramDocument),
          variables: {
            id: milestone?.loyaltyProgramId
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const {
          data: {
            data: { loyalty }
          }
        } = res
        setLoyalty(loyalty)
      })
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })

      })
  }

  useEffect(() => {
    if (reward?.milestoneId) {
      getMilestoneApi()
    }
  }, [reward?.milestoneId])

  const rewardMap = reward ? [{
    title: 'Name',
    value: reward?.milestoneName
  }, {
    title: 'Services/Products',
    value: reward?.services
  }, {
    title: 'Client',
    value: reward?.client
  }, {
    title: 'Date Reached',
    value: getNumberMonthAndYearFromDate(reward?.dateReached) || "-"
  }, {
    title: 'Date redeemed',
    value: getNumberMonthAndYearFromDate(reward?.dateRedeemed) || "-"
  }, {
    title: 'Location',
    value: reward?.location
  }, {
    title: 'Staff',
    value: reward?.staff
  }, {
    title: 'Reward value',
    value: reward?.promoType === 'fixed_value' ? formatInToPrice(reward?.promoValue) : `${reward?.promoValue}%`
  }] : [];
  return (
    <Modal
      show={isOpen}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Reward Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Milestone details</Heading>
              <Paragraph size="b4">Find information about reward here</Paragraph>
            </div>
          </div>
          {reward && rewardMap?.length ? (
            <div className='w-full flex flex-col space-y-2 border border-grey-50 p-4'>
              {Array?.isArray(rewardMap) && rewardMap?.length ? rewardMap?.map((dataReward, index) => {
                return (
                  <div className='w-full p-3 flex justify-between items-center space-x-4 bg-grey-10' key={index}>
                    <Paragraph size="b4" color="grey-300" className="text-left">
                      {dataReward?.title}
                    </Paragraph>

                    <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                      {dataReward?.value}
                    </Paragraph>
                  </div>
                )
              }) : null}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default LoyaltyRedeemedDetails