import axios from "axios";
import { print } from "graphql"
import { AppointmentDocument, AppointmentsDocument, AppointmentServicesDocument, AppointmentSummaryDocument, ClosedPeriodsDocument, SaleDocument, StaffBlockedTimesDocument, StaffTimeOffsDocument } from "../../graphql/generated";

export const fetchClosedPeriodsData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(ClosedPeriodsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    
    const {
      data: {
        data: { closedPeriods }
      }
    } = response;
    
    return closedPeriods;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAppointmentById = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { appointment }
      }
    } = response;
    return appointment;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBlockTimeData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(StaffBlockedTimesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { staffBlockedTimes }
      }
    } = response;
    return staffBlockedTimes;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTimeOffData = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(StaffTimeOffsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { staffTimeOffs }
      }
    } = response;
    return staffTimeOffs;
  } catch (error) {
    console.log(error);
  }
};

export const fetchCalendarAppointments = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { appointments }
      }
    } = response;
    return appointments;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAppointmentServices = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentServicesDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { appointmentServices }
      }
    } = response;
    return appointmentServices;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAppointments = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentsDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { appointments }
      }
    } = response;
    return appointments;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAppointmentSummaryByBookingId = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(AppointmentSummaryDocument), variables },
    );

    const {
      data: {
        data: { appointmentSummary }
      }
    } = response;
    return appointmentSummary;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSaleSummaryBySaleId = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(SaleDocument), variables },
    );

    const {
      data: {
        data: { sale }
      }
    } = response;
    return sale;
  } catch (error) {
    console.log(error);
  }
}

export const fetchSaleById = async (variables) => {
  try {
    const response = await axios.post(
      '/graphql',
      { query: print(SaleDocument), variables },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );

    const {
      data: {
        data: { sale }
      }
    } = response;
    return sale;
  } catch (error) {
    console.log(error);
  }
};