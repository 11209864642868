import { AddonStatus, Permission, Role } from "../graphql/generated";
import { PERMISSION_LEVELS_LISTING } from "../uicomponents/constants";

const getStaffRole = () => {
  const staffRole = localStorage.getItem('role');
  if (staffRole === undefined || staffRole === 'undefined') {
    localStorage.clear()
    window.location.href = '/'
    return false;
  }
  if (staffRole === 'null') return false;
  if (!staffRole) return false;
  if (staffRole) return JSON.parse(staffRole)
}

export const canPerformAction = (action: string) => {
  const staffRole = getStaffRole();
  if (!staffRole) {
    return;
  }
  const permissions = staffRole?.authorizations;
  if (!permissions) return false;

  return permissions.some(({ name }) => {
      return name === action;
  });
}

export const checkIfCanPerformAction = (action: string, permissions: Permission[]) => {
  return permissions.find(({ name }) => {
      return name === action;
  });
}

export const forfietRight = (roleName: string) => {
  const staffRole = getStaffRole();
  if (!staffRole) {
    return;
  }
  return (staffRole?.name?.toLowerCase() === roleName)
} 

export const canShowPage = (action: string) => {
  const staffRole = getStaffRole();
  if (!staffRole) {
    return;
  }
  const permissions = staffRole?.authorizations;
  if (!permissions) return false;

  // check if action includes in permissions?.name
  return permissions.some(({ name }) => {
    return name.includes(action);
  });
}

const getRoleIndex = (roleName: string) => {
  switch (roleName) {
    case 'receptionist':
      return 1;
    case 'manager':
      return 2;
    case 'owner':
      return 3;
    default:
      return 0;
  }
}

export const formatPermissionSettingsFields = () => {
  const levels = PERMISSION_LEVELS_LISTING;
  const user = JSON.parse(localStorage.getItem('userData'))
  const roles: Role[] = user?.business?.roles;
  const authorizations = roles?.find((role) => role?.name === 'owner')?.authorizations;

  // loop through levels and roles and return an array of objects with label and value
  const roleLevels =  levels.map((level) => {
    return {
      levelTitle: level?.levelTitle,
      // loop through modules
      modules: level?.modules?.map((module) => {
        const ids = [];
        const levels = [false, false, false, false]
        // loop through module?.module
        module?.module?.forEach((item) => {
          const itemSplit = item ? item.split("::").filter(Boolean) : null;
          if (itemSplit && itemSplit?.length > 1) {
            const authorization = authorizations?.find((auth) => auth?.name === item);
            if (authorization) {
              ids.push(authorization?.id);
            }
          } else if (itemSplit && itemSplit?.length === 1) {
            const matchingAuthorizations = authorizations?.filter((auth) => auth?.name?.includes(itemSplit[0]));
            if (matchingAuthorizations) {
              const authIds = ids.concat(matchingAuthorizations.map((auth) => auth.id));
              ids.push(...authIds)
            }
          }
        });
        if (ids?.length > 0) {
          // loop through roles
          roles?.forEach((role) => {
            const checkRole = role?.authorizations?.some((auth) => ids?.includes(auth?.id));
            const roleIndex = getRoleIndex(role?.name);
            if (checkRole) {
              levels[roleIndex] = true;
            }
          })
        }
        return {
          label: module?.label,
          ids,
          levels,
          module: module?.module
        }
      })
    }
  });
  return roleLevels;
}

export const formatRoleName = (roleName: string) => {
  // switch case for roleName
  switch (roleName) {
    case 'receptionist':
      return 'Level 2';
    case 'manager':
      return 'Level 3';
    case 'owner':
      return 'Level 4';
    case 'staff':
      return 'Level 1';
    default:
      return roleName;
  }
}

export const formatLevelToRoleName = (level: string) => {
  // switch case for level
  switch (level) {
    case 'Level 2':
      return 'receptionist';
    case 'Level 3':
      return 'manager';
    case 'Level 4':
      return 'owner';
    case 'Level 1':
      return 'staff';
    default:
      return level;
  }
}

const getRoleIdViaLevel = (levelId: string) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const roles: Role[] = user?.business?.roles;
  const roleId = roles?.find((role) => role?.name === formatLevelToRoleName(levelId))?.id;
  return roleId;
}

// export const transformPermissionsToInput = (data) => {
//   const permissions = [];

//   console.log({
//     data
//   })
//   data.forEach(level => {
//     level.modules.forEach(module => {
//       module.levels.forEach((levelPermission, index) => {
//         if (levelPermission) {
//           const roleId = getRoleIdViaLevel(`Level ${index + 1}`)
//           permissions.push({
//             roleId, // Assuming roleId is derived from the level index
//             permissionIds: module.ids // Collecting all ids
//           });
//         }
//       });
//     });
//   });

//   // Merge permissions with the same roleId
//   const mergedPermissions = permissions.reduce((acc, curr) => {
//     const existing = acc.find(item => item.roleId === curr.roleId);
//     if (existing) {
//       existing.permissionIds = [...existing.permissionIds, ...curr.permissionIds];
//     } else {
//       acc.push(curr);
//     }
//     return acc;
//   }, []);

//   // Remove duplicates from permissionIds
//   mergedPermissions.forEach(permission => {
//     permission.permissionIds = [...new Set(permission.permissionIds)];
//   });

//   return mergedPermissions;
// };
export const transformPermissionsToInput = (data) => {
  const permissions = [];

  // Initialize permissions array with empty permissionIds for all levels
  for (let i = 1; i <= 4; i++) {
    permissions.push({
      roleId: getRoleIdViaLevel(`Level ${i}`),
      permissionIds: []
    });
  }
  
  data.forEach(level => {
    level.modules.forEach(module => {
      module.levels.forEach((levelPermission, index) => {
        if (levelPermission) {
          const roleId = getRoleIdViaLevel(`Level ${index + 1}`);
          const existingPermission = permissions.find(p => p.roleId === roleId);
          
          if (existingPermission) {
            existingPermission.permissionIds.push(...module.ids);
          }
        }
      });
    });
  });

  // Remove duplicates from permissionIds
  permissions.forEach(permission => {
    permission.permissionIds = [...new Set(permission.permissionIds)];
  });
  return permissions;
};

export const checkPageRightStatus = (module: string, action: string): string => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const addons: AddonStatus[] = user?.business?.addons;
  const addon = addons?.find((addon) => addon?.addon?.name?.toLowerCase()?.includes(module))

  if (!addon?.subscribed) {
    return 'shouldSubscribe';
  }

  if (addon?.subscribed && !canPerformAction(action)) {
    return 'hidePage';
  }

  return 'showPage'
}