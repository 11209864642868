import * as React from 'react'
import { Logo } from '../atoms/logo'
import { NavBar } from '../molecules/navBar'
import { Paragraph } from '../atoms/paragraph'
import { COLORS } from '../../constants/colors'
import { NameAvatar } from '../atoms/nameAvatar'
import { User } from '../../graphql/generated'
import { cleanUpText } from '../../utils/misc'

const SideMenu = () => {
  const user: User = JSON.parse(localStorage.getItem('userData'));
  const userInitials = user?.firstName?.charAt(0) + user?.lastName?.charAt(0);
  return (
    <div
      style={{ width: '300px' }}
      className='hidden xl:flex relative flex-col justify-between w-full h-screen bg-white border-r border-grey-100'
    >
        {/* <header
          style={{ top: '60px', marginLeft: '10px' }}
          className='hidden xl:block fixed bg-white'
        >
          <Logo className='max-w-[80px]' />
        </header> */}
      <div className='flex w-[300px] items-center space-x-4 fixed bg-white border-b border-r border-grey-50 h-[80px] xl:h-[120px] p-6'>
        <NameAvatar name={userInitials} />
        <div className='flex flex-col'>
          <Paragraph size='b4'>{user?.firstName} {user?.lastName}</Paragraph>
          <Paragraph size='b5' color={COLORS.GREY[300]}>{cleanUpText(user?.salonStaff?.specialty)}</Paragraph>
        </div>
      </div>
      <div
        style={{ marginTop: '120px', height: '80vh' }}
        className='overflow-y-scroll px-4'
      >
        <NavBar />
      </div>
    </div>
  )
}

export default SideMenu
