import React, { useState } from 'react'
import { BatchUploadProps } from './types'
import { CSV_UPLOAD_PATTERN } from '../constants/pattern'
import Button from '../ui/atoms/button/Button'
import { useSalonCache } from '../hooks/useSalonCache'
import { useFileUploader_ } from '../hooks/useUploader'
import { UploadServiceDocument, UploadServiceInput } from '../graphql/generated'
import axios from 'axios'
import { print } from 'graphql'

const ImportServices = (props: BatchUploadProps) => {
  const token = localStorage.getItem('token')
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [isLoading, setIsLoading] = useState(false)

  const uploadService = async (input: UploadServiceInput) => {
    try {
      const {
        data: {
          data: { uploadService: us }
        }
      } = await axios.post(
        '/graphql',
        { query: print(UploadServiceDocument), variables: { input } },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (us?.status === 200) {
        props?.addToast({message: 'Services uploaded successfully. This may take a few minutes to process', variant: 'success'})
      } else {
        props?.addToast({message: us?.errors[0]?.message, variant: 'error'})
      }
    } catch (error) {
      props?.addToast({ message: error?.message, variant: 'error' })
    }
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setIsLoading(true);
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)

      const payload = {
        salonId,
        url: url
      }

      uploadService(payload)
      setIsLoading(false)
    }

    reader.readAsDataURL(file)
  };

  return (
    <Button
      variant='success'
      className='relative'
      // disabled={uploadSalonServices?.isLoading}
      size='sm'
      rounded={props.rounded || 'md'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ?
        (
          <>
            Import Services
            <input
              className="cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full"
              type="file"
              accept={CSV_UPLOAD_PATTERN}
              onChange={handleCsvUpload}
            />
          </>
        ) : (
          <div className="flex justify-center gap-x-2 cursor-pointer">
            {[1, 2, 3].map((item, index) => (
              <div key={index} className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse" />
            ))}
          </div>
        )}
    </Button>
  )
}

export default ImportServices