import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  FormatNumber,
  Input,
  Layout,
  Modal,
  MultiSelect,
  MultiSelectOption,
  Paragraph,
  Pill,
  SearchTerm,
  SelectInput
} from '../ui'
import {
  DEPOSIT_TYPE_REQUIREMENTS,
  FORM_ERRORS,
  INVENTORY_TAB_NAME,
  PACKAGE_STATUS,
  PRODUCTS_HEADINGS,
  PRODUCT_TYPE_LIST_OPTIONS,
  SERVICES_HEADINGS,
  SERVICE_PRICE_TYPES,
  VALID_FOR,
  VOUCHER_SALE_ON,
  YES_OR_NO_SETTINGS
} from '../constants/information'
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper'
import { useModal, useToast } from '../hooks'
import { ToastProps } from '../ui/atoms/toast/types'
import Tabs from '../ui/molecules/tabs/Tabs'
import TableSkeleton from '../ui/organism/tableSkeleton/TableSkeleton'
import { Heading } from '../ui/atoms/heading'
import { Menu, Transition } from '@headlessui/react'
import SvgSelectDropDown from '../ui/icons/SelectDropDown'
import SvgFilter from '../ui/icons/BiFilter'
import Label from '../ui/atoms/formLabel/Label'
import FullTable from '../ui/organism/table/Table'
import {
  convertFullDateStringToDate,
  convertStaffOptionsToCommaSeparatedStringForServices,
  formatInToPrice,
  formatNumber,
  formatServiceCustomPriceInToSelectOptions,
  formatTableHeadersFilterArray,
  getFullTime,
  getHoursAndMinutes,
  getHoursAndMinutesString,
  limitString
} from '../utils/misc'
import {
  Category,
  CreatePackageInput,
  CreateProductInput,
  CreateServiceInput,
  DepositTypeEnum,
  Package,
  PackageService,
  Product,
  SalonStaff,
  Service,
  ServicePricingTypeEnum,
  UpdateProductStockInput
} from '../graphql/generated'
import ServiceProvider, { useServiceContext } from '../context/servicesContext'
import { COLORS } from '../constants/colors'
import { Controller, useForm } from 'react-hook-form'
import {
  CSV_UPLOAD_PATTERN,
  IMAGE_UPLOAD_PATTERN,
  REGEX_PATTERNS
} from '../constants/pattern'
import { ERRORS } from '../constants/errors'
import { DEFAULT_CURRENCY } from '../constants/currency'
import ProductProvider, { useProductContext } from '../context/productProvider'
import ImportFileLink from '../uicomponents/ImportFileLink'
import PackageProvider, { usePackageContext } from '../context/packageContext'
import {
  SvgArrowBack,
  SvgBrandVoucher,
  SvgChevronLeft,
  SvgClose,
  SvgGreyMinus,
  SvgGreyPlus,
  SvgMdiLightImage,
  SvgPackageCheckGrey,
  SvgPlus,
  SvgProductEmpty,
  SvgTrash
} from '../ui/icons'
import { useSalonCache } from '../hooks/useSalonCache'
import SvgUploadImage from '../ui/icons/UploadImage'
import { ProductInput } from '../uicomponents/types'
import { useFileUploader_ } from '../hooks/useUploader'
import { getImageUploadContent } from '../utils/upload'
import SingleSelect from '../ui/molecules/singleSelect/SingleSelect'
import { formatCategoriesToSelectFieldCreation, formatStaffToSelectField } from '../utils/utils'
import { fetchStaffListData } from '../modals/utils'
import { SelectedService } from '../modals/types'
import { createCategory, getCategoriesApi } from '../modals/api'
import { canPerformAction, checkPageRightStatus } from '../utils/permission'
import { PERMISSION_CONSTANTS } from '../constants/permission'
import { PermissionActionProp } from '../utils/types'
import FormToggle from '../ui/atoms/toggle/Toggle'
import ToggleOnly from '../ui/atoms/toggle/ToggleOnly'
import BlockPage from '../uicomponents/BlockPage'
import AddonSubscription from '../uicomponents/AddonSubscription'

export interface ITableFilterProp {
  label: string
  value: string
  show: boolean
}

export type TableFilterProps = ITableFilterProp[]

const Inventory = () => {
  const [activeTab, setActiveTab] = React.useState(
    INVENTORY_TAB_NAME.SERVICES as string
  )
  const { addToast, toast } = useToast();
  const [openAddProduct, setOpenAddProductModal] = useState(false);
  const [openAddService, setOpenAddServiceModal] = useState(false);
  const [openAddSalonPackage, setOpenAddSalonPackageModal] = useState(false);


  const openAddProductModal = () => {
    setOpenAddProductModal(true);
  }

  const closeAddProductModal = () => {
    setOpenAddProductModal(false)
  }

  const openAddServiceModal = () => {
    setOpenAddServiceModal(true);
  }

  const closeAddServiceModal = () => {
    setOpenAddServiceModal(false)
  }

  const openAddSalonPackageModal = () => {
    setOpenAddSalonPackageModal(true);
  }

  const closeAddSalonPackageModal = () => {
    setOpenAddSalonPackageModal(false)
  }

  const INVENTORY_TABS = [
    {
      key: INVENTORY_TAB_NAME.SERVICES,
      title: INVENTORY_TAB_NAME.SERVICES,
      show: true,
    },
    {
      key: INVENTORY_TAB_NAME.PRODUCTS,
      title: INVENTORY_TAB_NAME.PRODUCTS,
      show: true
    },
    {
      key: INVENTORY_TAB_NAME.PACKAGES,
      title: INVENTORY_TAB_NAME.PACKAGES,
      show: true
    }
  ]

  const showVoucherPage = checkPageRightStatus('vouchers', 'Catalogue::Packages::ViewPackage')

  const reloadPage = () => {
    window.location.reload()
  }
  return (
    <Layout pageTitle={activeTab}
      openModal={
        activeTab === INVENTORY_TAB_NAME.PRODUCTS ?
          openAddProductModal : activeTab === INVENTORY_TAB_NAME?.SERVICES ?
            openAddServiceModal : openAddSalonPackageModal
      } pageLevel={2}>
      <ToastWrapper toast={toast as ToastProps} />
      <Tabs
        tabs={INVENTORY_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === INVENTORY_TAB_NAME.SERVICES ? (
        <ServiceProvider>
          <Services openPage={openAddService} closePage={closeAddServiceModal} />
        </ServiceProvider>
      ) : activeTab === INVENTORY_TAB_NAME.PRODUCTS ? (
        <ProductProvider>
          <Products openPage={openAddProduct} closePage={closeAddProductModal} />
        </ProductProvider>
      ) : activeTab === INVENTORY_TAB_NAME.PACKAGES ? (
        <PackageProvider>
              <ServiceProvider>
              {showVoucherPage === 'showPage' ? (
                  <Packages openPage={openAddSalonPackage} closePage={closeAddSalonPackageModal} />
              ) : showVoucherPage === 'shouldSubscribe' ? (
                <AddonSubscription module='vouchers' addToast={addToast} reloadPage={reloadPage} />
              ) : showVoucherPage === 'hidePage' ? <BlockPage /> : null }
          </ServiceProvider>
        </PackageProvider>
      ) : null}
    </Layout>
  )
}

const Products = ({ openPage, closePage }: { openPage: boolean, closePage: () => void }) => {
  const { products, getProducts, toast } = useProductContext()
  const [product, setProduct] = React.useState<Product | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [productHeadings, setProductHeadings] = React.useState(PRODUCTS_HEADINGS)
  const MOBILE_PRODUCTS_HEADINGS = [
    {
      title: 'Product name',
      key: 'name'
    }, {
      title: 'Category',
      key: 'category'
    },
    {
      title: 'Description',
      key: 'description',
    },
    {
      title: 'Stock count',
      key: 'stock',
    },
    {
      title: 'Retail price',
      key: 'price'
    }
  ]

  const actions = {
    addProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.add}`),
    viewProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.view}`),
    editProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.edit}`),
    deleteProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.delete}`),
    increaseStock: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.increase_stock}`),
    decreaseStock: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.decrease_stock}`),
    importProduct: canPerformAction(`Catalogue::Product::${PERMISSION_CONSTANTS.product.import}`)
  }

  const {
    isVisible: isDeleteProductDialogModalVisible,
    openModal: openDeleteProductDialogModal,
    closeModal: closeDeleteProductDialogModal
  } = useModal()

  const {
    isVisible: isAddProductModalVisible,
    openModal: openAddProductModal,
    closeModal: closeAddProductModal
  } = useModal()

  const {
    isVisible: isViewProductModalVisible,
    openModal: openViewProductModal,
    closeModal: closeViewProductModal
  } = useModal()

  const {
    isVisible: isImportProductModalVisible,
    openModal: openImportProductModal,
    closeModal: closeImportProductModal
  } = useModal()

  React.useEffect(() => {
    if (openPage) {
      openAddProductModal()
    }
  }, [openPage])

  React.useEffect(() => {
    setIsLoading(true)
    getProducts()
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    getProducts(debouncedSearchQuery)
  }, [debouncedSearchQuery])

  const initiateCloseViewProductModal = () => {
    closeViewProductModal()
    setProduct(null)
  }

  const initiateCloseAddProductModal = () => {
    closeAddProductModal()
    setProduct(null)
    closePage()
  }

  const initiateCloseDeleteProductDialogModal = () => {
    closeDeleteProductDialogModal()
    setProduct(null)
  }

  const openProduct = (id: number, action?: string) => {
    const product = products[id]
    setProduct(product)
    switch (action) {
      case 'delete':
        openDeleteProductDialogModal()
        break
      case 'edit':
        openAddProductModal()
        break
      default:
        openViewProductModal()
        break
    }
  }

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in productHeadings then update the show
    const updatedHeadings = productHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setProductHeadings(updatedHeadings)
  }

  const generateProductsTableData = (
    products: Product[],
    tableHeadings: TableFilterProps
  ) => {
    return products?.map?.((product) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = product?.name
              break
            case 'category':
              rowData[heading.value] = product?.category?.name
              break
            case 'description':
              rowData[heading.value] = limitString(product?.description, 25)
              break
            case 'stock':
              rowData[heading.value] = product?.stockCount
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(product?.retailPrice)
              break
            case 'status':
              rowData[heading.value] = product.status ? (
                <Pill
                  variant={
                    product.status === 'In stock'
                      ? 'success'
                      : product.status === 'Sold out'
                        ? 'danger'
                        : product.status === 'Limited'
                          ? 'light'
                          : 'light'
                  }
                >
                  {product.status}
                </Pill>
              ) : (
                <Paragraph size='b5'> - </Paragraph>
              )
              break
          }
        }
      })
      return rowData
    })
  }
  const loadProductsShimmer = isLoading && !products?.nodes?.length && !debouncedSearchQuery;

  const getProductsContent = () => {
    if (loadProductsShimmer)
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )

    if (Array.isArray(products) && products.length || debouncedSearchQuery) {
      const tableClientsData = generateProductsTableData(
        products,
        productHeadings
      )
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Product list
            </Heading>
            {actions?.addProduct || actions?.importProduct ?
              <div className='flex md:items-center space-x-2'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                      <span>Add</span>
                      <SvgSelectDropDown width='10px' height='10px' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                      <div className='flex flex-col p-4 space-y-4'>
                        {actions?.addProduct ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openAddProductModal}
                            >
                              Add Product
                            </span>
                          </Menu.Item> : null}
                        {actions?.importProduct ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openImportProductModal}
                            >
                              Import Products
                            </span>
                          </Menu.Item> : null}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> : null}
          </div>
          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
            <div
              className='w-full xl:w-6/12 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, category, description, price'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(productHeadings) &&
                        productHeadings.length &&
                        productHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(productHeadings)}
            mobileHeaders={MOBILE_PRODUCTS_HEADINGS}
            rows={tableClientsData}
            onClick={openProduct}
            tableOptions={{
              view: actions.viewProduct,
              edit: actions.editProduct,
              delete: actions.deleteProduct,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div
          style={{ maxWidth: '450px' }}
          className='flex flex-col justify-center items-center space-y-6 w-full p-12'
        >
          <SvgProductEmpty width="100%" height="100%" />
          <Heading variant='h2' size='h3'>
            Products
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any products yet.
          </Paragraph>
          <div className='w-full flex space-x-2'>
            {actions?.importProduct ?
              <Button
                variant='success'
                size='lg'
                type='button'
                rounded='lg'
                onClick={openImportProductModal}
              >
                Import Product
              </Button> : null}
            {actions?.addProduct ?
              <Button
                variant='primary'
                size='lg'
                type='button'
                rounded='lg'
                onClick={openAddProductModal}
              >
                Add Product
              </Button> : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full mb-20'>
      <ToastWrapper toast={toast} />
      {getProductsContent()}
      <AddProductModal
        isVisible={isAddProductModalVisible}
        closeModal={initiateCloseAddProductModal}
        product={product}
        setProduct={setProduct}
        actions={actions}
      />
      <DeleteProductDialog
        product={product}
        isVisible={isDeleteProductDialogModalVisible}
        closeModal={initiateCloseDeleteProductDialogModal}
        setProduct={setProduct}
      />
      <ViewProductModal
        isVisible={isViewProductModalVisible}
        closeModal={initiateCloseViewProductModal}
        product={product}
        setProduct={setProduct}
        actions={actions}
      />
      <ImportProduct
        isVisible={isImportProductModalVisible}
        closeModal={closeImportProductModal}
      />
    </div>
  )
}

type ImportProductModalProps = {
  isVisible: boolean
  closeModal: () => void
}
const ImportProduct = ({ isVisible, closeModal }: ImportProductModalProps) => {
  // @ts-expect-error envVariables is defined in application.html.erb
  const PRODUCT_TEMPLATE_URL = `${envVariables.baseUrl}/home/download_template/product_import_template.csv`
  const [loading, setLoading] = useState(false)
  const { uploadProduct, addToast } = useProductContext()
  const [uploadUrl, setUploadUrl] = useState(null)
  const { getSalonFieldValue } = useSalonCache()
  const { handleSubmit, control, setValue } = useForm<FileUploadInput>()
  const importProductSubmit = async () => {
    setLoading(true)
    const payload = {
      url: uploadUrl,
      salonId: getSalonFieldValue('id')
    }
    await uploadProduct(payload)

    setValue('fileUpload', null)
    setUploadUrl(null)
    setLoading(false)
    closeModal()
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true)
    const file = event.target.files?.[0]
    setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setUploadUrl(url)
      setLoading(false)
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal title='Import Products' show={isVisible} closeModal={closeModal}>
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <a href={PRODUCT_TEMPLATE_URL} target='_blank' download>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            First, download the <span className='underline'>template</span> here
          </Paragraph>
        </a>
        <form
          onSubmit={handleSubmit(importProductSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <Controller
            control={control}
            name='fileUpload'
            render={({ field: { value } }) => {
              return (
                <div className='w-full flex flex-col py-6'>
                  <div className='w-full flex items-center space-x-4 border rounded-sm border-grey-20 border-dashed h-[130px] justify-center'>
                    <div className='relative cursor-pointer'>
                      {value ? (
                        <SvgMdiLightImage width='2rem' height='2rem' />
                      ) : (
                        <SvgUploadImage width='2rem' height='2rem' />
                      )}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                        type='file'
                        accept={CSV_UPLOAD_PATTERN}
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5'>
                        {value ? value?.name : 'Upload product inventory'}
                      </Paragraph>
                    </div>
                  </div>
                  {/* {errors?.fileUpload?.message && (
                    <FormHelperText variant='error'>
                      {errors.fileUpload.message}
                    </FormHelperText>
                  )} */}
                </div>
              )
            }}
            rules={{
              required: ERRORS.CSV_FILE_REQUIRED
            }}
          />
          <Button
            variant='primary'
            className=''
            disabled={loading}
            loading={loading}
            size='lg'
            rounded='lg'
          >
            Upload
          </Button>
          <Button
            variant='text'
            fontWeight='bold'
            className={`mx-auto`}
            size='none'
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

const ViewProductModal = ({
  isVisible,
  closeModal,
  product: p_,
  setProduct,
  actions
}: AddProductModalProps<'addProduct' | 'editProduct' | 'deleteProduct' | 'increaseStock' | 'decreaseStock'>) => {
  const { createProduct, getProduct, product } =
    useProductContext()
  const [loading, setLoading] = useState(false)
  const [actionType, setActionType] = useState('')
  const {
    isVisible: isAddProductModalVisible,
    openModal,
    closeModal: closeAddProductModal
  } = useModal()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreateProductInput>()

  const getProductAsync = () => {
    if (p_) getProduct(p_?.id)
  }

  useEffect(() => {
    getProductAsync()
  }, [p_])

  const {
    isVisible: isAddStockModalVisible,
    openModal: openAddStockModal,
    closeModal: closeAddStockModal
  } = useModal()

  const {
    isVisible: isDeleteProductDialogModalVisible,
    openModal: openDeleteProductDialogModal,
    closeModal: closeDeleteProductDialogModal
  } = useModal()

  const addToStock = () => {
    setActionType('add')
    openAddStockModal()
  }

  const removeStock = () => {
    setActionType('remove')
    openAddStockModal()
  }

  const onSave = async (input: CreateProductInput) => {
    setLoading(true)
    const payload = {
      id: product?.id,
      name: product?.name,
      description: product?.description,
      imageUrl: product?.imageUrl,
      categoryId: product?.category?.id,
      isPurchasable: product?.isPurchasable,
      costPrice: product?.costPrice,
      ...(product?.isPurchasable ? { retailPrice: product?.retailPrice || 0 } : {}),
      stockCount: Number(input?.stockCount),
      expirationDate: input?.expirationDate,
      lowStockThreshold: Number(input?.lowStockThreshold)
    }
    await createProduct(payload, closeModal, resetFormProduct)
    setLoading(false)
  }

  const initiateCloseDeleteProductDialogModal = (deleteProduct: boolean) => {
    if (deleteProduct) {
      closeDeleteProductDialogModal()
      _closeModal()
    } else {
      closeDeleteProductDialogModal()
    }
  }

  const _closeModal = () => {
    closeModal()
    resetFormProduct()
  }

  const resetFormProduct = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('category', '');
    setValue('costPrice', 0);
    setValue('retailPrice', 0)
    setValue('isPurchasable', false)
    setValue('imageUrl', '')
    setValue('stockCount', 0)
    setValue('lowStockThreshold', 0)
    setValue('expirationDate', null)
    setProduct && setProduct(null)
  }


  useEffect(() => {
    if (product) {
      setValue('stockCount', product.stockCount)
      setValue('lowStockThreshold', product.lowStockThreshold || 0)
      setValue('isPurchasable', product.isPurchasable)
      setValue(
        'expirationDate',
        product?.expirationDate
          ? convertFullDateStringToDate(product?.expirationDate as string)
          : null
      )
    }
  }, [product, setValue])

  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} size='lg'>
        <div className='w-full flex flex-col space-y-4 items-start justify-start'>
          <Button
            variant='icon'
            size='square'
            rounded='md'
            type='button'
            className='hidden xl:flex max-w-[50px]'
            onClick={_closeModal}
          >
            <SvgClose width='24px' height='24px' />
          </Button>
          <div className='w-full flex space-x-20'>
            <div
              style={{ width: '30%' }}
              className='w-[30%] flex flex-col space-y-6'
            >
              <div className='w-full'>
                <Heading variant='h1' size='h8' weight='bold'>
                  Product Details
                </Heading>
                <Paragraph size='b5' color={COLORS.GREY[400]}>
                  Find details about your product below
                </Paragraph>
              </div>
              <div className='w-full flex flex-col space-y-4'>
                <div className='w-full flex justify-between items-center'>
                  <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold'>
                    BASIC INFORMATION
                  </Paragraph>
                  {actions?.editProduct ?
                    <Button
                      variant='text'
                      fontWeight='bold'
                      className={`mx-auto text-green-600`}
                      size='none'
                      onClick={openModal}
                      type='button'
                    >
                      Edit
                    </Button> : null}
                </div>

                {product?.imageUrl && (
                  <img
                    src={product?.imageUrl}
                    alt='product image'
                    className='w-full max-w-[150px] p-2'
                    loading='lazy'
                  />
                )}
              </div>
              <div className='w-full'>
                <Heading variant='h2' size='h10'>
                  {product?.name}
                </Heading>
                <Paragraph size='b5' color={COLORS.GREY[400]}>
                  {product?.description} ・{product?.category?.name}
                </Paragraph>
              </div>
            </div>
            <div className='w-[70%] flex flex-col space-y-6'>
              <form
                onSubmit={handleSubmit(onSave)}
                className='w-full mt-6 space-y-6'
                autoComplete='off'
              >
                <Input
                  name='expirationDate'
                  label='Expiration Date'
                  id='expirationDate'
                  type='date'
                  placeholder='DD/MM/YY'
                  control={control}
                />
                <div className='w-full flex flex-col space-y-2'>
                  <div className='w-full flex gap-x-4'>
                    <Input
                      name='stockCount'
                      label='Stock count'
                      id='stock-count'
                      type='text'
                      placeholder='0'
                      control={control}
                      rules={{
                        required: FORM_ERRORS.STOCK_COUNT_REQUIRED,
                        pattern: REGEX_PATTERNS.NUMBER
                      }}
                      error={errors?.stockCount}
                      disabled={true}
                    />
                    <Input
                      name='lowStockThreshold'
                      label='Low stock alert at'
                      id='lowStockThreshold'
                      type='text'
                      placeholder='0'
                      control={control}
                      rules={{
                        required: FORM_ERRORS.THRESHOLD_REQUIRED,
                        pattern: REGEX_PATTERNS.NUMBER
                      }}
                      error={errors?.lowStockThreshold}
                      disabled={true}
                    />
                  </div>
                  <div className='w-full flex gap-x-4'>
                    {actions?.increaseStock ?
                      <Button
                        variant='success'
                        size='sm'
                        rounded='sm'
                        fontWeight='semiBold'
                        onClick={addToStock}
                        type='button'
                      >
                        Add item
                      </Button> : null}
                    {actions?.decreaseStock ?
                      <Button
                        variant='light'
                        size='sm'
                        rounded='sm'
                        fontWeight='semiBold'
                        onClick={removeStock}
                        type='button'
                      >
                        Remove item
                      </Button> : null}
                  </div>
                </div>
                <div className='space-y-6'>
                  {actions?.editProduct ?
                    <Button
                      variant='primary'
                      className=''
                      disabled={loading}
                      loading={loading}
                      size='lg'
                      rounded='lg'
                    >
                      Save Changes
                    </Button> : null}
                  {actions?.deleteProduct ?
                    <Button
                      variant='text'
                      className={`mx-auto text-red-600`}
                      disabled={false}
                      loading={false}
                      size='none'
                      fontWeight='semiBold'
                      onClick={openDeleteProductDialogModal}
                      type='button'
                    >
                      Delete Product
                    </Button> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
        <AddProductModal
          isVisible={isAddProductModalVisible}
          product={product}
          closeModal={() => {
            closeAddProductModal()
            getProductAsync()
          }}
          actions={actions}
        />
        <UpdateStockModal
          isVisible={isAddStockModalVisible}
          actionType={actionType}
          closeModal={() => {
            closeAddStockModal()
            getProductAsync()
          }}
          product={product}
          actions={actions}
        />
      </Modal>
      <DeleteProductDialog
        product={product}
        isVisible={isDeleteProductDialogModalVisible}
        closeModal={initiateCloseDeleteProductDialogModal}
        setProduct={setProduct}
      />
    </>
  )
}

type UpdateStockModalProps<T extends string> = {
  isVisible: boolean;
  closeModal: () => void;
  product?: Product;
  setProduct?: (product: Product | null) => void;
  actions: PermissionActionProp<T>;
  actionType: string,
}

const UpdateStockModal = ({
  actionType,
  isVisible,
  closeModal,
  product,
  actions
}: UpdateStockModalProps<'increaseStock' | 'decreaseStock'>) => {
  const [saving, setSaving] = useState(false)
  const { updateProductStock } = useProductContext()
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<UpdateProductStockInput>()

  const resetForm = () => {
    setValue('quantity', 0)
    setValue('reason', '')
  }

  const modifyProductStockSubmit = async (input: UpdateProductStockInput) => {
    setSaving(true)
    const payload: UpdateProductStockInput = {
      quantity: Number(input?.quantity?.toString()?.replace(/,/, '')),
      reason: input?.reason,
      actionType,
      productId: product?.id,
      salonId: product?.salonId
    }
    await updateProductStock(payload, closeModal, resetForm)
    setSaving(false)
  }

  const _closeModal = () => {
    resetForm()
    closeModal()
  }

  const increaseStockCount = () => {
    const currentStockCount = watch('quantity') // Get the current stock count value

    if (isNaN(currentStockCount)) {
      // Check if it's not a valid number
      setValue('quantity', 1) // Initialize stockCount to 1
    } else {
      setValue('quantity', Number(currentStockCount) + 1) // Increment stockCount by 1
    }
  }

  const decreaseStockCount = () => {
    if (watch('quantity') > 0) {
      setValue('quantity', Number(watch('quantity')) - 1)
    }
  }

  return (
    <Modal
      title={actionType === 'add' ? 'Add Item' : 'Remove Item'}
      show={isVisible}
      closeModal={_closeModal}
    >
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <div className='w-full flex flex-col items-center space-y-4'>
          <div className='w-full flex flex-col items-center space-y-2'>
            <Paragraph size='b4' color={COLORS.GREY[900]}>
              {product?.name}
            </Paragraph>
            <Paragraph size='b6' color={COLORS.GREY[900]}>
              {formatInToPrice(product?.retailPrice)}
            </Paragraph>
          </div>

          <Pill variant='light' className='text-b4 text-grey-400 font-semibold'>
            {product?.stockCount} IN STOCK
          </Pill>
        </div>
        <form
          onSubmit={handleSubmit(modifyProductStockSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <div className='w-full flex gap-x-4'>
            <Controller
              control={control}
              name='quantity'
              render={({ field, formState: { errors } }) => {
                return (
                  <div className='w-full flex flex-col'>
                    <FormLabel htmlFor='quantity'>Quantity</FormLabel>
                    <div className='w-full flex space-x-4 pt-2'>
                      {actions?.increaseStock ?
                        <Button
                          variant='icon'
                          size='none'
                          type='button'
                          className='border-0'
                          onClick={increaseStockCount}
                        >
                          <SvgGreyPlus width='36px' height='36px' />
                        </Button> : null}
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='1'
                      />
                      {actions?.decreaseStock ?
                        <Button
                          variant='icon'
                          size='none'
                          type='button'
                          className='border-0'
                          onClick={decreaseStockCount}
                        >
                          <SvgGreyMinus width='36px' height='36px' />
                        </Button> : null}
                    </div>
                    {errors?.quantity?.message && (
                      <FormHelperText variant='error'>
                        {errors.quantity.message}
                      </FormHelperText>
                    )}
                  </div>
                )
              }}
              rules={{
                required: 'Stock quantity is required'
              }}
            />
            <SelectInput
              name='reason'
              id='type'
              label='Reason'
              control={control}
              error={errors.reason}
              rules={{ required: 'Select a reason' }}
              options={PRODUCT_TYPE_LIST_OPTIONS}
              placeholder='Select Reason'
            />
          </div>
          {actions?.increaseStock || actions?.decreaseStock ?
            <Button
              variant='primary'
              className=''
              disabled={saving}
              loading={saving}
              size='lg'
              rounded='lg'
            >
              Save
            </Button> : null}
          <Button
            variant='text'
            fontWeight='bold'
            className={`mx-auto`}
            size='none'
            onClick={_closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

type AddProductModalProps<T extends string> = {
  isVisible: boolean
  closeModal: () => void
  product?: Product
  setProduct?: (product: Product | null) => void
  actions: PermissionActionProp<T>
}
const AddProductModal = ({
  isVisible,
  closeModal,
  product,
  setProduct,
  actions
}: AddProductModalProps<'editProduct' | 'addProduct' | 'deleteProduct'>) => {
  const { createProduct, getCategories, categories, createProductCategory } = useProductContext();
  const [categorysOptions, setCategorysOptions] = useState<MultiSelectOption[]>([]);
  const [saving, setSaving] = useState(false)
  const [newCategory, setNewCategory] = useState(false)
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<ProductInput>()
  const [imageUrl, setImageUrl] = useState('')
  const [isFileUploading, setIsFileUploading] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setCategorysOptions(formatCategoriesToSelectFieldCreation(categories))
  }, [categories]);

  useEffect(() => {
    if (watch('categoryId')) {
      const categoryIdField = watch('categoryId') as unknown as {
        label: string;
        value: string;
      };
      if (categoryIdField?.value === 'new-cate') {
        setNewCategory(true);
      } else {
        setNewCategory(false);
      }
    }
  }, [watch('categoryId')])

  const addProductSubmit = async (input: ProductInput) => {
    setSaving(true)
    const categoryIdField = input.categoryId as unknown as {
      label: string;
      value: string;
    };
    let categoryId = categoryIdField?.value;
    if (newCategory) {
      const categoryResult = await createProductCategory({
        name: input?.category
      })
      if (categoryResult) {
        categoryId = categoryResult?.id;
      }
    }
    const payload = {
      name: input?.name,
      description: input?.description,
      imageUrl: imageUrl,
      categoryId,
      isPurchasable: input.isPurchasable ? true : false,
      id: product ? product.id : null,
      lowStockThreshold: Number(input.lowStockThreshold),
      stockCount: Number(input.stockCount),
      costPrice: Number(input.costPrice.toString().replace(/,/g, '')),
      retailPrice: input.isPurchasable
        ? Number(input.retailPrice.toString().replace(/,/g, ''))
        : null,
      expirationDate: input.expirationDate,
    }
    await createProduct(payload, closeModal, resetFormInput)
    setSaving(false)
  }

  useEffect(() => {
    if (product) {
      setValue('name', product?.name)
      setValue('description', product?.description)
      setValue('categoryId', product?.category ? {
        label: product?.category?.name,
        value: product?.category?.id
      } as unknown as [] : [])
      setValue('costPrice', product?.costPrice)
      setValue('retailPrice', product?.retailPrice)
      setValue('isPurchasable', product?.isPurchasable)
      setValue('imageUrl', product?.imageUrl)
      setValue('stockCount', product?.stockCount)
      setValue('lowStockThreshold', product?.lowStockThreshold || 0)
      setValue(
        'expirationDate',
        product?.expirationDate
          ? convertFullDateStringToDate(product?.expirationDate as string)
          : null
      )
    } else {
      resetFormInput()
    }
  }, [product]);

  const resetFormInput = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('categoryId', []);
    setValue('costPrice', 0);
    setValue('retailPrice', 0)
    setValue('isPurchasable', false)
    setValue('imageUrl', '')
    setValue('stockCount', 0)
    setValue('lowStockThreshold', 0)
    setValue('expirationDate', null)
    setProduct && setProduct(null)
  }

  const _closeModal = () => {
    closeModal()
    resetFormInput()
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsFileUploading(true)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setImageUrl(url)
      setIsFileUploading(false)
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal
      title={product ? 'Edit Product' : 'Add Product'}
      subTitle={product ? 'Edit Product' : 'Add a new product to your business'}
      show={isVisible}
      closeModal={_closeModal}
    >
      <form
        onSubmit={handleSubmit(addProductSubmit)}
        className='w-full mt-6 space-y-6'
        autoComplete='off'
      >
        <Input
          name='name'
          label='Product name'
          id='product-name'
          type='text'
          placeholder='E.g Manicure'
          control={control}
          rules={{
            required: 'Product name is required'
          }}
          error={errors.name}
        />
        <Input
          name='description'
          label='Description'
          id='description'
          type='text'
          placeholder='A brief description of this service'
          control={control}
          error={errors.description}
        />
        <div className='w-full flex gap-x-4'>
          <div className='w-full flex flex-col space-y-2'>
            <Controller
              control={control}
              name="categoryId"
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => {
                const errorMessage: string = errors?.categoryId?.message;
                return (
                  <>
                    <FormLabel htmlFor="service">
                      Select a category
                    </FormLabel>
                    <SingleSelect
                      selected={value || []}
                      options={categorysOptions}
                      setSelected={onChange}
                    />
                    {errorMessage && (
                      <FormHelperText variant="error">
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
              rules={{
                required: "Select a category",
              }}
            />
            {newCategory && (
              <Input
                name='category'
                label='Category'
                id='category'
                type='text'
                placeholder='Enter a category'
                control={control}
                rules={{
                  required: newCategory && FORM_ERRORS.CATEGORY_REQUIRED
                }}
                error={errors.category}
              />
            )}
          </div>
          <div className='w-full'>
            <Input
              name='expirationDate'
              label='Expiration Date'
              id='expirationDate'
              type='date'
              placeholder='DD/MM/YY'
              control={control}
            />
          </div>
        </div>
        <div className='w-full flex border rounded-sm border-grey-20 border-dashed h-[130px] justify-center items-center'>
          <Controller
            control={control}
            name='imageUrl'
            render={({ field: { value }, formState: { errors } }) => {
              const productImageUploadUrl = imageUrl || value
              return (
                <div className='flex flex-col'>
                  <div className='flex items-center space-x-4'>
                    <div className='relative cursor-pointer'>
                      {getImageUploadContent({
                        imageUrl: productImageUploadUrl,
                        uploadIsLoading: isFileUploading
                      })}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-full h-[96px]'
                        type='file'
                        accept={IMAGE_UPLOAD_PATTERN}
                        onChange={handleFileUpload}
                      />
                    </div>
                  </div>
                  {errors?.imageUrl?.message && (
                    <FormHelperText variant='error'>
                      {errors.imageUrl.message}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
          />
        </div>
        <div className='w-full flex gap-x-4'>
          <Input
            name='stockCount'
            label='Stock count'
            id='stock-count'
            type='text'
            placeholder='0'
            control={control}
            rules={{
              required: FORM_ERRORS.STOCK_COUNT_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER
            }}
            error={errors.stockCount}
          />
          <Input
            name='lowStockThreshold'
            label='Low stock alert at'
            id='lowStockThreshold'
            type='text'
            placeholder='0'
            control={control}
            rules={{
              required: FORM_ERRORS.THRESHOLD_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER
            }}
            error={errors.lowStockThreshold}
          />
        </div>
        <div className='w-full flex gap-x-4'>
          <div className='w-1/2 flex flex-col space-y-4'>
            <Controller
              control={control}
              name='costPrice'
              rules={{ required: 'Please enter a price' }}
              render={({ field, formState: { errors } }) => (
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='price'>Cost Price</FormLabel>
                  <input
                    className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                    type='text'
                    {...field}
                    value={formatNumber(field.value)}
                    onChange={(e) =>
                      field.onChange(formatNumber(e.target.value))
                    }
                    placeholder='Enter price'
                  />
                  {errors?.costPrice?.message && (
                    <FormHelperText variant='error'>
                      {errors.costPrice.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name='isPurchasable'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      <div className='flex' onClick={() => onChange(!value)}>
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <Checkbox isChecked={value} />
                          <span className='text-grey-900 whitespace-nowrap'>
                            Allow sales of this product
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          </div>
          <div className='w-1/2'>
            {watch('isPurchasable') ? (
              <Controller
                control={control}
                name='retailPrice'
                rules={{ required: 'Please enter a price' }}
                render={({ field, formState: { errors } }) => (
                  <div className='w-full flex flex-col space-y-2'>
                    <FormLabel htmlFor='price'>Retail Price</FormLabel>
                    <input
                      className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                      type='text'
                      {...field}
                      value={formatNumber(field.value as number)}
                      onChange={(e) =>
                        field.onChange(formatNumber(e.target.value))
                      }
                      placeholder='Enter price'
                    />
                    {errors?.retailPrice?.message && (
                      <FormHelperText variant='error'>
                        {errors.retailPrice.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            ) : null}
          </div>
        </div>
        <div className='space-y-6'>
          {actions?.editProduct || actions?.addProduct ?
            <Button
              variant='primary'
              className=''
              disabled={saving}
              loading={saving}
              size='lg'
              rounded='lg'
            >
              Save
            </Button> : null}
          <Button
            variant='text'
            className={`mx-auto`}
            size='none'
            onClick={_closeModal}
            type='button'
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

type DeleteProductDialogProps = {
  product: Product
  isVisible: boolean
  closeModal: (deleteProduct?: boolean) => void
  setProduct: (product: Product | null) => void
}
const DeleteProductDialog = ({
  product,
  isVisible,
  closeModal,
  setProduct
}: DeleteProductDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { deleteProduct } = useProductContext()

  const cancelProductDeletion = () => {
    closeModal()
  }

  const handleDelete = () => {
    setIsLoading(true)
    deleteProduct(product.id)
    setIsLoading(false)
    closeModal(true)
    setProduct(null)
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Product
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {product?.name}? This means that
                this product will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => cancelProductDeletion()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const Packages = ({ openPage, closePage }: { openPage: boolean, closePage: () => void }) => {
  const PACKAGES_HEADINGS = [
    {
      label: 'Voucher name',
      value: 'name',
      show: true
    },
    {
      label: 'Description',
      value: 'description',
      show: true
    },
    {
      label: 'Services',
      value: 'services',
      show: true
    },
    {
      label: 'Valid for',
      value: 'validityMonths',
      show: true
    },
    {
      label: 'Sale price',
      value: 'price',
      show: true
    },
    {
      label: 'Status',
      value: 'status',
      show: true
    }
  ]
  const MOBILE_PACKAGES_HEADINGS = [
    {
      title: 'Package name',
      key: 'name'
    },
    {
      title: 'Description',
      key: 'description',
    },
    {
      title: 'Services',
      key: 'services',
    },
    {
      title: 'Valid for',
      key: 'validityMonths',
    },
    {
      title: 'Sale price',
      key: 'price'
    },
  ]
  const [loading, setLoading] = useState(false)
  const { getPackages, packages, toast } = usePackageContext()
  const { getServices } = useServiceContext()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null)

  const [packageHeadings, setPackageHeadings] =
    React.useState(PACKAGES_HEADINGS)

  const actions = {
    addPackage: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.add}`),
    editPackage: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.edit}`),
    viewPackages: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.view}`),
    deletePackage: canPerformAction(`Catalogue::Packages::${PERMISSION_CONSTANTS.voucher.delete}`)
  }
  const {
    isVisible: isAddPackageModalVisible,
    closeModal: closeAddPackageModal,
    openModal: openAddPackageModal
  } = useModal()

  const {
    isVisible: isViewPackageModalVisible,
    closeModal: closeViewPackageModal,
    openModal: openViewPackageModal
  } = useModal()

  const {
    isVisible: isDeletePackageModalVisible,
    closeModal: closeDeletePackageModal,
    openModal: openDeletePackageModal
  } = useModal()

  useEffect(() => {
    if (openPage) {
      openAddPackageModal()
    }
  }, [openPage])

  useEffect(() => {
    setLoading(true)
    getPackages()
    getServices()
    setLoading(false)
  }, [])

  useEffect(() => {
    getPackages(debouncedSearchQuery)
  }, [debouncedSearchQuery])

  const closePackageAddModal = () => {
    closeAddPackageModal()
    closePage();
  }

  const initaiteCloseDeletePackageModal = () => {
    closeDeletePackageModal()
    setSelectedPackage(null)
  }

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in packageHeadings then update the show
    const updatedHeadings = packageHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setPackageHeadings(updatedHeadings)
  }

  const convertSalonServicesNamesToString = (services?: Service[]) => {
    const title = services?.map(({ name }) => name).join(', ') || ''
    return title
  }

  const generatePackagesTableData = (
    packages: Package[],
    tableHeadings: TableFilterProps
  ) => {
    return packages?.map?.((salonPackage) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = salonPackage?.name
              break
            case 'services':
              rowData[heading.value] = limitString(
                convertSalonServicesNamesToString(salonPackage?.services),
                20
              )
              break
            case 'description':
              rowData[heading.value] = limitString(salonPackage?.description, 12)
              break
            case 'validityMonths':
              rowData[heading.value] = `${salonPackage?.validityMonths} Month${salonPackage?.validityMonths > 1 ? 's' : ''
                }`
              break
            case 'price':
              rowData[heading.value] = salonPackage?.usesCustomPrice
                ? formatInToPrice(salonPackage?.customPrice)
                : formatInToPrice(salonPackage?.totalPrice)
              break
            case 'status':
              rowData[heading.value] = salonPackage?.status ? (
                <Pill
                  variant={
                    salonPackage.status === 'active'
                      ? 'success'
                      : salonPackage.status === 'expired'
                        ? 'danger'
                        : salonPackage.status === 'inactive'
                          ? 'light'
                          : 'light'
                  }
                >
                  {salonPackage?.status}
                </Pill>
              ) : (
                <Paragraph size='b5'> - </Paragraph>
              )
              break
          }
        }
      })
      return rowData
    })
  }

  const handleOpenViewPackageModal = (id: number, action?: string) => {
    const p = packages[id]
    setSelectedPackage(p)

    switch (action) {
      case 'view':
        openViewPackageModal()
        break
      case 'edit':
        openAddPackageModal()
        break
      case 'delete':
        openDeletePackageModal()
        break
      default:
        openViewPackageModal()
        break
    }
  }


  const loadPackagesShimmer = loading && !packages?.length && !debouncedSearchQuery;

  const getPackagesContent = () => {
    if (loadPackagesShimmer)
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )

    if (Array.isArray(packages) && packages.length || debouncedSearchQuery) {
      const rows = generatePackagesTableData(packages || [], packageHeadings)
      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Vouchers list
            </Heading>
            {actions?.addPackage ?
              <div className='flex md:items-center space-x-2'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                      <span>Add</span>
                      <SvgSelectDropDown width='10px' height='10px' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                      <div className='flex flex-col p-4 space-y-4'>
                        <Menu.Item>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={openAddPackageModal}
                          >
                            Create Voucher
                          </span>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              : null}
          </div>
          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
            <div
              className='w-full xl:w-6/12 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, category, description, price'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(packageHeadings) &&
                        packageHeadings.length &&
                        packageHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(packageHeadings)}
            mobileHeaders={MOBILE_PACKAGES_HEADINGS}
            rows={rows}
            onClick={handleOpenViewPackageModal}
            tableOptions={{
              view: actions?.viewPackages,
              delete: actions?.deletePackage,
              edit: actions?.editPackage,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <SvgBrandVoucher width="100%" height="100%" />
          <Heading variant='h2' size='h3'>
            Vouchers
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any vouchers yet.
          </Paragraph>
          <div className='w-full flex space-x-2'>
            <Button
              variant='primary'
              size='lg'
              disabled={false}
              loading={false}
              type='button'
              rounded='lg'
              onClick={openAddPackageModal}
            >
              Create Voucher
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full mb-20'>
      <ToastWrapper toast={toast} />
      {getPackagesContent()}
      <AddPackageModal
        isVisible={isAddPackageModalVisible}
        closeModal={closePackageAddModal}
        package_={selectedPackage}
        setPackage={setSelectedPackage}
        actions={actions}
      />
      <ViewPackage
        isVisible={isViewPackageModalVisible}
        closeModal={closeViewPackageModal}
        package_={selectedPackage}
        setPackage={setSelectedPackage}
        actions={actions}
      />
      <DeleteSalonPackageModal
        isVisible={isDeletePackageModalVisible}
        closeModal={initaiteCloseDeletePackageModal}
        package_={selectedPackage}
        setPackage={setSelectedPackage}
      />
    </div>
  )
}

type AddPackageModalProps<T extends string> = {
  isVisible: boolean
  closeModal: () => void
  package_?: Package,
  setPackage?: (package_: Package | null) => void,
  actions: PermissionActionProp<T>
}
interface ICreatePackageInput extends CreatePackageInput {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services: any[]
  price: number
}
const AddPackageModal = ({
  isVisible,
  closeModal,
  package_,
  setPackage,
  actions
}: AddPackageModalProps<'editPackage' | 'addPackage'>) => {
  const [saving, setSaving] = useState(false)
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm<ICreatePackageInput>()
  const { services } = useServiceContext()
  const { createPackage } = usePackageContext()
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>([])
  const [totalPrice, setTotalPrice] = useState<null | number>(null)
  const formatServicesToSelectField = (services: Service[]) => {
    return services?.map((service) => ({
      label: `${service?.name} - ${formatInToPrice(service?.price)}`,
      value: service?.id
    }))
  }
  const servicesOptions = formatServicesToSelectField(services)
  const { getSalonFieldValue } = useSalonCache()

  const addSalonPackage = async (input: ICreatePackageInput) => {
    setSaving(true)
    const payload = {
      ...input,
      isPurchasableAsVoucher: true,
      isDiscountApplicable: input.isDiscountApplicable ? input.isDiscountApplicable : false,
      id: package_ ? package_.id : null,
      validityMonths: Number(input.validityMonths),
      status: input.status ? input.status : 'active',
      serviceIdsAndQuantity: convertSelectedServicesIntoServiceIdsAndQuantity(),
      usesCustomPrice: input.customPrice ? true : false,
      customPrice: input.customPrice
        ? Number(input.customPrice.toString().replace(/,/g, ''))
        : null,
      salonId: getSalonFieldValue('id'),
      activateTax: false
    }

    delete payload?.services
    delete payload.price
    createPackage(payload, closeModal, resetPackageFormInput)
    setSaving(false)
  }

  const convertSelectedServicesIntoServiceIdsAndQuantity = () => {
    return Array?.isArray(selectedServices) && selectedServices?.length && selectedServices.map((service) => ({
      serviceId: service?.id,
      quantity: service.quantity || 1
    }))
  }

  const resetPackageFormInput = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('validityMonths', 0);
    setValue('status', null);
    setValue('isDiscountApplicable', false);
    setValue('isPurchasableAsVoucher', false);
    setValue('usesCustomPrice', false);
    setValue('customPrice', 0);
    setValue('services', []);
    setValue('allowSaleOn', null)
    setSelectedServices([]);
    setPackage && setPackage(null)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const convertSalonServicesNamesToString = (services?: Service[]) => {
    const title = services?.map(({ name }) => name).join(', ') || ''
    return title
  }

  const transformServicesArrayInToMultiSelectOptions = (
    inputArray: PackageService[]
  ): [] => {
    if (!inputArray) return []
    return inputArray.map((service) => ({
      label: service.name,
      value: service.serviceId
    })) as []
  }

  useEffect(() => {
    if (package_) {
      setValue('name', package_.name)
      setValue('description', package_.description)
      setValue('validityMonths', package_.validityMonths)
      setValue(
        'customPrice',
        package_.usesCustomPrice ? package_.customPrice : 0
      )
      // @ts-expect-error enum status value
      setValue('status', package_.status)
      // @ts-expect-error enum status value
      setValue('allowSaleOn', package_.allowSaleOn)
      setValue('usesCustomPrice', package_.usesCustomPrice)
      setValue('isDiscountApplicable', package_.isDiscountApplicable)
      setValue('isPurchasableAsVoucher', package_.isPurchasableAsVoucher)
      setValue(
        'services',
        transformServicesArrayInToMultiSelectOptions(package_?.packageServices)
      )
      const serviceSelectedFromOptions = services
        .filter((service) => {
          const serviceId = service?.serviceId
          const existsInServicesField = watch('services')?.some(
            (serviceSelected: MultiSelectOption) =>
              serviceSelected?.value === serviceId
          )
          const existsInSelectedServices = selectedServices.some(
            (selectedService) => selectedService.id === serviceId
          )

          if (!existsInServicesField && existsInSelectedServices) {
            // Return null to indicate that this service should be removed
            setSelectedServices((prevSelectedServices) =>
              prevSelectedServices.filter((s) => s.id !== serviceId)
            )
            return false
          }
          return existsInServicesField && !existsInSelectedServices
        })
        .filter((service) => service !== null) // Remove the null values

      if (serviceSelectedFromOptions) {
        setSelectedServices((prevSelectedServices) => [
          ...prevSelectedServices,
          ...serviceSelectedFromOptions
        ])
      }
    } else {
      resetPackageFormInput()
    }
  }, [package_])

  // useEffect(() => {
  //   // get total price from selected services
  //   const totalPrice = selectedServices.reduce((prev, curr) => {
  //     return prev + curr.price
  //   }, 0)
  //   setValue('price', totalPrice)
  //   setTotalPrice(totalPrice)
  // }, [selectedServices])

  useEffect(() => {
    if (watch('services')) {
      const serviceSelectedFromOptions = services.filter((service) => {
        const serviceId = service?.id;
        const existsInServicesField = watch('services')?.some((serviceSelected: MultiSelectOption) => serviceSelected?.value === serviceId);
        const existsInSelectedServices = selectedServices.some((selectedService) => selectedService.id === serviceId);

        if (!existsInServicesField && existsInSelectedServices) {
          // Return null to indicate that this service should be removed
          setSelectedServices((prevSelectedServices) => prevSelectedServices.filter((s) => s.id !== serviceId));
          return false;
        }
        return existsInServicesField && !existsInSelectedServices;
      }).filter((service) => service !== null); // Remove the null values

      if (serviceSelectedFromOptions) {
        setSelectedServices((prevSelectedServices) => [
          ...prevSelectedServices,
          ...serviceSelectedFromOptions
        ]);
      }
    }
  }, [watch('services')]);

  useEffect(() => {
    // get total price from selected services
    const totalPrice = selectedServices.reduce((prev, curr) => {
      const quantity = curr.quantity || 1;
      return prev + curr.price * quantity;
    }, 0);
    setValue('price', totalPrice);
    setTotalPrice(totalPrice);
  }, [selectedServices])

  const _closeModal = () => {
    closeModal()
    resetPackageFormInput()
  }

  const modifyCart = (id: string, type: "add" | "remove", inventory: "service") => {
    // modify cart for services
    if (inventory === "service") {
      let remove = false;
      const serviceSelectedFromOptions = selectedServices.map(service => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1;
          if (type === "add") {
            return {
              ...service,
              quantity: quantity + 1
            };
          }
          if (type === "remove") {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1
              };
            } else {
              remove = true
            }
          }
        }
        return service;
      });
      if (remove) {
        // get serive index from watch("services") via id
        const serviceIndex = watch('services')?.findIndex((serviceSelected: MultiSelectOption) => serviceSelected?.value === id);
        removeService(serviceIndex);
        return;
      }
      setSelectedServices(serviceSelectedFromOptions);
    }
  }

  const removeService = (index?: number) => {
    const selectedServicesWatched = watch('services');
    if (!Array.isArray(selectedServicesWatched) || typeof index !== 'number') return;
    if (selectedServicesWatched?.length && selectedServicesWatched?.[index]) {
      selectedServicesWatched.splice(index, 1);
    }
    setValue('services', selectedServicesWatched);

    if (selectedServices?.length && selectedServices?.[index]) {
      selectedServices.splice(index, 1);
    }
    setSelectedServices(selectedServices);
  }

  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} variant='right'>
        <form
          onSubmit={handleSubmit(addSalonPackage)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Voucher
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Heading variant='h1' size='h9' weight='semiBold'>
              {!package_ ? 'Create voucher' : 'Edit voucher'}
            </Heading>
            <Paragraph size='b4'>
              {!package_
                ? 'Create a new voucher for a clients'
                : 'Edit an existing voucher'}
            </Paragraph>
          </div>

          <div className='w-full p-6 space-y-6'>
            <Input
              name='name'
              label='Voucher name'
              id='package-name'
              type='text'
              placeholder='Enter voucher name here'
              control={control}
              rules={{
                required: ERRORS.PACKAGE_NAME_REQUIRED,
                pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
              }}
              error={errors.name}
              disabled={package_ ? true : false}
            />
            <Input
              name='description'
              label='Description of voucher'
              id='description'
              type='text'
              placeholder='A brief description of this voucher'
              control={control}
              error={errors.description}
            />
            <div className='w-full flex gap-x-4'>
              <SelectInput
                name='validityMonths'
                id='validityMonths'
                label='Valid for'
                control={control}
                rules={{
                  required: ERRORS.VALID_FOR_REQUIRED
                }}
                error={errors.validityMonths}
                options={VALID_FOR}
                placeholder='Select validity period'
              />
              {package_ ? (
                <SelectInput
                  name='status'
                  id='status'
                  label='Status'
                  control={control}
                  error={errors.status}
                  options={PACKAGE_STATUS}
                  placeholder='Select Status'
                />
              ) : null}
            </div>
            <div className='w-full flex gap-x-4'>
              <div className='w-1/2 flex flex-col space-y-4'>
                <Controller
                  control={control}
                  name='services'
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const errorMessage: string = errors?.services?.message
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>Add Service(s)</FormLabel>
                        <MultiSelect
                          selected={value || []}
                          options={servicesOptions}
                          setSelected={onChange}
                          disabled={package_ ? true : false}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: 'Add a service'
                  }}
                />
                {!package_ ? (
                  <Controller
                    control={control}
                    name='usesCustomPrice'
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className='w-full flex flex-col space-y-4'>
                          <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                            <div
                              className='flex'
                              onClick={() => onChange(!value)}
                            >
                              <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                <Checkbox isChecked={value} />
                                <span className='text-grey-900 whitespace-nowrap'>
                                  Use custom price
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                ) : null}
              </div>
              <div className='w-1/2'>
                <Controller
                  control={control}
                  name='price'
                  // rules={{ required: "Please enter a price" }}
                  render={({ field, formState: { errors } }) => (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='price'>Price</FormLabel>
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value as number)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='Price'
                        disabled={true}
                      />
                      {errors?.price?.message && (
                        <FormHelperText variant='error'>
                          {errors.price.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className='w-1/2'>
              {watch('usesCustomPrice') && (
                <Controller
                  control={control}
                  name='customPrice'
                  rules={{ required: 'Please enter a price' }}
                  render={({ field, formState: { errors } }) => (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='customPrice'>Custom Price</FormLabel>
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value as number)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='Enter price'
                        disabled={package_ ? true : false}
                      />
                      {errors?.customPrice?.message && (
                        <FormHelperText variant='error'>
                          {errors.customPrice.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              )}
            </div>
            {selectedServices && Array.isArray(selectedServices) && selectedServices?.length > 0 ? (
              <div className="w-full flex flex-col py-5 px-4 gap-4 border border-grey-20 rounded-md">
                <div className="w-full flex flex-col space-y-4">
                  <div className="w-full flex space-x-2">
                    <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[50%]'>SELECTED SERVICES</Heading>
                    <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[20%]'>SELECT QUANTITY</Heading>
                    <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[20%] flex justify-end'>PRICE</Heading>
                    <div className='w-[10%] flex justify-end'></div>
                  </div>
                  <>
                    {selectedServices?.map((service, index) => {
                      let quantity = service?.quantity || 1;
                      if (package_) {
                        quantity = package_?.packageServices?.find(s => s.serviceId === service?.id)?.quantity || 1;
                      }
                      return (
                        <div className='w-full flex items-start space-x-2' key={service?.id}>
                          <div className='flex flex-col w-[50%]'>
                            <Paragraph size='b4' color={COLORS.GREY[300]} className="">{service?.name}</Paragraph>
                          </div>
                          <div className="w-[20%] flex space-x-2">
                            <div className="w-full flex pt-2">
                              <Button
                                variant='icon'
                                size="none"
                                type="button"
                                className="border-0"
                                onClick={() => modifyCart(service?.id, 'add', 'service')}
                                disabled={package_ ? true : false}
                              >
                                <SvgGreyPlus width="22px" height="22px" />
                              </Button>
                              <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                                <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{quantity}</Paragraph>
                              </div>
                              <Button
                                variant='icon'
                                size="none"
                                type="button"
                                className="border-0"
                                onClick={() => modifyCart(service?.id, 'remove', 'service')}
                                disabled={package_ ? true : false}
                              >
                                <SvgGreyMinus width="22px" height="22px" />
                              </Button>
                            </div>
                          </div>
                          <div className='flex justify-end w-[20%]'>
                            <Paragraph size='b4' color={COLORS.GREY[300]} className="">
                              {DEFAULT_CURRENCY}<FormatNumber value={service?.price * quantity} />
                              {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full ml-2'>from</span>) : null}
                            </Paragraph>
                          </div>
                          {!package_ && (
                            <div className='w-[10%] flex justify-end items-center'>
                              <span className="text-red-600 text-b5 cursor-pointer" onClick={() => {
                                removeService(index)
                              }}><SvgTrash width="14px" height="14px" /></span>
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </>
                </div>
                <div className='w-full flex justify-between'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Total amount</Paragraph>
                  <div className='flex flex-col xl:flex-row gap-2'>
                    <Paragraph size='b4' className={watch('usesCustomPrice') ? 'line-through' : ''}>{formatInToPrice(totalPrice || 0)}</Paragraph>
                    {watch('usesCustomPrice') ? (
                      <Paragraph size='b4'>{formatInToPrice(watch('customPrice') || 0)}</Paragraph>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <Controller
              control={control}
              name='isDiscountApplicable'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <FormLabel htmlFor='isPromoApplicable'>
                      Promo/discounts applicable
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                        ? YES_OR_NO_SETTINGS.map(
                          (isPromoApplicable: {
                            label: string
                            value: boolean
                          }) => {
                            return (
                              <div
                                className='flex'
                                onClick={() =>
                                  onChange(isPromoApplicable?.value)
                                }
                                key={isPromoApplicable?.label}
                              >
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                  <Checkbox
                                    isChecked={
                                      value === isPromoApplicable?.value
                                    }
                                  />
                                  <span className='text-grey-900 whitespace-nowrap'>
                                    {isPromoApplicable?.label}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )
                        : null}
                    </div>
                  </div>
                )
              }}
            />
            <Controller
              control={control}
              name='allowSaleOn'
              render={({ field: { onChange, value } }) => {
                const errorMessage: string =
                  errors?.allowSaleOn?.message
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <FormLabel htmlFor='isPurchasableAsVoucher'>
                      Allow voucher as sale on
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      {Array.isArray(VOUCHER_SALE_ON) &&
                        VOUCHER_SALE_ON.length
                        ? VOUCHER_SALE_ON.map(
                          (allowSaleOn: {
                            label: string
                            value: string
                          }) => {
                            return (
                              <div
                                className='flex'
                                onClick={() =>
                                  onChange(allowSaleOn?.value)
                                }
                                key={allowSaleOn?.label}
                              >
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                  <Checkbox
                                    isChecked={
                                      value === allowSaleOn?.value
                                    }
                                  />
                                  <span className='text-grey-900 whitespace-nowrap'>
                                    {allowSaleOn?.label}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )
                        : null}
                    </div>
                    {errorMessage && (
                      <FormHelperText variant='error'>
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </div>
                )
              }}
              rules={{ required: "Choose an option" }}
            />
          </div>

          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex justify-between py-4 px-8'>
              <div className='bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  TOTAL: {DEFAULT_CURRENCY}
                  <FormatNumber
                    value={
                      !watch('usesCustomPrice')
                        ? totalPrice
                        : watch('customPrice')
                    }
                  />
                </Paragraph>
              </div>
              <div className='flex space-x-2'>
                <Button
                  variant='text'
                  className='mr-6'
                  size='none'
                  fontWeight='semiBold'
                  type='button'
                  onClick={_closeModal}
                >
                  Cancel
                </Button>
                {actions?.editPackage || actions?.addPackage ?
                  <Button
                    variant='primary'
                    className=''
                    size='md'
                    rounded='lg'
                    disabled={false}
                    loading={saving}
                  >
                    {package_ ? 'Save' : 'Create voucher'}
                  </Button> : null}
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

const ViewPackage = ({
  isVisible,
  closeModal,
  package_,
  setPackage,
  actions
}: AddPackageModalProps<'editPackage' | 'deletePackage' | 'addPackage'>) => {
  const {
    openModal: openAddPackageModal,
    closeModal: closeAddPackageModal,
    isVisible: isAddPackageModalVisible
  } = useModal()
  const convertSalonServicesNamesToString = (services?: PackageService[]) => {
    const title = services?.map(({ name, quantity }) => `${name} x${quantity}`).join(', ') || ''
    return title
  }

  const {
    isVisible: isDeletePackageModalVisible,
    closeModal: closeDeletePackageModal,
    openModal: openDeletePackageModal
  } = useModal()

  const initiateCloseDeletePackageModal = (deleteSalonPackage?: boolean) => {
    if (deleteSalonPackage) {
      closeDeletePackageModal()
      _closeModal()
    } else {
      closeDeletePackageModal()
    }
  }

  const _closeModal = () => {
    closeModal()
    setPackage(null)
  }

  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} variant='right'>
        <div className='w-full relative my-[80px]'>
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                View voucher Details
              </Paragraph>
            </div>
          </div>
          <div className='w-full space-y-12 px-6 py-4'>
            <div className='w-full flex items-center justify-between'>
              <div className='flex flex-col space-y-6'>
                <Heading variant='h1' size='h9' weight='semiBold'>
                  Voucher details
                </Heading>
                <Paragraph size='b4'>
                  Find information about package here
                </Paragraph>
              </div>
              <div className="flex flex-col xl:flex-row gap-4">
                {actions?.deletePackage ?
                  <Button
                    variant='text'
                    type='button'
                    fontWeight='semiBold'
                    fontSize='b5'
                    className='text-red-500'
                    onClick={openDeletePackageModal}
                  >
                    Delete
                  </Button> : null}
                {actions?.editPackage ?
                  <Button
                    variant='secondary'
                    size='sm'
                    type='button'
                    rounded='md'
                    fontWeight='semiBold'
                    fontSize='b5'
                    onClick={openAddPackageModal}
                  >
                    Edit details
                  </Button> : null}
              </div>
            </div>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
                <div className='grid grid-col-1 md:grid-cols-4 gap-4'>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Name
                      </Paragraph>
                      <Paragraph size='b4'>{package_?.name}</Paragraph>
                    </div>
                  </div>
                  <div className='col-span-2 flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Services
                      </Paragraph>
                      <Paragraph size='b4'>
                        {convertSalonServicesNamesToString(package_?.packageServices)}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Valid for
                      </Paragraph>
                      <Paragraph size='b4'>
                        {package_?.validityMonths} Month(s)
                      </Paragraph>
                    </div>
                  </div>
                </div>
                <div className='grid grid-col-1 md:grid-cols-4 gap-4 pt-4 border-t border-grey-50'>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Total price
                      </Paragraph>
                      <Paragraph size='b4'>
                        {package_?.usesCustomPrice ? formatInToPrice(package_?.customPrice) : formatInToPrice(package_?.totalPrice)}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Total quantity
                      </Paragraph>
                      <Paragraph size='b4'>
                        {package_?.services?.length}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='flex justify-start items-start space-x-4'>
                    <div className='w-[20px] h-[20px] bg-grey-75'></div>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>
                        Status
                      </Paragraph>
                      <Paragraph size='b4' className='capitalize'>
                        {package_?.status}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full flex flex-col border border-grey-20 space-y-6 p-4 rounded-md'>
                <Paragraph size='b4' color={COLORS.GREY[300]}>
                  Permissions
                </Paragraph>
                <div className='flex gap-4'>
                  {package_?.isPurchasableAsVoucher ?
                    <Paragraph
                      size='b4'
                      color={COLORS.GREY[900]}
                      className='flex space-x-4 items-center'
                    >
                      {package_?.allowSaleOn === 'salon' ? <div className='w-[20px] h-[20px] bg-grey-75'></div> : <SvgPackageCheckGrey width='16px' height='16px' />}{' '}
                      <span>Online sale as vouchers</span>
                    </Paragraph> : null}
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[900]}
                    className='flex space-x-4 items-center'
                  >
                    {package_?.isDiscountApplicable ? <SvgPackageCheckGrey width='16px' height='16px' /> : <div className='w-[20px] h-[20px] bg-grey-75'></div>}{' '}
                    <span>Promos/discounts applicable</span>
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddPackageModal
          isVisible={isAddPackageModalVisible}
          closeModal={closeAddPackageModal}
          package_={package_}
          actions={actions}
        />
      </Modal>
      <DeleteSalonPackageModal
        isVisible={isDeletePackageModalVisible}
        closeModal={initiateCloseDeletePackageModal}
        package_={package_}
        setPackage={setPackage}
      />
    </>
  )
}

type DeleteAddPackageModalProps = {
  isVisible: boolean
  closeModal: (deletePackage?: boolean) => void
  package_?: Package,
  setPackage?: (package_: Package | null) => void
}
const DeleteSalonPackageModal = ({
  package_,
  isVisible,
  closeModal,
  setPackage
}: DeleteAddPackageModalProps) => {
  const { deletePackage } = usePackageContext()

  const cancelPackage = () => {
    closeModal();
  }

  const handleDelete = async () => {
    await deletePackage({ packageId: package_.id })
    closeModal(true)
    setPackage(null);
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Package
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {package_?.name}? This means
                that this package will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={handleDelete}
                disabled={false}
                loading={false}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={cancelPackage}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const Services = ({ openPage, closePage }: { openPage: boolean, closePage: () => void }) => {
  const { getServices, services, toast } = useServiceContext()
  const [isLoading, setIsLoading] = useState(false)
  const [service, setService] = useState<Service>()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [serviceHeadings, setServiceHeadings] =
    React.useState(SERVICES_HEADINGS)
  const actions = {
    addService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.add}`),
    editService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.edit}`),
    deleteService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.delete}`),
    viewService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.view}`),
  }
  const MOBILE_SERVICES_HEADINGS = [
    {
      title: 'Service Name',
      key: 'name'
    }, {
      title: 'Category',
      key: 'category'
    }, {
      title: 'Description',
      key: 'description'
    }, {
      title: 'Duration',
      key: 'duration'
    },
    {
      title: 'Price',
      key: 'price'
    }
  ]

  const {
    isVisible: isDeleteServiceDialogModalVisible,
    openModal: openDeleteServiceDialogModal,
    closeModal: closeDeleteServiceDialogModal
  } = useModal()
  const {
    isVisible: isServiceModalVisible,
    openModal: openAddServiceModal,
    closeModal: closeAddServiceModal
  } = useModal()
  const {
    isVisible: isViewServiceModalVisible,
    openModal: openViewServiceModal,
    closeModal: closeViewServiceModal
  } = useModal()
  const {
    isVisible: isImportServiceModalVisible,
    openModal: openImportServiceModal,
    closeModal: closeImportServiceModal
  } = useModal()

  const handleCloseAddServiceModal = () => {
    setService(null)
    closeAddServiceModal()
    closePage()
  }

  const handleCloseViewServiceModal = () => {
    setService(null)
    closeViewServiceModal()
  }

  const handleDeleteService = () => {
    setService(null)
    closeDeleteServiceDialogModal()
  }

  useEffect(() => {
    setIsLoading(true)
    getServices()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (openPage) {
      openAddServiceModal()
    }
  }, [openPage])

  useEffect(() => {
    getServices(debouncedSearchQuery)
  }, [debouncedSearchQuery])

  const generateServicesTableData = (
    services: Service[],
    tableHeadings: TableFilterProps
  ) => {
    return services?.map?.((service) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = service?.name
              break
            case 'category':
              rowData[heading.value] = service?.category?.name
              break
            case 'description':
              rowData[heading.value] = limitString(service?.description, 10)
              break
            case 'duration':
              rowData[heading.value] = `${service?.duration} mins`
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(service?.price)
              break
          }
        }
      })
      return rowData
    })
  }

  const handleHeadingCheckboxChange = (value: string) => {
    const updatedHeadings = serviceHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setServiceHeadings(updatedHeadings)
  }

  const openService = (id: number, action?: string) => {
    const service = services[id]
    if (!action && !actions?.editService && !actions?.viewService) return;
    setService(service)
    switch (action) {
      case 'delete':
        openDeleteServiceDialogModal()
        break
      case 'edit':
        openAddServiceModal()
        break
      default:
        openViewServiceModal()
    }
  }

  const loadServicesShimmer = isLoading && !services?.length && !debouncedSearchQuery;

  const getServicesContent = () => {
    if (loadServicesShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }
    if (Array.isArray(services) && services.length || debouncedSearchQuery) {
      const tableServicesData = generateServicesTableData(
        services,
        serviceHeadings
      )

      return (
        <>
          <div className='flex flex-col md:flex-row py-4 px-8 justify-between md:items-center gap-4 border-b border-grey-20'>
            <Heading variant='h2' size='h11' weight='bold'>
              Service list
            </Heading>
            {actions?.addService ?
              <div className='flex md:items-center space-x-2'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                      <span>Add</span>
                      <SvgSelectDropDown width='10px' height='10px' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                      <div className='flex flex-col p-4 space-y-4'>
                        {actions?.addService ?
                          <Menu.Item>
                            <span
                              className='text-b4 cursor-pointer'
                              onClick={openAddServiceModal}
                            >
                              Add Service
                            </span>
                          </Menu.Item>
                          : null}
                        <Menu.Item>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={openImportServiceModal}
                          >
                            Import Services
                          </span>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div> : null}
          </div>
          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
            <div
              className='w-full xl:w-1/2 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, category, description, price'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(serviceHeadings) &&
                        serviceHeadings.length &&
                        serviceHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(serviceHeadings)}
            rows={tableServicesData}
            onClick={openService}
            mobileHeaders={MOBILE_SERVICES_HEADINGS}
            tableOptions={{
              view: actions?.viewService,
              edit: actions?.editService,
              delete: actions?.deleteService,
              duplicate: false
            }}
          />
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div
          style={{ maxWidth: '450px' }}
          className='flex flex-col justify-center items-center space-y-6 w-full p-12'
        >
          <SvgProductEmpty width="100%" height="100%" />
          <Heading variant='h2' size='h3'>
            Services
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any services yet
          </Paragraph>
          <div className='w-full flex space-x-2'>
            <ImportServices />
            {actions?.addService ?
              <Button
                variant='primary'
                size='lg'
                disabled={false}
                loading={false}
                type='button'
                rounded='lg'
                onClick={openAddServiceModal}
              >
                Add Service
              </Button>
              : null}
          </div>
          <ImportFileLink modules='service' />
        </div>
      </div>
    )
  }

  return (
    <div className='w-full mb-20'>
      <ToastWrapper toast={toast} />
      {getServicesContent()}
      <DeleteServiceDialog
        isVisible={isDeleteServiceDialogModalVisible}
        closeModal={handleDeleteService}
        service={service}
      />
      <AddServiceModal
        service={service}
        isVisible={isServiceModalVisible}
        closeModal={handleCloseAddServiceModal}
        actions={actions}
      />
      <ViewServiceModal
        service={service}
        isVisible={isViewServiceModalVisible}
        closeModal={handleCloseViewServiceModal}
      />
      <ImportServiceModal
        isVisible={isImportServiceModalVisible}
        closeModal={closeImportServiceModal}
      />
    </div>
  )
}

const ImportServices = () => {
  const { uploadService } = useServiceContext()
  const { getSalonFieldValue } = useSalonCache()
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUploadUrl] = useState(null)

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true)
    const file = event.target.files?.[0]
    // setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setUploadUrl(url)
      const payload = {
        salonId: getSalonFieldValue('id'),
        url: url
      }
      await uploadService(payload)

      setIsLoading(false)
    }

    reader.readAsDataURL(file)
  }

  return (
    <Button
      variant='success'
      className='relative'
      size='lg'
      rounded={'lg'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ? (
        <>
          Import Services
          <input
            className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full'
            type='file'
            accept={CSV_UPLOAD_PATTERN}
            onChange={handleCsvUpload}
          />
        </>
      ) : (
        <div className='flex justify-center gap-x-2 cursor-pointer'>
          {[1, 2, 3].map((item, index) => (
            <div
              key={index}
              className='h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse'
            />
          ))}
        </div>
      )}
    </Button>
  )
}

type ImportServiceModalProps = {
  isVisible: boolean
  closeModal: () => void
}
type FileUploadInput = {
  fileUpload: File | null
}
const ImportServiceModal = ({
  isVisible,
  closeModal
}: ImportServiceModalProps) => {
  // @ts-expect-error envVariables is defined in application.html.erb
  const SERVICE_TEMPLATE_URL = `${envVariables.baseUrl}/home/download_template/service_import_template.csv`
  const [loading, setLoading] = useState(false)
  const { getSalonFieldValue } = useSalonCache()
  const [uploadUrl, setUploadUrl] = useState(null)
  const { uploadService } = useServiceContext()
  const { handleSubmit, control, setValue } = useForm<FileUploadInput>()
  const importServiceSubmit = async () => {
    setLoading(true)
    const payload = {
      salonId: getSalonFieldValue('id'),
      url: uploadUrl
    }
    await uploadService(payload)
    setLoading(false)
    setValue('fileUpload', null)
    setUploadUrl(null)
    closeModal()
  }
  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true)
    const file = event.target.files?.[0]
    setValue('fileUpload', file)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      const { url } = await useFileUploader_(base64DataUri)
      setUploadUrl(url)
      setLoading(false)
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal title='Import Services' show={isVisible} closeModal={closeModal}>
      <div className='w-full flex flex-col my-6 items-center space-y-6'>
        <a href={SERVICE_TEMPLATE_URL} target='_blank' download>
          <Paragraph size='b5' color={COLORS.GREY[400]}>
            First, download the <span className='underline'>template</span> here
          </Paragraph>
        </a>
        <form
          onSubmit={handleSubmit(importServiceSubmit)}
          className='w-full mt-6 space-y-6'
          autoComplete='off'
        >
          <Controller
            control={control}
            name='fileUpload'
            render={({ field: { value }, formState: { errors } }) => {
              return (
                <div className='w-full flex flex-col py-6'>
                  <div className='w-full flex items-center space-x-4 border rounded-sm border-grey-20 border-dashed h-[130px] justify-center'>
                    <div className='relative cursor-pointer'>
                      {value ? (
                        <SvgMdiLightImage width='2rem' height='2rem' />
                      ) : (
                        <SvgUploadImage width='2rem' height='2rem' />
                      )}
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-[128px] h-[96px]'
                        type='file'
                        accept={CSV_UPLOAD_PATTERN}
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <Paragraph size='b5'>
                        {value ? value?.name : 'Upload service inventory'}
                      </Paragraph>
                    </div>
                  </div>
                  {errors?.fileUpload?.message && (
                    <FormHelperText variant='error'>
                      {errors?.fileUpload.message}
                    </FormHelperText>
                  )}
                </div>
              )
            }}
            rules={{
              required: 'Service file is required'
            }}
          />
          <Button
            variant='primary'
            className=''
            disabled={loading}
            loading={loading}
            size='lg'
            rounded='lg'
          >
            Upload
          </Button>
          <Button
            variant='text'
            fontWeight='bold'
            className={`mx-auto`}
            size='none'
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
        </form>
      </div>
    </Modal>
  )
}

interface ICreateServiceInput extends CreateServiceInput {
  durationMinutes: string
  durationHours: string
  extraTimeMinutes: string
  extraTimeHours: string
  category: string
  serviceCategoryId: []
}
const AddServiceModal = ({
  service,
  closeModal,
  isVisible,
  actions
}: ServiceDialogProps<'editService' | 'viewService' | 'deleteService'>) => {
  const [staff, setStaff] = useState<SalonStaff[]>([]);
  const [staffMembersOptions, setStaffMembersOptions] = useState<MultiSelectOption[]>([]);
  const { createService, closeUploadServiceModal } = useServiceContext()
  const [typeSign, setTypeSign] = useState<string | null>('-')
  const [allowCustomPrice, setAllowCustomPrice] = useState(false);
  const [allowServiceExtraTime, setAllowServiceExtraTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false)
  const { getSalonFieldValue } = useSalonCache()
  const [serviceCustomPrices, setServiceCustomPrices] = useState<{ price: number, staff: MultiSelectOption[] }[]>([])
  const salonId = getSalonFieldValue('id')
  const [newCategory, setNewCategory] = useState(false)
  const [categories, setCategories] = useState<Category[] | null>(null)
  const [categorysOptions, setCategorysOptions] = useState<MultiSelectOption[]>([]);
  const [showCustomButton, setShowCustomButton] = useState(false)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<ICreateServiceInput>({
    delayError: 100,
    mode: 'onChange',
    defaultValues: {
      isDepositRequired: false
    }
  })

  const {
    isVisible: isDeleteServiceDialogModalVisible,
    openModal: openDeleteServiceDialogModal,
    closeModal: closeDeleteServiceDialogModal
  } = useModal()

  const handleClose = () => {
    resetServiceFormInput();
    closeModal()
  }

  const resetServiceFormInput = () => {
    setValue('name', '')
    setValue('description', '')
    setValue('category', '')
    setValue('serviceCategoryId', []);
    setValue('price', 0)
    setValue('pricingType', null)
    setValue('durationHours', null)
    setValue('durationMinutes', null)
    setValue('extraTimeMinutes', null)
    setValue('extraTimeHours', null)
    setValue('isDepositRequired', null)
    setValue('depositValue', null)
    setValue('depositType', null)
    setValue('salonStaffIds', [])
    setServiceCustomPrices([])
    setAllowCustomPrice(false)
    setAllowServiceExtraTime(false)
    setShowCustomButton(false)
    setValue('isPublic', false)
  }

  useEffect(() => {
    if (isVisible) {
      const fetchData = async () => {
        try {
          const staffData = await fetchStaffListData({ salonId });
          setStaff(staffData?.staffs);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      if (salonId) {
        fetchData();
      }
    }
  }, [salonId, isVisible]);

  useEffect(() => {
    const updateOptions = () => {
      if (Array?.isArray(staff) && staff?.length) {
        setStaffMembersOptions(formatStaffToSelectField(staff));
      }
    };

    updateOptions();
  }, [staff]);

  const getCategoriesAsync = async () => {
    const cates = await getCategoriesApi(salonId);

    if (Array?.isArray(cates) && cates?.length) {
      setCategories(cates);
    }
  }

  useEffect(() => {
    if (salonId && isVisible) {
      getCategoriesAsync()
    }
  }, [salonId, isVisible]);

  useEffect(() => {
    setCategorysOptions(formatCategoriesToSelectFieldCreation(categories))
  }, [categories]);

  useEffect(() => {
    if (watch('serviceCategoryId')) {
      const categoryIdField = watch('serviceCategoryId') as unknown as {
        label: string;
        value: string;
      };
      if (categoryIdField?.value === 'new-cate') {
        setNewCategory(true);
      } else {
        setNewCategory(false);
      }
    }
  }, [watch('serviceCategoryId')])

  useEffect(() => {
    const salonStaffIds = watch('salonStaffIds');
    if (Array?.isArray(salonStaffIds) && salonStaffIds?.length > 0) {
      setShowCustomButton(true);
    } else {
      setShowCustomButton(false);
    }
  }, [watch('salonStaffIds')]);

  useEffect(() => {
    if (service) {
      const { hours, minutes } = getHoursAndMinutes(service?.duration)
      const { hours: extraTimeHours, minutes: extraTimeMinutes } = getHoursAndMinutes(service?.extraTime)
      if (service?.extraTime > 0) {
        setAllowServiceExtraTime(true)
      }
      const serviceType =
        service?.pricingType === 'fixed'
          ? ServicePricingTypeEnum.Fixed
          : ServicePricingTypeEnum.StartingAt
      setValue('name', service?.name)
      setValue('description', service?.description)
      setValue('price', service?.price)
      setValue('pricingType', serviceType)
      setValue('durationHours', hours.toString())
      setValue('durationMinutes', minutes.toString())
      setValue('extraTimeHours', extraTimeHours.toString())
      setValue('extraTimeMinutes', extraTimeMinutes.toString())
      setValue('isDepositRequired', service?.isDepositRequired)
      setValue('depositValue', service?.depositValue)
      setValue('isPublic', service?.isPublic)
      setValue(
        'depositType',
        service.depositType === 'percentage'
          ? DepositTypeEnum.Percentage : service.depositType === 'fixed_value' ? DepositTypeEnum.FixedValue : null
      )
    } else {
      resetServiceFormInput()
    }
  }, [service])

  useEffect(() => {
    if (service && Array?.isArray(categories) && categories?.length) {
      const serviceCategory = service?.category?.id;
      const checkCategory = categories.find((cate) => cate?.id === serviceCategory);
      if (checkCategory) {
        setValue('serviceCategoryId', {
          label: checkCategory?.name,
          value: checkCategory?.id
        } as unknown as [])
      }
    }

  }, [service, categories])

  useEffect(() => {
    if (service && Array?.isArray(staff) && staff?.length) {
      // get staff ids in staff that matches service?.serviceStaffs
      const staffIds = staff.filter(staff => {
        const staffService = service?.serviceStaffs?.find(
          serviceStaff => serviceStaff?.salonStaff?.id === staff.id
        )
        return staffService
      })
      if (staffIds?.length) {
        setShowCustomButton(true)
      }
      setValue('salonStaffIds', formatStaffToSelectField(staffIds) as []);

      // checkif service?.serviceStaffs price are different
      const serviceStaffs = service?.serviceStaffs;
      let differentPriceStaffs = [];
      if (serviceStaffs.length) {
        differentPriceStaffs = serviceStaffs.filter((serviceStaff) => serviceStaff.price !== service?.price && serviceStaff?.price);
      }
      if (Array?.isArray(differentPriceStaffs) && differentPriceStaffs?.length) {

        setAllowCustomPrice(true);
        setServiceCustomPrices(formatServiceCustomPriceInToSelectOptions(differentPriceStaffs));
      }
    } else {
      setValue('salonStaffIds', [])
    }
  }, [service, staff])

  useEffect(() => {
    if (watch('depositType')) {
      if (watch('depositType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY)
      } else if (watch('depositType') === 'percentage') {
        setTypeSign('%')
      }
    } else {
      setTypeSign('-')
    }
  }, [watch('depositType')])

  const handleCreateService = async (input: ICreateServiceInput) => {
    const duration = getFullTime(
      input.durationMinutes?.toString(),
      input.durationHours?.toString()
    )
    const extraTime = getFullTime(
      input.extraTimeMinutes?.toString(),
      input.extraTimeHours?.toString()
    )
    delete input.durationHours
    delete input.durationMinutes
    delete input.extraTimeMinutes
    delete input.extraTimeHours

    const categoryIdField = input?.serviceCategoryId as unknown as {
      label: string;
      value: string;
    };
    let categoryId = categoryIdField?.value;
    if (newCategory) {
      const categoryResult = await createCategory({
        name: input?.category,
      })
      if (categoryResult) {
        categoryId = categoryResult?.id;
      }
    }
    delete input?.category;
    delete input?.serviceCategoryId
    const salonStaffIds = input?.salonStaffIds ? convertStaffOptionsToCommaSeparatedStringForServices(input?.salonStaffIds as unknown as MultiSelectOption[], input.price || 0, serviceCustomPrices) : [];
    setIsLoading(true)
    const payload: CreateServiceInput = {
      ...input,
      categoryId,
      duration,
      extraTime,
      id: service ? service.id : null,
      customerCanSchedule: true,
      depositValue: input.depositValue && input?.isDepositRequired
        ? Number(input.depositValue.toString().replace(/,/g, ''))
        : 0,
      salonStaffIds,
      price: Number(input.price.toString().replace(/,/g, ''))
    } as CreateServiceInput
    createService(payload, closeModal, resetServiceFormInput)
    setIsLoading(false)
  }

  const handleCancelOrDelete = () => {
    if (service && actions?.deleteService) {
      openDeleteServiceDialogModal()
    } else {
      handleClose()
    }
    if (closeUploadServiceModal) {
      handleClose()
    }
  }

  const initiateCloseDeleteService = (deleteService: boolean) => {
    if (deleteService) {
      closeDeleteServiceDialogModal()
      handleClose()
    } else {
      closeDeleteServiceDialogModal()
    }
  }

  const enableOrDisableCustomPrice = () => {
    setAllowCustomPrice(!allowCustomPrice)
    if (Array?.isArray(serviceCustomPrices) && serviceCustomPrices?.length === 0) {
      setServiceCustomPrices([
        { price: 0, staff: [] }
      ])
    }
  }

  const addMoreCustomPrice = () => {
    setServiceCustomPrices([
      ...serviceCustomPrices,
      { price: 0, staff: [] }
    ])
  }

  const changeCustomPrice = (index, value) => {
    const newCustomPrices = [...serviceCustomPrices]
    newCustomPrices[index].price = value
    setServiceCustomPrices(newCustomPrices)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeCustomPriceStaff = (index: number) => (value: any) => {
    const newCustomPrices = [...serviceCustomPrices]
    newCustomPrices[index].staff = value
    setServiceCustomPrices(newCustomPrices)
  }

  return (
    <>
      <Modal
        show={isVisible}
        closeModal={handleClose}
        variant='right'
      >
        <form
          onSubmit={handleSubmit(handleCreateService)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={handleClose}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Service
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Heading variant='h1' size='h9' weight='semiBold'>
              {!service ? 'Add a new service' : 'Edit Service'}
            </Heading>
            <Paragraph size='b4'>
              {!service
                ? 'Add a new service to your salon'
                : 'Edit the details of this service'}
            </Paragraph>
          </div>

          <div className='w-full p-6 space-y-6'>
            <Input
              name='name'
              label='Service name'
              id='service-name'
              type='text'
              placeholder='E.g Manicure'
              control={control}
              rules={{
                required: FORM_ERRORS.SERVICE_NAME_REQUIRED
              }}
              error={errors.name}
            />
            <Input
              name='description'
              label='Description'
              id='description'
              type='text'
              placeholder='A brief description of this service'
              control={control}
              error={errors.description}
            />

            <div className='w-full flex flex-col xl:flex-row gap-2'>
              <div className="w-full xl:w-1/2 flex flex-col space-y-3">
                <Controller
                  control={control}
                  name="serviceCategoryId"
                  render={({
                    field: { onChange, value },
                    formState: { errors },
                  }) => {
                    const errorMessage: string = errors?.serviceCategoryId?.message;
                    return (
                      <>
                        <FormLabel htmlFor="service">
                          Select a category
                        </FormLabel>
                        <SingleSelect
                          selected={value || []}
                          options={categorysOptions}
                          setSelected={onChange}
                        />
                        {errorMessage && (
                          <FormHelperText variant="error">
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                  rules={{
                    required: "Select a category",
                  }}
                />
                {newCategory && (
                  <Input
                    name='category'
                    label='Category'
                    id='category'
                    type='text'
                    placeholder='Enter a category'
                    control={control}
                    rules={{
                      required: newCategory && FORM_ERRORS.CATEGORY_REQUIRED
                    }}
                    error={errors.category}
                  />
                )}
              </div>
              <Controller
                control={control}
                name="salonStaffIds"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  const errorMessage: string = errors?.salonStaffIds?.message;
                  return (
                    <div className="w-full xl:w-1/2 flex flex-col space-y-2">
                      <FormLabel htmlFor="staff">
                        Add Staff
                      </FormLabel>
                      <MultiSelect
                        selected={value as unknown as [] || []}
                        options={staffMembersOptions}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div className="w-full flex flex-col xl:flex-row gap-2">
              <div className="w-full xl:w-1/2 flex flex-col space-y-3">
                <div className='w-full flex space-x-2'>
                  <Controller
                    control={control}
                    name='price'
                    rules={{ required: 'Please enter a price' }}
                    render={({ field, formState: { errors } }) => (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='price'>Price</FormLabel>
                        <input
                          className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                          type='text'
                          {...field}
                          value={formatNumber(field.value)}
                          onChange={(e) => field.onChange(formatNumber(e.target.value))}
                          placeholder='Enter price'
                        />
                        {errors?.price?.message && (
                          <FormHelperText variant='error'>
                            {errors.price.message}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  />
                  <div className="w-30%">
                    <SelectInput
                      name='pricingType'
                      id='price-type'
                      label='Price Type'
                      control={control}
                      rules={{
                        required: ERRORS.PRICE_TYPE_REQUIRED
                      }}
                      value={watch('pricingType')}
                      error={errors.pricingType}
                      options={SERVICE_PRICE_TYPES}
                      placeholder='Select Price Type'
                    />
                  </div>
                </div>
                {showCustomButton ? (
                  <div className='w-full flex flex-col space-y-4'>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      <div className='flex' onClick={enableOrDisableCustomPrice}>
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <Checkbox isChecked={allowCustomPrice} />
                          <span className='text-grey-900 whitespace-nowrap'>
                            Set custom price
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {allowCustomPrice ? (
                  <div className="flex flex-col space-y-3">
                    <FormLabel htmlFor='isDepositRequired'>
                      Custom price
                    </FormLabel>
                    {Array?.isArray(serviceCustomPrices) && serviceCustomPrices?.map((serviceCustomPrice, index) => {
                      return (
                        <div className='w-full flex gap-2'>
                          <input
                            className='w-full max-w-[40%] flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                            type='text'
                            value={formatNumber(serviceCustomPrice.price)}
                            onChange={(e) => changeCustomPrice(index, formatNumber(e.target.value))}
                            placeholder='Enter price'
                          />
                          <div className="w-full max-w-[60%]">
                            <MultiSelect
                              selected={serviceCustomPrice?.staff || []}
                              options={watch('salonStaffIds') as []}
                              setSelected={onChangeCustomPriceStaff(index)}
                            />
                          </div>
                        </div>
                      )
                    })}

                    <Button
                      variant='light'
                      size='xs'
                      rounded='lg'
                      type="button"
                      className='w-fit border border-grey-100'
                      onClick={addMoreCustomPrice}
                    >
                      Add more
                      <SvgPlus width="14px" height="14px" />
                    </Button>
                  </div>
                ) : null}
              </div>
              <div className='w-full xl:w-1/2 space-y-2'>
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='duration'>Duration</FormLabel>
                  <div className='w-full flex items-center gap-x-4'>
                    <Input
                      name='durationHours'
                      id='duration-hours'
                      type='text'
                      placeholder='Hour(s)'
                      control={control}
                      rules={{
                        pattern: REGEX_PATTERNS.NUMBER,
                        required: !watch('durationMinutes') && ERRORS.TIME_REQUIRED
                      }}
                    />
                    <span className='text-b5 text-grey-900'>:</span>
                    <Input
                      name='durationMinutes'
                      id='duration-minutes'
                      type='text'
                      placeholder='Minutes'
                      control={control}
                      rules={{
                        pattern: REGEX_PATTERNS.NUMBER,
                        required: !watch('durationHours') && ERRORS.TIME_REQUIRED
                      }}
                    />
                  </div>
                  {errors?.durationHours || errors?.durationMinutes ? (
                    <FormHelperText key={ERRORS.TIME_REQUIRED} variant='error'>
                      {ERRORS.TIME_REQUIRED}
                    </FormHelperText>
                  ) : null}
                </div>
                <div className='w-full flex flex-col space-y-4'>
                  <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                    <div className='flex' onClick={() => { setAllowServiceExtraTime(!allowServiceExtraTime) }}>
                      <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                        <Checkbox isChecked={allowServiceExtraTime} />
                        <span className='text-grey-900 whitespace-nowrap'>
                          Add extra time in-between
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {allowServiceExtraTime ? (
                  <div className='w-full flex flex-col space-y-2'>
                    <FormLabel htmlFor='extraTime'>Extra time</FormLabel>
                    <div className='w-full flex items-center gap-x-4'>
                      <Input
                        name='extraTimeHours'
                        id='extra-time-hours'
                        type='text'
                        placeholder='Extra Time Hour(s)'
                        control={control}
                        rules={{
                          pattern: REGEX_PATTERNS.NUMBER,
                          required: !watch('extraTimeMinutes') && ERRORS.TIME_REQUIRED
                        }}
                      />
                      <span className='text-b5 text-grey-900'>:</span>
                      <Input
                        name='extraTimeMinutes'
                        id='extra-time-minutes'
                        type='text'
                        placeholder='Extra Time Minutes'
                        control={control}
                        rules={{
                          pattern: REGEX_PATTERNS.NUMBER,
                          required: !watch('extraTimeHours') && ERRORS.TIME_REQUIRED
                        }}
                      />
                    </div>
                    {allowServiceExtraTime && errors?.extraTimeHours || errors?.extraTimeMinutes ? (
                      <FormHelperText key={ERRORS.TIME_REQUIRED} variant='error'>
                        {ERRORS.TIME_REQUIRED}
                      </FormHelperText>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>

            <Controller
              control={control}
              name='isPublic'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex'>
                    <FormLabel htmlFor='isDepositRequired'>
                    Allow clients to book service via booking site
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      <div
                        className='flex'
                        onClick={() => onChange(!value)}
                      >
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <ToggleOnly isChecked={value} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
            <Controller
              control={control}
              name='isDepositRequired'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex'>
                    <FormLabel htmlFor='isDepositRequired'>
                      Is deposit required to book this service?
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      <div
                        className='flex'
                        onClick={() => onChange(!value)}
                      >
                        <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                          <ToggleOnly isChecked={value} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />

            {watch('isDepositRequired') && (
              <>
                <div className='w-full flex items-center gap-x-4'>
                  <SelectInput
                    name='depositType'
                    id='depositValue-type'
                    label='How much deposit is required?'
                    control={control}
                    rules={{
                      required: ERRORS.DEPOSIT_TYPE_REQUIRED
                    }}
                    value={watch('depositType')}
                    error={errors.depositType}
                    options={DEPOSIT_TYPE_REQUIREMENTS}
                    placeholder='Select Requirement'
                  />
                  <Controller
                    control={control}
                    name='depositValue'
                    rules={{
                      required: 'How much deposit is required for this service?'
                    }}
                    render={({ field, formState: { errors } }) => (
                      <div
                        style={{ marginTop: '28px' }}
                        className='w-full flex flex-col mt-7'
                      >
                        <div className='flex w-full'>
                          <input
                            className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                            type='text'
                            {...field}
                            value={formatNumber(field.value)}
                            onChange={(e) =>
                              field.onChange(formatNumber(e.target.value))
                            }
                            placeholder='10'
                          />
                          <div className='px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900'>
                            {typeSign}
                          </div>
                        </div>
                        {errors?.depositValue?.message && (
                          <FormHelperText variant='error'>
                            {errors.depositValue.message}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  />
                </div>
              </>
            )}
          </div>

          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex justify-end py-4 px-8'>
              <div className='flex space-x-2'>
                <Button
                  variant='text'
                  className={`mx-auto ${service && actions?.deleteService && 'text-red-500'}`}
                  size='none'
                  type='button'
                  disabled={false}
                  loading={isDeleting}
                  onClick={() => handleCancelOrDelete()}
                >
                  {service && actions?.deleteService ? 'Delete' : 'Cancel'}
                </Button>
                {actions?.editService ?
                  <Button
                    variant='primary'
                    className=''
                    disabled={isLoading}
                    loading={isLoading}
                    size='md'
                    rounded='lg'
                  >
                    Save
                  </Button>
                  : null}
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <DeleteServiceDialog
        isVisible={isDeleteServiceDialogModalVisible}
        closeModal={initiateCloseDeleteService}
        service={service}
      />
    </>
  )
}

type ViewServiceModalProps = {
  service: Service
  isVisible: boolean
  closeModal: () => void
}

const ViewServiceModal = ({
  service,
  closeModal,
  isVisible,
}: ViewServiceModalProps) => {
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div
        className='w-full relative my-[80px]'
      >
        <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgChevronLeft width='24px' height='24px' />
            </Button>
            <Paragraph size='b3' weight='bold'>
              Service
            </Paragraph>
          </div>
        </div>

        <div className='w-full flex flex-col px-6 py-4 space-y-6'>
          <Heading variant='h1' size='h9' weight='semiBold'>
            View service
          </Heading>
        </div>

        <div className='w-full flex flex-col p-6 space-y-2'>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Name
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {service?.name}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Price
            </Paragraph>
            <div className='flex space-x-3'>
              {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null}
              <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                {formatInToPrice(service?.price)}
              </Paragraph>
            </div>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Custom Prices
            </Paragraph>
            <div className='flex flex-wrap space-x-3'>
              {Array?.isArray(service?.serviceStaffs) && service?.serviceStaffs?.map((staff) => {
                return (
                  <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
                    {staff?.salonStaff?.user?.firstName}: {formatInToPrice(staff?.price)}
                  </Paragraph>
                )
              })}
            </div>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Duration
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {getHoursAndMinutesString(service?.duration)}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Description
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {service?.description}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Deposit Required
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {!service?.isDepositRequired ? 'No' : service?.depositType === 'percentage' ? `${service?.depositValue}%` : `${formatInToPrice(service?.depositValue)}`}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
            Allow clients to book service via booking site
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {!service?.isPublic ? 'No' : 'Yes'}
            </Paragraph>
          </div>
          <div className="px-2 py-3 flex justify-between items-center space-x-4 bg-grey-10">
            <Paragraph size="b4" color="grey-300" className="text-left">
              Staff Assigned
            </Paragraph>
            <Paragraph size="b4" color="grey-800" weight="semiBold" className="text-right">
              {service?.serviceStaffs?.length ? service.serviceStaffs.map(staff => staff?.salonStaff?.user?.firstName).filter(Boolean).join(', ') : ''}
            </Paragraph>
          </div>
        </div>
      </div>
    </Modal>
  )
}

type ServiceDialogProps<T extends string> = {
  service: Service
  isVisible: boolean
  closeModal: () => void
  actions: PermissionActionProp<T>;
}

type DeleteServiceDialogProps = {
  service: Service
  isVisible: boolean
  closeModal: (deleteService?: boolean) => void
}
const DeleteServiceDialog = ({
  service,
  isVisible,
  closeModal
}: DeleteServiceDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { deleteService } = useServiceContext()

  const handleDelete = () => {
    setIsLoading(true)
    deleteService(service.id)
    closeModal(true)
    setIsLoading(false)
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Service
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {service?.name}? This means that
                this service will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Inventory
