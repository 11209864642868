import React, { ChangeEvent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { HoursFormInput, TimeBufferIntervalInput } from './types';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { API_ERRORS, ERRORS } from '../constants/errors';
import Button from '../ui/atoms/button/Button';
import { DAYS, SALON_INTERVAL } from '../constants/information';
import SelectInput from '../ui/molecules/input/SelectInput';
import Paragraph from '../ui/atoms/paragraph/Paragraph';
import { COLORS } from '../constants/colors';
import { convert12HourTo24Hour, limitString } from '../utils/misc';
import Checkbox from '../ui/atoms/checkbox/Checkbox';
import ToastWrapper from '../ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from '../ui/atoms/toast/types';
import { Modal } from '../ui/templates/modal/Modal';
import { useToast } from '../hooks/useToast';
import { useSalonCache } from '../hooks/useSalonCache';
import { useModal } from '../hooks/useModal';
import { convertSchedule, setDefaultCloseTimeForWeekDays, setDefaultOpenTimeForWeekDays, setTrueForWeekDays } from './utils';
import { Salon, SalonHour, SalonHoursDocument, UpdateSalonDocument, UpdateSalonHourDocument } from '../graphql/generated';
import { print } from 'graphql'

const BusinessHoursForm = ({
  salonId,
  onNextPage,
  onPrevPage,
  buttonText,
  accountSetup
}: {
  businessId: string;
  salonId: string;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  buttonText?: string;
  accountSetup?: boolean
}) => {
  const { addToast, toast } = useToast();
  const { control, handleSubmit } = useForm<HoursFormInput>();
  const [formValues, setFormValues] = useState<HoursFormInput>({});
  const { getSalonData, setSalonData } = useSalonCache();
  const salon = getSalonData() as Salon;
  const [timeInterval, setTimeInterval] = useState<number | null>(salon?.intervalMinutes || null);
  const [salonHours, setSalonHours] = useState<SalonHour[]>([])
  const [isLoading, setIsLoading] = useState(false);
  const [bufferIsLoading, setBufferIsLoading] = useState(false);
  const {
    control: timeBufferControl,
    handleSubmit: timeBufferHandleSubmit,
    watch: timeBufferwatch,
    formState: { errors: intervalErrors },
  } = useForm<TimeBufferIntervalInput>({
    defaultValues: {
      interval: salon?.intervalMinutes
    }
  });

  const getSalonHours = async () => {
    axios
      .post(
        '/graphql',
        {
          query: print(SalonHoursDocument),
          variables: { salonId }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        const {
          data: {
            data: { salonHours }
          }
        } = res
        setSalonHours(salonHours);
      })
      .catch((err) => {
        addToast({ message: err.message, variant: 'error' })
      })
  }

  useEffect(() => {
    getSalonHours();
  }, [])

  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();

  const onSubmitData = async () => {
    const updatedHours = convertSchedule(formValues, salonHours);
    if (updatedHours?.length === 0) {
      addToast({
        variant: 'error',
        message: 'Please select at least one day',
      })
      return;
    }
    // filter through updatedHours
    const filteredHours = updatedHours.filter((day) => day?.openTime && day?.closeTime);
    if (filteredHours?.length === 0) {
      addToast({
        variant: 'error',
        message: 'Please select open and close time for at least one day',
      })
      return;
    }
    setIsLoading(true)
    await axios.post(
      '/graphql',
      {
        query: print(UpdateSalonHourDocument),
        variables: { input: { salonHours: updatedHours, salonId } },
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    ).then(({ data }) => {
      setIsLoading(false)
      if (data?.data?.updateSalonHour?.status === 200) {
        onNextPage ? onNextPage() :
        addToast({
          variant: 'success',
          message: 'Business hours updated successfully',
        })
      }

      if (data?.data?.updateSalonHour?.errors) {
        const message = data?.data?.updateSalonHour?.errors[0]?.message || API_ERRORS.BUSINESS_HOURS_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(createHoursError => {
      setIsLoading(false)
      if (axios.isAxiosError(createHoursError)) {
        const message = createHoursError?.response?.data?.message || API_ERRORS.BUSINESS_HOURS_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    })
  };

  useEffect(() => {
    if (Array?.isArray(salonHours) && salonHours?.length) {
      const defaultValues: HoursFormInput = {};
      salonHours?.forEach(({ day, openTime, closeTime }) => {
        defaultValues[day] = {
          isAvailable: openTime && closeTime ? true : accountSetup ? setTrueForWeekDays(day) : false,
          openTime: openTime || setDefaultOpenTimeForWeekDays(day),
          closeTime: closeTime || setDefaultCloseTimeForWeekDays(day),
        };
      });
      setFormValues(defaultValues);
    }
  }, [salonHours]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxChange = (day: string) => (_e: ChangeEvent<HTMLInputElement>) => {
    const dayValue = formValues[day];
    const updatedFormValues = {
      ...formValues,
      [day]: {
        isAvailable: !dayValue?.isAvailable,
        openTime: dayValue?.isAvailable ? "" : "08:00",
        closeTime: dayValue?.isAvailable ? "" : "20:00",
      },
    };
    setFormValues(updatedFormValues);
  };

  // const handleInputChange = (day: string, fieldName: "openTime" | "closeTime") => (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = e.target;
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [day]: {
  //       ...prevValues[day],
  //       [fieldName]: value,
  //     },
  //   }));
  // };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTimeChange = (day: string, fieldName: "openTime" | "closeTime") => (value: any, dateString: string) => {
    if (dateString) {
      const timeString = convert12HourTo24Hour(dateString);
      setFormValues((prevValues) => ({
        ...prevValues,
        [day]: {
          ...prevValues[day],
          [fieldName]: timeString,
        },
      }));
    }
  };

  const timeBufferSubmit = async (input: TimeBufferIntervalInput) => {
      setBufferIsLoading(true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {createdAt, updatedAt, deletedAt, clients, salonStaffs, products, services, onboardingStatus, createdById, business, wallet, ...rest} = salon
      await axios.post(
        '/graphql',
        {
          query: print(UpdateSalonDocument),
          variables: { input: { ...rest, intervalMinutes: Number(input.interval) } },
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      ).then(({ data }) => {
        setBufferIsLoading(false)
        if (data?.data?.updateSalon?.status === 200) {
          const salon = data?.data?.updateSalon?.salon;
          setSalonData(salon);
          setTimeInterval(salon?.intervalMinutes || null)
          closeModal();
        }

        if (data?.data?.updateSalon?.errors) {
          const message = data?.data?.errors[0]?.message || API_ERRORS.BUSINESS_TIME_BUFFER_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      }).catch(bufferTimeError => {
        setBufferIsLoading(false)
        if (axios.isAxiosError(bufferTimeError)) {
          const message = bufferTimeError?.response?.data?.message || API_ERRORS.BUSINESS_TIME_BUFFER_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
    })
  }
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full h-full space-y-6 pt-6">
        <div className="w-full flex flex-col space-y-4">
          {DAYS.map((day) => (
            <div className="w-full flex" key={day}>
              <div className="relative w-1/5 flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                <Checkbox
                  isChecked={formValues[day]?.isAvailable || false}
                />
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange(day)}
                  className="absolute left-0 w-[24px] h-[24px] opacity-0 cursor-pointer"
                />
                <span className="text-grey-900 font-semibold uppercase whitespace-nowrap">
                  {limitString(day, 3, false)}
                </span>
              </div>
              <div className="w-4/5 flex space-y-2">
                {!formValues[day]?.isAvailable ? (
                  <span className='text-b4 text-grey-400'>
                    Unavailable
                  </span>
                ) : (
                  <div className="w-full flex items-center gap-x-4">
                    <Controller
                      control={control}
                      name={`${day}.openTime`}
                      render={({
                        field,
                      }) => {
                        return (
                          <div className="w-full max-w-[200px] flex flex-col space-y-2">
                            <TimePicker className="splice-time-input" use12Hours format="h:mm a" value={dayjs(field.value || formValues[day]?.openTime, 'HH:mm')} onChange={handleTimeChange(day, 'openTime')} />
                          </div>
                        );
                      }}
                    />
                    <span className='text-b5 text-grey-900'>to</span>
                    <Controller
                      control={control}
                      name={`${day}.closeTime`}
                      render={({
                        field,
                      }) => {
                        return (
                          <div className="w-full max-w-[200px] flex flex-col space-y-2">
                            <TimePicker className="splice-time-input" use12Hours format="h:mm a" value={dayjs(field.value || formValues[day]?.closeTime, 'HH:mm')} onChange={handleTimeChange(day, 'closeTime')} />
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className='w-full flex justify-center space-x-2'>
          {timeInterval ? (
            <Paragraph size="b5" weight='normal' className='' color={COLORS.BLACK}>
              Time Interval: {timeInterval && `${timeInterval} mins`} between appointments
            </Paragraph>
          ) : null}
          <Button
            variant='text'
            disabled={false}
            loading={false}
            size='none'
            fontSize='b5'
            fontWeight='bold'
            className={timeInterval ? 'mx-0' : ''}
            type='button'
            onClick={openModal}
          >
            {timeInterval ? "Edit" : "Set Time Interval"}
          </Button>
        </div>
        <div className='w-full flex space-x-2'>
          {onPrevPage && (
            <Button
              variant='secondary'
              className=''
              disabled={false}
              onClick={onPrevPage}
              size='lg'
              rounded='lg'
              type='button'
            >
              Prev
            </Button>
          )}
          <Button
            variant='primary'
            className=''
            size='lg'
            rounded='lg'
            disabled={isLoading}
            loading={isLoading}
          >
            {buttonText || "Next"}
          </Button>
        </div>
      </form>
      <Modal
        title="Time interval"
        subTitle="This is the time in between appointments"
        show={isVisible}
        closeModal={closeModal}
      >
        <form onSubmit={timeBufferHandleSubmit(timeBufferSubmit)} className="w-full space-y-12 mt-12" autoComplete='off'>
          <div className='w-full max-w-[300px] mx-auto'>
            <SelectInput name="interval" value={timeBufferwatch('interval')} id="interval" label="Select interval" control={timeBufferControl} rules={{
              required: ERRORS.INTERVAL_REQUIRED,
            }} error={intervalErrors.interval} options={SALON_INTERVAL} placeholder="Select Interval" />
          </div>
          <div className='space-y-6'>
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
              disabled={bufferIsLoading}
              loading={bufferIsLoading}
            >
              Save
            </Button>
            <Button
              variant='text'
              className='mx-auto'
              size='none'
              onClick={closeModal}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default BusinessHoursForm