import React from 'react'
import SLogo from '../ui/icons/SLogo'
import Heading from '../ui/atoms/heading/Heading'
import { COLORS } from '../constants/colors'
import Paragraph from '../ui/atoms/paragraph/Paragraph'

const PaymentConfirmed = ({ seconds }: {
  seconds?: string | number
}) => {
  return (
    <main className='fixed top-0 left-0 w-full h-screen bg-grey-100 flex items-center justify-center'>
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <SLogo width="100%" height="100%" className="w-[32px]" />
              <Heading variant='h1' size='h4' weight='bold' color={COLORS.BLACK}>Payment made</Heading>
              <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                Your payment is being processed. A confirmation email will be sent shortly.
              </Paragraph>
              {seconds ? <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                {seconds}
              </Paragraph> : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PaymentConfirmed